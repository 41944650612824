import React from 'react'
import { Link } from 'react-router-dom';
import './RepairReplacement.css';
import connectionImg from '../../../../scs-img/image-section-102-min.png'
import checkingImg from '../../../../scs-img/checking-current-laptop-circuit-board-min.jpg'

const RepairReplacement = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2>Repair-Replacement</h2>
                        </div>
                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Repair-Replacement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            <div className="container" >
                <div className="repair-replacement-main" >
                    <div className="repair-replacement-content" >
                        <div className="row" >
                            <div className="col-lg-4" >
                                <div data-aos="fade-left"  className="side-bar-connection" >
                                    <div className="side-bar-connection-inner" >
                                        <div className="side-bar-connection-content" >
                                            <div className="side-bar-connection-content-heading" >
                                                <h1>Have a High Quality Connection</h1>
                                            </div>
                                        </div>
                                        <div className="side-bar-connection-content" >
                                            <div className="side-bar-connection-content-img" >
                                                <img className="img-responsive" src={connectionImg} alt="connectionImg" />
                                            </div>
                                        </div>
                                        <div className="side-bar-connection-btn" >
                                            <Link to="/hardware" className="request-quote-button" style={{ textDecoration: 'none' }}>See Plans</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8" >
                                <div data-aos="fade-up" className="repair-content-secton" >
                                    <div data-aos="zoom-in" className="repair-content-section-inner" >
                                        <div className="repair-content-section-inner-img" >
                                            <img className="img-responsive" src={checkingImg} alt="checkingImg" />
                                        </div>
                                    </div>
                                    <div  className="repair-contnet-second" >
                                    <div class="heading-below-line">
                                        <h3>Repair Center </h3>
                                    </div>
                                    <p>SCS Telcom provides VoIP system repair, including phone hardware (Handsets, PBX, etc.), and replacement services with the same fast response time and great savings as our installations. SCS Telcom is the repair center of choice for businesses of all sizes. We are able to fix any issue you may have with your VoIP system, plus we can provide maintenance services and training. Our experts are ready to help. When you need a helping hand, you know who to call.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RepairReplacement
