import React, { useState } from 'react'
import './LongQuoteBar.css';
import { Link, useHistory } from 'react-router-dom'
import RubikMedium from '../../../font/Rubik-Medium.ttf';
import Swal from 'sweetalert2';
import queryServices from '../../../Services/TransactionService';
import Validate from '../../validation/Validate'
import { Spinner } from 'react-bootstrap';



const options = [
    {
        label: "Select Option",
        value: "",
    },
    {
        label: "Desk Phone",
        value: "deckphone",
    },
    {
        label: "Room Phone",
        value: "roomphone",
    },
    {
        label: "Cordless Phone",
        value: "cordlessphone",
    },
    {
        label: "Basic Phone",
        value: "basicphone",
    },
    {
        label: "Phone Number",
        value: "phonenumber",
    },
    {
        label: "Ext/DID",
        value: "extdid",
    },
    {
        label: "Fax",
        value: "fax",
    },
];
const LongQuoteBar = () => {
    const queryService = new queryServices()
    const history = useHistory()
    const [quote, setQuote] = useState({
        firstName: "",
        LastName: "",
        email: "",
        phone: "",
        company_name: "",
        no_employee: ""
    })
    const [quote_Regarding, setQuote_Regarding] = useState('')
    const [errorMessage, seterrorMess] = useState({})
    const [loading, setLoading] = useState(false);

    const quoteInputhandler = (e) => {
        setQuote({
            ...quote,
            [e.target.name]: e.target.value
        })
    }
    const successfulAlert = () => {
        Swal.fire({
            icon: 'success',
            title: 'Your request have been submitted',
            showConfirmButton: true,

        })
    }

    const unSuccefullAlert = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! Network Connection',
        })
    }
    const quoteSubmitHandler = (e) => {
        e.preventDefault()
        setLoading(true);
        const quoteData = { ...quote, quote_Regarding: quote_Regarding }

        seterrorMess(Validate(quoteData))

        if (quote_Regarding !== '' && quote.LastName !== '' && quote.email !== '' ) {
            seterrorMess({})
            queryService.getaQuote(quoteData)
                .then((res) => {
                    console.log(res)
                    if (res.data.message === "Ok") {
                        successfulAlert()
                        setLoading(false);
                        setQuote({
                            firstName: "",
                            LastName: "",
                            email: "",
                            phone: "",
                            company_name: "",
                            no_employee: ""
                        })
                        setQuote_Regarding("")
                        
                    }
                })
                .catch((err) => {
                    unSuccefullAlert()
                    setLoading(false);
                    seterrorMess({})
                })
        }



    }
    return (
        <div className="container" >
            <div data-aos="zoom-in" className="home-long-quoteBar" >
                <div className="home-long-quoteBar-inner" >
                    <div className="row" >
                        <div className="col-lg-4" >
                            <div className="long-quoteBar-heading-main" >
                                <div className="quoteBar-heading" >
                                    <h3 style={{ fontFamily: RubikMedium }} >get a quote</h3>
                                    <p>Write us today and we will get back to you in the shortest while</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8" >
                            <div className="long-quoteBar-inputs-section" >
                                <div className="long-quoteBar-input-form" >
                                    <div className="long-quoteBar-input-div" >
                                        <input value={quote.firstName} onChange={quoteInputhandler} name="firstName" required type="text" className="long-quoteBar-input" placeholder="First Name" />
                                        <p className="quote_error" >{errorMessage.firstName}</p>
                                    </div>
                                    <div className="long-quoteBar-input-div" >
                                        <input value={quote.LastName} onChange={quoteInputhandler} name="LastName" required type="text" className="long-quoteBar-input" placeholder="Last Name" />
                                        <p className="quote_error" >{errorMessage.LastName}</p>
                                    </div>
                                    <div className="long-quoteBar-input-div" >
                                        <input value={quote.email} onChange={quoteInputhandler} name="email" required type="text" className="long-quoteBar-input" placeholder="Email" />
                                        <p className="quote_error" >{errorMessage.email}</p>
                                    </div>
                                    <div className="long-quoteBar-input-div" >
                                        <input value={quote.phone} onChange={quoteInputhandler} name="phone" required type="text" className="long-quoteBar-input" placeholder="Phone Number" />
                                        <p className="quote_error" >{errorMessage.phone}</p>
                                    </div>
                                </div>
                                <div className="long-quoteBar-input-form" >
                                    <div className="long-quoteBar-input-div" >
                                        <input value={quote.company_name} onChange={quoteInputhandler} name="company_name" required type="text" className="long-quoteBar-input" placeholder="Company Name" />
                                        <p className="quote_error" >{errorMessage.company_name}</p>
                                    </div>
                                    <div className="long-quoteBar-input-div" >
                                        <input value={quote.no_employee} onChange={quoteInputhandler} name="no_employee" required type="test" className="long-quoteBar-input" placeholder="No. of Employee" />
                                        <p className="quote_error" >{errorMessage.no_employee}</p>
                                    </div>
                                    <div className="long-quoteBar-input-div">
                                        <select onChange={(e) => setQuote_Regarding(e.target.value)} className="form-select form-select-sm dropdown-quote" aria-label=".form-select-sm example" >
                                            {options.map((option) => (
                                                <option value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="long-quoteBar-input-div">
                                        <div className="long-quoteBar-input-button" >
                                            <button onClick={quoteSubmitHandler} className="long-quoteBar-button" disabled={loading}>
                                                {
                                                    loading
                                                        ? (<Spinner animation="border" role="status" size="sm">
                                                            {/* <span className="visually-hidden">Loading...</span> */}
                                                        </Spinner>)
                                                        : 'Submit'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LongQuoteBar;