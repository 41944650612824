import { React, useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import AuthService from '../../../../Services/AuthService';
import Swal from 'sweetalert2';



const authService = new AuthService()
const AuthUI = (props) => {

    const [email, setemail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false)
    const [isloading, setisLoading] = useState(false)

    const unSuccefullAlert = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! Error Warning',
        })
    }
  const successfulAlert = () => {
        Swal.fire({
            icon: 'success',
            title: 'Reset password send your email, Please check your email',
            footer: 'Warning! Change your password after login in your profile',
            confirmButtonColor: '#7ebc12',
            confirmButtonText: 'Ok',
        }).then((result) => {
            if (result.value) {
                props.history.push("/authui");
            }
        })


    }
    const signInFormSubmitHandler = (e) => {
        e.preventDefault()
        setIsSubmited(true)
        setisLoading(true)

        if (email == '') {
            setErrorMessage('Email is required')
            setisLoading(false)
        }
        else if (!/\S+@\S+\.\S+/.test(email)) {
            setErrorMessage('Required valid email address')
            setisLoading(false)

        }
        else {
            let data = {
                "email" : email
            }
            authService.resetPassword(data)
                .then((res) => {
                    if (res.data.SuccessMessage == "Email is sent") {
                        setisLoading(false)
                        successfulAlert()
                    } else {
                        setErrorMessage('Email not found')
                        setisLoading(false)

                    }
                })
                .catch((error) => {
                    setisLoading(false)
                    setIsSubmited(false)
                    unSuccefullAlert()
                })
        }

     }

    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2>Forgot Password</h2>
                        </div>
                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Forgot Password</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            {/* <!-- Inner Wrapper Start --> */}

            {
                isSuccess ?
                    window.location.replace('/') : ""
            }
            <div className="inner-page-wrapper login-wrapper">

                <div className="container">
                    {errorMessage ? <div class="alert alert-danger" role="alert" style={{ marginTop: "40px", margin: "auto", width: "80%" }}>
                        <p className="alert-errorMessage">{errorMessage}</p>
                    </div> : ""}
                    <div className="row" style={{ marginTop: '30px' }}  >
                        <div className="col-sm-5">
                            <h3>Forgot Password</h3>
                            <form  onSubmit={signInFormSubmitHandler} >
                                <input required placeholder="E-mail" value={email} name="email" type="email" onChange={(e) => {
                                    if (errorMessage) {
                                        setErrorMessage('')
                                    }
                                    setemail(e.target.value)
                                }} />

                                <div className="auth-ui-btn" >
                                    <button type="submit" className="btn" > {isloading ? <div class="spinner-border text-dark" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div> : 'Submit'} </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Inner Wrapper End -->  */}
        </div>
    )
}

export default withRouter(AuthUI)
