import * as ActionTypes from './actiionTypes';
import ProductServices from '../../Services/ProductServices';

const Fetchedsiptrunking = new ProductServices()

export const siptrunkingFetched = () => (dispatch) => {


    Fetchedsiptrunking.sipTruckingData()
        .then((response) => {
            const { data } = response
            dispatch(addsiptrunking(data))
        })
        .catch((Error) => {
            alert(Error, 'Network Error')
        })


}

export const sipaddToCart = (item) => {
    return {
        type: ActionTypes.ADD_TO_CART_SIP,
        payload: {
            pro: item
        }
    }
}

export const sipremoveFromCart = (item) => {
    return {
        type: ActionTypes.REMOVE_FROM_CART_SIP,
        payload: {
            pro: item
        }
    }
}

export const sipChangeDate = (date) => {

    return {
        type: ActionTypes.SIP_CHANGE_DATE,
        payload: date
    }
}
export const sipChangeMonth = (month) => {

    return {
        type: ActionTypes.SIP_CHANGE_MONTH,
        payload: month
    }
}

export const checkSipDate = (date) => {

    return {
        type: ActionTypes.CHECK_DATE_SIP,
        payload: date
    }
}
export const checkSipMonth = (month) => {

    return {
        type: ActionTypes.CHECK_MONTH_SIP,
        payload: month
    }
}

export const addsiptrunking = (data) => ({
    type: ActionTypes.ADD_SIPTRUNKING,
    payload: data
})

export const resetSIPTrunking = () => {
    console.log('sip cart reset')
    return {
        type: ActionTypes.RESET_SIP_CART
    }
}