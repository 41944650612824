import React, { useEffect, useState } from 'react';
import './CustomerOrder.css';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderDetailFetched } from '../../../../store/actions/OrdreDetailAction';
import Generate from '../../../component/Generate/Generate';

const CustomerOrder = (props) => {
  const getUserInfo = JSON.parse(localStorage.getItem('user'));
  const [userDetail, setuserDetail] = useState(props.orderHistory);
  const [userEmail, setuserId] = useState(getUserInfo.user.userInfo.email);

  useEffect(() => {
    props.orderDetailFetch(userEmail);
  }, []);

  if (props.orderHistory?.length == 0) {
    return (
      <div
        className='d-flex justify-content-center'
        style={{ marginTop: '30px' }}
      >
        {/* <div className="spinner-border text-success " role="status">
                    <span className="sr-only">Loading...</span>
                </div> */}

        <h2>Orders not found</h2>
      </div>
    );
  } else {
    return (
      <div>
        <div className='order-detail-main'>
          <div className='heading-below-line'>
            <h3>Your Orders</h3>
          </div>
          <div className='order-detail'>
            <Table hover className='table-main-order'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Invoice Number</th>
                  <th>Total Products/Services</th>
                  <th>Total Amount</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {props.orderHistory?.map((res, i) => {
                  console.log('customerOrders', res);
                  return (
                    <tr>
                      <th>{i + 1}</th>
                      <th>{
                        res.billingDetails?.invoice_no
                          ? res.billingDetails?.invoice_no
                          : res.billingDetails?.invoice_number
                      }</th>
                      <th>
                        {res.cartDetail?.cart?.nonRecurring?.map((res) => (
                          <p>{res.product_name}</p>
                        ))}
                        {res.cartDetail?.cart?.recurring?.map((res) => (
                          <p>{res.packageName}</p>
                        ))}
                      </th>
                      <th>{res.cartDetail?.totalAmount}</th>
                      <th>
                        <Generate data={res} />
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderDetailFetch: (userEmail) => {
      dispatch(orderDetailFetched(userEmail));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    orderHistory: state.orderDetailReducers.orderDetail,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerOrder)
);
