import * as ActionTypes from '../actions/actiionTypes';

export const orderDetailReducer = (state = {
    orderDetail: [],
    unpaidBillDetail : []
}, action) => {
    switch (action.type) {
        case ActionTypes.ORDER_DETAIL:

            return { ...state, orderDetail: action.payload }

        case ActionTypes.UNPAIDBILL:
            return {...state , unpaidBillDetail : action.payload}
        default: return state

    }
}