export const ADD_HARDWARE = 'ADD_HARDWARE';
export const ADD_TO_CART_HARDWARE = 'ADD_TO_CART_HARDWARE ';
export const REMOVE_FROM_CART_HARDWARE = 'REMOVE_FROM_CART_HARDWARE';
export const INC_HARDWARE = 'INC_HARDWARE'
export const DEC_HARDWARE = 'DEC_HARDWARE'
export const  RESET_HARDWARE_CART  = 'RESET_HARDWARE_CART'

export const ADD_PRICING = 'ADD_PRICING';
export const CHANGE_MONTH = 'CHANGE_MONTH';
export const STARTED_DATE = 'STARTED_DATE';
export const ADD_TO_CART_PRICING = 'ADD_TO_CART_PRICING  ';
export const REMOVE_FROM_CART_PRICING = 'REMOVE_FROM_CART_PRICING ';
export const INC_PRICING_CART = 'INC_PRICING_CART'
export const DEC_PRICING_CART = 'DEC_PRICING_CART'
export const  RESET_PRICING_CART  = 'RESET_PRICING_CART'


export const ADD_SIPTRUNKING = 'ADD_SIPTRUNKING';
export const ADD_TO_CART_SIP = 'ADD_TO_CART_SIP ';
export const REMOVE_FROM_CART_SIP = 'REMOVE_FROM_CART_SIP';
export const SIP_CHANGE_MONTH = 'SIP_CHANGE_MONTH'
export const SIP_CHANGE_DATE = 'SIP_CHANGE_DATE'
export const RESET_SIP_CART = 'RESET_SIP_CART'
export const CHECK_DATE_SIP = 'CHECK_DATE_SIP'
export const CHECK_MONTH_SIP = 'CHECK_MONTH_SIP'

export const ADD_MSP = 'ADD_MSP';
export const ADD_TO_CART_MST = 'ADD_TO_CART_MST'
export const REMOVE_TO_CART_MST = 'REMOVE_TO_CART_MST';
export const MSP_STRATED_DATE = 'MSP_STRATED_DATE';
export const MSP_CHANGE_MONTH = 'MSP_CHANGE_MONTH';
export const  RESET_MSP_CART  = 'RESET_MSP_CART'



export const ORDER_DETAIL = 'ORDER_DETAIL';
export const UNPAIDBILL = 'UNPAIDBILL'





export const CART_PRODUCT = 'CART_PRODUCT';