import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Phone from '../../../images/cta-phone-icon.png';
import { connect } from 'react-redux';
import './promotion.css';
import { Accordion, Card, Spinner } from 'react-bootstrap';
import PhoneIcon from '../../../images/cta-phone-icon.png';
import company from '../../../scs-img/company-min.jpg';
import Validate from '../../validation/Validate';
import promotionServices from '../../../Services/ProductServices';
import Swal from 'sweetalert2';

let promotionpost = new promotionServices();
const Promotion = (props) => {
  const [referralData, setreferralData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    address: '',
    phone: '',
    city: '',
    state: '',
    postalCode: '',
    exacutive: '',
  });
  const [refrenceData, setrefrenceData] = useState({
    refFirstName: '',
    refLastName: '',
    refEmail: '',
    refCompanyName: '',
    refAddress: '',
    refPhoneNo: '',
    refCity: '',
    refState: '',
    refPostalCode: '',
    refCompnayIndustry: '',
    refcompanySize: '',
    refcontantPreferrance: '',
  });
  const [loading, setLoading] = useState(false);

  const [errorMessage, seterrorMessage] = useState({});

  const [formTrigger, setformTrigger] = useState(true);

  const successfulAlert = () => {
    Swal.fire({
      icon: 'success',
      title: 'Your request have been submitted',
      showConfirmButton: true,
    });
  };

  const unSuccefullAlert1 = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error,
    });
  };
  const unSuccefullAlert = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Some thing went Wrong , Network Error',
    });
  };

  const handleNextFormHandler = (e) => {
    e.preventDefault();
    seterrorMessage(Validate(referralData));

    if (referralData.phone !== '' && referralData.email !== '') {
      setformTrigger(false);
      seterrorMessage({});
    }
  };

  const handleBackFormHandler = (e) => {
    e.preventDefault();
    setformTrigger(true);
  };

  let submitFormHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    let mergeObj = {
      referralData: referralData,
      refrenceData: refrenceData,
    };
    seterrorMessage(Validate(refrenceData));
    if (refrenceData.refPhoneNo !== '' && refrenceData.refEmail !== '') {
      promotionpost
        .newPromotion(mergeObj)
        .then((res) => {
          setformTrigger(true);
          seterrorMessage({});
          if (
            res.data.message ==
            `You have already referenced promotion to ${referralData.email}`
          ) {
            setLoading(false);
            unSuccefullAlert1(res.data.message);
          } else {
            setLoading(false);
            successfulAlert();
          }
          setreferralData({
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            address: '',
            phone: '',
            city: '',
            state: '',
            postalCode: '',
            exacutive: '',
          });
          setrefrenceData({
            refFirstName: '',
            refLastName: '',
            refEmail: '',
            refCompanyName: '',
            refAddress: '',
            refPhoneNo: '',
            refCity: '',
            refState: '',
            refPostalCode: '',
            refCompnayIndustry: '',
            refcompanySize: '',
            refcontantPreferrance: '',
          });
        })
        .catch((err) => {
          unSuccefullAlert();
        });
    }
  };
  const handleRefferalData = (e) => {
    setreferralData({
      ...referralData,
      [e.target.name]: e.target.value,
    });
  };
  const handleRefrenceData = (e) => {
    setrefrenceData({
      ...refrenceData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      {/* <!-- Breadcrumb Wrapper Start --> */}
      <div className='breadcrumb-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <h2>Promotion</h2>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='inner-breadcrumb'>
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>Products</li>
                  <li>Promotion</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Breadcrumb Wrapper End -->  */}
      {/* <!-- Promotion Plans Wrapper Start --> */}
      <div className='promotion-page-main'>
        <div className='promotion-page-inner'>
          {/* promotion page section 1 start */}
          <div className='promotion-page-section-1'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='promotion-page-section-side-1'>
                  <p>Referral</p>
                  <div className='section-1-side-1-heading'>
                    <h1 className='section-1-side-1-heading'>
                      Pay it forward.
                    </h1>
                    <h1 className='section-1-side-1-heading'>
                      {' '}
                      Get $500 back.
                    </h1>
                  </div>
                  <hr style={{ background: '#7ebc12' }} />
                  <h6>
                    We're all about customized, personal service. So naturally,
                    we prefer to grow our customer base through people we trust
                    and respect, like you. And when your referral becomes a
                    customer, you'll earn $500*.
                  </h6>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='promotion-page-section-side-2'>
                  <div className='section-2-side-2-heading'>
                    <h2>referral program </h2>
                  </div>
                  <div className='section-2-side-2-img'>
                    <img src={company} alt='compnay img' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* promotion page section 1 end */}
          {/* promotion page sectin 2 start */}
          <div className='promotion-page-section-2'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='promotion-page-section-2-side-1'>
                  <div className='promotion-page-section-2-side-1-inner'>
                    <h1>Reap the rewards of your referrals</h1>
                    <div className='referal-form'>
                      <form>
                        <div className='tab-content'>
                          {formTrigger ? (
                            <div className='referral-form-1'>
                              <div className='referral-form-titel'>
                                <p>
                                  Step 1 of 2: Vendor/Referral Partner/Customer
                                </p>
                              </div>
                              <div className='refreal-input'>
                                <div className='refrral-input-div-1'>
                                  <input
                                    onChange={handleRefferalData}
                                    value={referralData.firstName}
                                    name='firstName'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='First Name'
                                  />
                                  {errorMessage.firstName && (
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      {' '}
                                      {errorMessage.firstName}{' '}
                                    </p>
                                  )}
                                </div>
                                <div className='refrral-input-div-2'>
                                  <input
                                    onChange={handleRefferalData}
                                    value={referralData.lastName}
                                    name='lastName'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='Last Name'
                                  />
                                  {errorMessage.lastName && (
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      {' '}
                                      {errorMessage.lastName}{' '}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefferalData}
                                  value={referralData.email}
                                  name='email'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Email'
                                />
                                {errorMessage.email && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.email}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefferalData}
                                  value={referralData.companyName}
                                  name='companyName'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Company Name'
                                />
                                {errorMessage.companyName && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.companyName}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefferalData}
                                  value={referralData.address}
                                  name='address'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Address'
                                />
                                {errorMessage.address && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.address}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefferalData}
                                  value={referralData.phone}
                                  name='phone'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Phone Number'
                                />
                                {errorMessage.phone && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.phone}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='refreal-input'>
                                <div className='refrral-input-div-1'>
                                  <input
                                    onChange={handleRefferalData}
                                    value={referralData.city}
                                    name='city'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='City'
                                  />
                                </div>
                                <div className='refrral-input-div-2'>
                                  <input
                                    onChange={handleRefferalData}
                                    value={referralData.state}
                                    name='state'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='State'
                                  />
                                </div>
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefferalData}
                                  value={referralData.postalCode}
                                  name='postalCode'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Postal Code'
                                />
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefferalData}
                                  value={referralData.exacutive}
                                  name='exacutive'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='SCS Telcom Account Executive'
                                />
                              </div>
                            </div>
                          ) : (
                            <div className='referral-form-1'>
                              <div className='referral-form-titel'>
                                <p>Step 2 of 2: Lead/Who are you referring? </p>
                              </div>
                              <div className='refreal-input'>
                                <div className='refrral-input-div-1'>
                                  <input
                                    onChange={handleRefrenceData}
                                    value={refrenceData.refFirstName}
                                    name='refFirstName'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='First Name'
                                  />
                                  {errorMessage.refFirstName && (
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      {' '}
                                      {errorMessage.refFirstName}{' '}
                                    </p>
                                  )}
                                </div>
                                <div className='refrral-input-div-2'>
                                  <input
                                    onChange={handleRefrenceData}
                                    value={refrenceData.refLastName}
                                    name='refLastName'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='Last Name'
                                  />
                                  {errorMessage.refLastName && (
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      {' '}
                                      {errorMessage.refLastName}{' '}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefrenceData}
                                  value={refrenceData.refEmail}
                                  name='refEmail'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Email'
                                />
                                {errorMessage.refEmail && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.refEmail}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefrenceData}
                                  value={refrenceData.refCompanyName}
                                  name='refCompanyName'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Company Name'
                                />
                                {errorMessage.refCompanyName && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.refCompanyName}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefrenceData}
                                  value={refrenceData.refAddress}
                                  name='refAddress'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Address'
                                />
                                {errorMessage.refAddress && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.refAddress}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefrenceData}
                                  value={refrenceData.refPhoneNo}
                                  name='refPhoneNo'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Phone Number'
                                />
                                {errorMessage.refPhoneNo && (
                                  <p
                                    style={{ fontSize: '12px', color: 'white' }}
                                  >
                                    {' '}
                                    {errorMessage.refPhoneNo}{' '}
                                  </p>
                                )}
                              </div>
                              <div className='refreal-input'>
                                <div className='refrral-input-div-1'>
                                  <input
                                    onChange={handleRefrenceData}
                                    value={refrenceData.refCity}
                                    name='refCity'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='City'
                                  />
                                </div>
                                <div className='refrral-input-div-2'>
                                  <input
                                    onChange={handleRefrenceData}
                                    value={refrenceData.refState}
                                    name='refState'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='State'
                                  />
                                </div>
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefrenceData}
                                  value={refrenceData.refPostalCode}
                                  name='refPostalCode'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Postal Code'
                                />
                              </div>
                              <div className='input-div'>
                                <input
                                  onChange={handleRefrenceData}
                                  value={refrenceData.refCompnayIndustry}
                                  name='refCompnayIndustry'
                                  className='contact-us-input'
                                  type='text'
                                  placeholder='Company Industry'
                                />
                              </div>
                              <div className='refreal-input'>
                                <div className='refrral-input-div-1'>
                                  <input
                                    onChange={handleRefrenceData}
                                    value={refrenceData.refcompanySize}
                                    name='refcompanySize'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='Company Size'
                                  />
                                </div>
                                <div className='refrral-input-div-2'>
                                  <input
                                    onChange={handleRefrenceData}
                                    value={refrenceData.refcontantPreferrance}
                                    name='refcontantPreferrance'
                                    className='contact-us-input'
                                    type='text'
                                    placeholder='Contant Preference'
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='input-div'>
                          {formTrigger ? (
                            <div>
                              <button
                                className='request-referral-button'
                                onClick={handleNextFormHandler}
                              >
                                {' '}
                                Next Step{' '}
                              </button>
                            </div>
                          ) : (
                            <div className='referal-button'>
                              <button
                                className='request-referal-button'
                                onClick={handleBackFormHandler}
                              >
                                {' '}
                                Back{' '}
                              </button>
                              <button
                                onClick={submitFormHandler}
                                className='request-referal-button'
                                disabled = {loading}
                              >
                                {' '}
                                {
                                  loading
                                    ? (<Spinner animation="border" role="status">
                                        {/* <span className="visually-hidden">Loading...</span> */}
                                      </Spinner>)
                                    : 'Submit'
                                }{' '}
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='promotion-page-section-2-side-2'>
                  <div className='promotion-page-section-2-side-2-inner'>
                    <div className='section-2-side-2'>
                      <h1>Grow our network and gain $500.</h1>
                      <p>
                        Do you know a business that could use support managing
                        and evolving their IT and digital operations? Our team
                        of network experts will help them connect and
                        collaborate across locations and devices, while guiding
                        them forward on their business transformation.{' '}
                      </p>
                    </div>
                    <div className='section-2-side-2-1'>
                      <h2 className='newtwork-heading'>
                        <span className='netword-no'>1</span>Complete the forms
                        to connect us
                      </h2>
                      <p>
                        Submit the forms to make a referral and be on your way
                        to earning a $500 Visa gift card.
                      </p>
                    </div>
                    <div className='section-2-side-2-1'>
                      <h2 className='newtwork-heading'>
                        <span className='netword-no'>2</span>Relax, we keep it
                        simple, no pressure
                      </h2>
                      <p>
                        After we receive your submission, one of our
                        representatives will contact your referral to offer a
                        free consultation.
                      </p>
                    </div>
                    <div className='section-2-side-2-1'>
                      <h2 className='newtwork-heading'>
                        <span className='netword-no'>3</span>Stay tuned for
                        timely updates
                      </h2>
                      <p>
                        We’ll keep you in the loop with email updates, so you’ll
                        know we received your submission and are reaching out to
                        your referral.
                      </p>
                    </div>
                    <div className='section-2-side-2-1'>
                      <h2 className='newtwork-heading'>
                        <span className='netword-no'>4</span>Watch for your
                        reward
                      </h2>
                      <p>
                        You will receive a $500 Visa gift card for each referral
                        who signs an agreement for a SCS Telcom Business product
                        or service* within weeks of contract signature.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* promotion page sectin 2 end */}
          {/* promotion page section 3 start */}
          <div className='promotion-page-section-2'>
            <div className='promotion-page-section-2-inner'>
              <h1>Frequently Asked Questions</h1>
              <p>
                Your referrals mean the world to us, and we understand you take
                them seriously. So feel free to contact us at Info@scstelcom.com
                if you have questions that aren't answered below.{' '}
              </p>
            </div>
            <div className='promotion-page-section-2-accordian'>
              <Accordion defaultActiveKey='0' style={{ fontSize: '1rem' }}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='0'>
                    What type of business should I refer?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='0'>
                    <Card.Body>
                      Any multi-site organization in the US with more than 30
                      employees that is not a current SCS Telcom Business
                      customer and has not been a SCS Telcom Business customer
                      within the past 90 days.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='1'>
                    Who in the company should I refer you to?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='1'>
                    <Card.Body>
                      IT decision makers and influencers, such as the CIO,
                      Director of IT, CFO or CEO.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='2'>
                    Who from SCS Telcom will contact my referral, and when?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='2'>
                    <Card.Body>
                      {' '}
                      A SCS Telcom representative will make the initial attempt
                      to contact within 24 hours.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='3'>
                    How can I check the status of my referral and reward?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='3'>
                    <Card.Body>
                      {' '}
                      We will notify you to confirm we received your referral
                      and whether you qualify for the incentive, and then again
                      if your referral results in successful business or is
                      disqualified. You may also email us at info@scstelcom.com
                      at any time.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='4'>
                    Will my referral receive any special offers or discounts?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='4'>
                    <Card.Body>
                      {' '}
                      Your referral will be eligible for all current market
                      discounts that they would otherwise qualify for.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='5'>
                    When and how will I receive my Visa gift card?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='5'>
                    <Card.Body>
                      {' '}
                      After we close a sale with your referral, we will let you
                      know you qualified for the incentive. You will then need
                      to visit our partner payment portal to provide some
                      necessary information for prompt payment.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='6'>
                    Is the reward considered taxable income?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='6'>
                    <Card.Body>
                      {' '}
                      Awards earned over the value of $599 in any given year are
                      considered taxable and applicable individuals will receive
                      a 1099 form.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey='7'>
                    How many referrals can I make and what is the maximum number
                    of rewards I can receive?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='7'>
                    <Card.Body>
                      {' '}
                      The sky’s the limit! You may refer as many businesses as
                      you’d like and will receive a $500 gift card for each
                      referral that becomes a new customer.{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What type of business should I refer?</button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            Any multi-site organization in the US with more than 30 employees that is not a current Frontier Business customer and has not been a Frontier Business customer within the past 90 days.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Who in the company should I refer you to?</button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body"> IT decision makers and influencers, such as the CIO, Director of IT, CFO or CEO. </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Who from Frontier will contact my referral, and when? </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">A Frontier representative will make the initial attempt to contact within 24 hours. </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How can I check the status of my referral and reward?</button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">We will notify you to confirm we received your referral and whether you qualify for the incentive, and then again if your referral results in successful business or is disqualified. You may also email us at enterprisereferrals@ftr.com at any time. </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFive">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Will my referral receive any special offers or discounts?</button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body"> Your referral will be eligible for all current market discounts that they would otherwise qualify for. </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingSix">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">When and how will I receive my Visa gift card? </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body"> After we close a sale with your referral, we will let you know you qualified for the incentive. You will then need to visit our partner payment portal to provide some necessary information for prompt payment. </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingSeven">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"> Is the reward considered taxable income? </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body"> Awards earned over the value of $599 in any given year are considered taxable and applicable individuals will receive a 1099 form. </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingEight">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"> How many referrals can I make and what is the maximum number of rewards I can receive?</button>
                                        </h5>
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body"> The sky’s the limit! You may refer as many businesses as you’d like and will receive a $500 gift card for each referral that becomes a new customer.</div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
          {/* promotion page section 3 end */}
          {/* <!-- cta wrapper Start --> */}
          <div className='cta-wrapper'>
            <div className='container'>
              <div className='text-center'>
                <div className='cta-icon'>
                  <img src={PhoneIcon} alt='' />{' '}
                </div>
                <h3>Need Help? Call Us 24/7</h3>
                <h2>+844-727-8353</h2>
              </div>
            </div>
          </div>
          {/* <!-- cta wrapper End -->  */}
        </div>
      </div>
      {/* <!-- Promotion Plans Wrapper End -->  */}
    </div>
  );
};

export default Promotion;
