import { act } from 'react-dom/test-utils';
import { toast } from 'react-toastify';
import * as ActionTypes from '../actions/actiionTypes';
toast.configure()
export const SipTrunkingReducers = (state = {
    sipTrunk: [],
    SipCart: [],
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_SIPTRUNKING:
            return { ...state, sipTrunk: action.payload }

        case ActionTypes.ADD_TO_CART_SIP:
            toast.warning('Your Product is added to cart', { autoClose: 3000 })

            action.payload.pro['price_value'] = 0
            if (state.SipCart.length === 0) {
                return {
                    ...state,
                    SipCart: [...state.SipCart, { ...action.payload.pro, value: action.payload.pro.pricing.tax_excluded }]
                }
            } else {
                let exist = state.SipCart.find(res => res._id == action.payload.pro._id)
                if (exist) {
                    let existingObject = JSON.parse(JSON.stringify(exist))
                    if (!existingObject) {
                        return {
                            ...state,
                            hardware: [...state.hardware, action.payload.pro]
                        }
                    } else {
                        return state
                    }
                } else {
                    return {
                        ...state,
                        SipCart: [...state.SipCart, { ...action.payload.pro, value: action.payload.pro.pricing.tax_excluded }]
                    }
                }
            }

        case ActionTypes.REMOVE_FROM_CART_SIP:
            return {
                ...state,
                SipCart: state.SipCart.filter((rem) => rem._id !== action.payload.pro._id)
            }

        case ActionTypes.SIP_CHANGE_DATE:

            const copyArray = [...state.SipCart]
            const findId = copyArray.filter((res) => res._id === action.payload.sip._id)
            if (findId) {
                const placeDate = copyArray.map((res) => res._id === action.payload.sip._id ? {
                    ...res,
                    startDate: action.payload.startDate
                } : res)
                return { ...state, SipCart: placeDate }
            }

        case ActionTypes.SIP_CHANGE_MONTH:
            const copysipArray = [...state.SipCart]
            const findsipId = copysipArray.filter((res) => res._id === action.payload.sip._id)
            if (findsipId) {
                const placeMonth = copysipArray.map((res) => res._id === action.payload.sip._id ? {
                    ...res,
                    month : action.payload.selectMonth,
                    pricing: {
                        ...res.pricing,
                        tax_excluded: res.value * action.payload.selectMonth
                    }
                } : res)
                return { ...state, SipCart: placeMonth }
            }


        case ActionTypes.RESET_SIP_CART:
            return { ...state, SipCart: [] }

            
        default:
            return state
    }
}