import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';


import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import HardwareReducer from './store/reducers/hardwareReducer';
import cartReducer from './store/reducers/cartReducer'
import { PricingReducers } from './store/reducers/PricingReducer'
import { SipTrunkingReducers } from './store/reducers/siptrunkingReducer';
import chat from './store/reducers/ChatReducer'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { mstDataReducer } from './store/reducers/MSPreducer'
import { logger } from 'redux-logger';
import { orderDetailReducer } from './store/reducers/OrderDetailReducer'

const persistConfig = {
  key: 'root',
  storage,
  // debug: true,
  // whitelist: ['hardware']
  // timeout: null,
}

const rootReducer = combineReducers({
  hardwares: HardwareReducer,
  pricing: PricingReducers,
  sipTrunkings: SipTrunkingReducers,
  mstResData: mstDataReducer,
  orderDetailReducers: orderDetailReducer,
  chat
})


const persistreducer = persistReducer(persistConfig, rootReducer)
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
const store = createStore(persistreducer, composeWithDevTools(applyMiddleware(thunk)))
const persistor = persistStore(store)


ReactDOM.render(
  <React.Fragment>
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);
reportWebVitals();
