import React from 'react';
import img1 from '../../../scs-img/solutionInstallaton.jpg';
import { Link } from 'react-router-dom';
import './installation.css'

const Installaton = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2>Installation</h2>
                        </div>
                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Solutions</li>
                                    <li>Installation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            <div className="solutionInstallation" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-lg-6" >
                            <div className="installation-section1-img" >
                                <img data-aos="zoom-in"  src={img1} alt="supportInstalltionImg" width="100%" height="100%" />
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div data-aos="fade-left"  className="installation-section1-heading" >
                                <h1  >Cable services provider</h1>
                                <p>We  offer cable installation in Houston and surrounding areas. We are experienced, reliable, and insured.</p>
                            </div>
                            <div data-aos="zoom-out"  className="installaton-route-button" >
                                <Link to="/contactus" className="installation-offer-button" style={{ textDecoration: 'none' }} >Get Quote</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Installaton
