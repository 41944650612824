import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Phone from '../../../images/cta-phone-icon.png';
import { connect } from 'react-redux';
import { FetchPricing } from '../../../store/actions/PricingActionCreator';
import { addToCart } from '../../../store/actions/PricingActionCreator'

const mapStateToProps = (state) => {
    return {
        priceData: state.pricing.pricing
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        pricingShow: () => { dispatch(FetchPricing()) },
        addToCartPrcing: (item) => dispatch(addToCart(item))
    }
}

const Pricing = (props) => {

    useEffect(() => { props.pricingShow() }, [])

    if (props.priceData?.length === 0) return (
        <div style={{ margin: '130px 0' }} className="d-flex justify-content-center">
            <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    )

    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2>Pricing</h2>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Products</li>
                                    <li>Pricing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            {/* <!-- Hosting Plans Wrapper Start --> */}
            <section className="hosting-plans-wrapper">
                <div className="container">
                    <div className="title">
                        <h2>Wide range of Hosting Plans</h2>
                        <span className="heading-line"></span> </div>
                    <div className="pricing-table pricing-section-secondary" >
                        <div className="row" >
                            {
                                props.priceData?.map((price) => {
                                    return (
                                        <div data-aos="fade-right" className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="price-content-wrap">
                                                <div className="plan-price">
                                                    <h4><span>$</span>{price.pricing.tax_excluded}</h4>
                                                </div>
                                                <div className="plan-name">
                                                    <p className="sub"> {price.duration} </p>
                                                    <h3 className="price-title"> {price.name} </h3>
                                                </div>
                                                <ul className="price-details">
                                                    {
                                                        price.list.map((priceL) => {
                                                            return (
                                                                <li>{priceL}</li>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                                <button onClick={() => props.addToCartPrcing(price)} className="btn mian-btn price-btn" >add to cart</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }



                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Hosting Plans Wrapper End -->  */}
            {/* <!-- cta wrapper Start --> */}
            <div class="cta-wrapper">
                <div class="container">
                    <div class="text-center">
                        <div class="cta-icon"><img src={Phone} alt="" /> </div>
                        <h3>Need Help? Call Us 24/7</h3>
                        <h2>+1 123-456-7890</h2>
                    </div>
                </div>
            </div>
            {/* <!-- cta wrapper End -->  */}
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pricing))
