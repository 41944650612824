import { getItem } from 'localforage';
import * as ActionTypes from '../actions/actiionTypes';
import { toast } from 'react-toastify';
toast.configure()

export const mstDataReducer = (state = {
    mstRes: [],
    mstCart: [],
    name : 'mst'
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_MSP:
            return { ...state, mstRes: action.payload }
        case ActionTypes.ADD_TO_CART_MST:

            toast.warning('Your Product is added to cart', { autoClose: 3000 })
            if (state.mstCart.length === 0) {
                return {
                    ...state,
                    mstCart: [...state.mstCart, { ...action.payload.mst, value: action.payload.mst.pricing.tax_included }]
                }
            } else {
                let exist = state.mstCart.find(res => res.heading.heading == action.payload.mst.heading.heading)
                if (exist) {
                    let existingObject = JSON.parse(JSON.stringify(exist))
                    if (!existingObject) {
                        return {
                            ...state,
                            msting: [...state.msting, action.payload.mst]
                        }
                    } else {
                        return state
                    }
                } else {
                    return {
                        ...state,
                        mstCart: [...state.mstCart, { ...action.payload.mst, value: action.payload.mst.pricing.tax_included }]
                    }
                }
            }
        case ActionTypes.REMOVE_TO_CART_MST:

            return {
                ...state,
                mstCart: state.mstCart.filter((res, i) => i !== action.payload)
            }


        case ActionTypes.MSP_CHANGE_MONTH:
            let mstArray = [...state.mstCart]

            const findpkag = mstArray.filter((res) => res.heading.heading === action.payload.mst.heading.heading)

            if(findpkag){
                const mstRes = mstArray.map((res) => res.heading.heading === action.payload.mst.heading.heading ? {
                    ...res,
                    month : action.payload.selectMonth,
                    pricing : {
                        ...res.pricing,
                        tax_included : res.value * action.payload.selectMonth
                    }
                } : res )
                return {...state, mstCart : mstRes}
            }

        case ActionTypes.MSP_STRATED_DATE:
            
            const mstArrays = [...state.mstCart]
            const getitem = mstArrays.filter((res) => res.heading === action.payload.mst.heading)

            if (getitem) {
                const finaldate = mstArrays.map((res) => res.heading === action.payload.mst.heading ? {
                    ...res,
                    startDate: action.payload.startDate
                } : res)

                return { ...state, mstCart: finaldate }
            }

            case ActionTypes.RESET_MSP_CART:
                return { ...state, mstCart: [] }

        default: return state
    }


}
