module.exports = {
  // SERVER_URL: "https://brightbee.co:8443/",
  // API_BASE: "https://scstelcom-backend.herokuapp.com/", // live back-end
  // IMAGE_URL: "https://scstelcom-backend.herokuapp.com",

  API_BASE: 'https://scs-telecom.herokuapp.com/', // live back-end
  IMAGE_URL: 'https://scs-telecom.herokuapp.com',
  WEBSITE_BASE: 'https://brightbee.co/',

  // IMAGE_URL:"http://localhost:8080",
  // API_BASE: "http://localhost:8080/",
  // SOCKET_URL: "http://localhost:8080/"
  // API_BASE: "http://localhost:8080/",   //local back-end
};
