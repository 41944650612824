
import React from 'react';
import './OnsiteSupport.css';
import { Link } from 'react-router-dom';
import repairPersong from '../../../../scs-img/electrician-s-tool-min-min.jpg'
import workPerson from '../../../../scs-img/crop-repairmen-working-with-tablet-min-min.jpg'

const OnsiteSupport = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div class="breadcrumb-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-7">
                            <h2>On-Site Support</h2>
                        </div>
                        <div class="col-sm-5">
                            <div class="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Support</li>
                                    <li>On-Site Support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}

            <div className="on-site-main-section" >
                <div className="on-site-inner-section" >
                    {/* <div className="title" style={{ textAlign: 'center', color: '#7ebc12' }} >
                        <h2>On-Site Support</h2>
                        <span className="heading-line"></span> </div> */}
                    <div className="on-site-content" >
                        <div className="container" >
                            <div className="row onsite-sec-1" >
                                <div className="col-lg-6" >
                                    <div data-aos="fade-left" className="onsitesec1" >
                                        <img className="img-responsive" src={repairPersong} alt="repairPerson" />
                                    </div>
                                </div>
                                <div data-aos="fade-right" className="col-lg-6" >
                                    <div className="onsiteheading" >
                                        <h3>Implementation</h3>
                                        <p>Many VOIP and hosted PBX providers do not offer onsite support, installs, or troubleshooting as part of their packages. They often come as additional bundles and can often cost sizable amounts, and dip into your ROI for the new business communication implementation. SCS Telcom has varying installation options available for on-site installation that are included in our quotes. We do an initial on-site assessment, the installation, and then an optional on-site training to familiarize your end users with the new equipment and to answer any questions.</p>
                                    </div>
                                    <div className="onsiteheading" >
                                        <h3>On-site Assessment </h3>
                                        <p>Many of our competitors do not offer an initial site assessment. This can lead to trouble down the line when issues are discovered after-the-fact. How stable is your internet connection? Do you need to upgrade Internet services? What is your electrical system and how difficult is it to integrate new phones and equipment into your building? How do our software and systems interact with your current setup? Is it a good fit? We are dedicated to making your new VOIP system as simple and as effortless as possible. This is an often overlooked and under-utilized aspect of VOIP services and can get rid of a lot of headaches in the future.</p>
                                    </div>
                                </div>
                            </div>
                            <div   className="row flex-column-reverse flex-md-row onsite-sec-2" >
                                <div className="col-lg-6" >
                                    <div  data-aos="fade-right" className="onsiteheading" >
                                        <h3>Installation</h3>
                                        <p>Some competitors ship everything to your doorstep with sets of instructions and the strong recommendation that you purchase an installation package rather than do it all yourself. This can be a massive hassle and not having troubleshooting available or experienced technicians onsite walking you through the process can be incredibly frustrating. Just like the initial site assessment, many potential issues are discovered during the installation by our technicians, and are rectified then and there, preventing pitfalls and service issues in the future. </p>
                                    </div>
                                    <div data-aos="fade-right" className="onsiteheading" >
                                        <h3>Training</h3>
                                        <p>Our phones and overall system are created to be user friendly and effortless to use, but there are times when the user requests more thorough training. Many competitors do not offer training outside of user guides or video walk-throughs. These are excellent resources, but nothing matches the ability of an individual to teach and be present. Training sessions can be scheduled as part of the installation process, or post-installation for new employees, or as a refresher for current ones. We are here to help! </p>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div data-aos="zoom-in" className="onsitesec2" >
                                    <img className="img-responsive" src={workPerson} alt="workPerson" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnsiteSupport
