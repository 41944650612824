import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router'
import './Hardware.css';
import { Link } from 'react-router-dom';
import { API_BASE } from './../../../Config'
import Default from '../../../scs-img/default.jpg';
import { connect } from 'react-redux'
import { FetchHardware , addToCart } from '../../../store/actions/HardwareActions'





const mapDispatchToProps = (dispatch) => {
    return {
        setHardwareData: () => { dispatch(FetchHardware()) },
        addToCart : (pro) => dispatch(addToCart(pro))
    }
}
const mapStateToProps = (state) => {
    return {
        products: state.hardwares.products,
       
    }
}


const Hardware = (props) => {
    useEffect(() => {
        props.setHardwareData()

    }, [])



    if (props.products.length === 0) return (
        <div style={{ margin: '130px 0' }} className="d-flex justify-content-center">
            <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )

    return (

        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2>Hardware</h2>
                        </div>

                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Products</li>
                                    <li>Hardware</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}

            <div className="bcontent" >

                {props.products.map((product, index) => {

                    let images = product.productImage.split(/\\|\//g)

                    let imgUrl = images[images.length - 1]



                    return (
                        <div data-aos="zoom-in-down" key={index} className="container bcontent">
                            <div className="card" style={{ width: "100%", background: '#f2f2f2' }}>
                                <div className="row no-gutters">
                                    <div className="col-sm-4">
                                        <img className="card-img" src={imgUrl ? API_BASE + imgUrl : Default} height="100%" width="100%" alt={product.product_name} />
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="card-item-body">
                                            <Link to={`/productDetail/${product._id}`} className="card-title-name" >{product.product_name}</Link>
                                            <div className="hardware-cart-product" >
                                                <span className="hardware-card-product-tag" >{product.type}</span>
                                            </div>
                                            <p className="card-text"> {product.detail} </p>
                                            <Link to={`/productDetail/${product._id}`} className="add-product-cart-seemore" >Read More</Link>

                                        </div>

                                    </div>
                                    <div className="col-lg-2 card-price-button-main"  >
                                        <div className="hardware-cart-button" >
                                            <span className="hardware-card-price" >${product.pricing.tax_excluded}</span>
                                            <button className="add-product-cart-button" onClick={() => props.addToCart(product)} >Add to cart</button>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}




export default connect(mapStateToProps, mapDispatchToProps)(Hardware);
