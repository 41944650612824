import React from 'react';
import './BusinessSolution.css';
import { Link } from 'react-router-dom';


const BussinesSolution = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2>Business Solution</h2>
                        </div>

                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Solutions</li>
                                    <li>Business Solution</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            <div className="container">
                <div className="business_solution_heading">
                    <h2>Business Solution</h2>
                    <span className="heading-line"></span>
                </div>
                <div className="business_solution_main" >
                    <div className="row" >
                        <div className="col-lg-7 business-section-1" >
                            <p>Many VOIP and hosted PBX providers do not offer onsite support, installs, or troubleshooting as part of their packages. They often come as additional bundles and can often cost sizable amounts, and dip into your ROI for the new business communication implementation. SCS Telcom has varying installation options available for on-site installation that are included in our quotes. We do an initial on-site assessment, the installation, and then an optional on-site training to familiarize your end users with the new equipment and to answer any questions.</p>
                            <p>Our phones and overall system are created to be user friendly and effortless to use, but there are times when the user requests more thorough training. Many competitors do not offer training outside of user guides or video walk-throughs. These are excellent resources, but nothing matches the ability of an individual to teach and be present. Training sessions can be scheduled as part of the installation process, or post-installation for new employees, or as a refresher for current ones. We are here to help!</p>
                            <p>SCS Telcom provides cohesive telecommunication plans that address all of your business needs. From dial-tone to internet services, expert service and installation to post-installation maintenance and support, and industry leading telephone solutions, we will create a plan that is sure to increase your business productivity. Run your business, and SCS Telcom will take care of your VoIP phone system support and maintenance.</p>
                            <p>Certain usage restrictions may be placed on the Services due to a high risk of fraud, regulatory restrictions and/or difficulties in settlement. Free conference calling or similar services that participate in traffic simulation practices or schemes that result in excessive charges may be blocked. These restrictions include but are not limited to calls terminating to or originating from a specific country or geographic area being blocked. Restrictions for said Services are described in the SCS Telcom, LLC web portal, and may change without notice from time to time at SCS Telcom, LLC’s sole discretion. Customer may also obtain a current list of blocked destinations by submitting a request through electronic mail to SCS Telcom, LLC at Support@SCSTelcom.com.</p>
                        </div>
                        <div className="col-lg-5" >
                            <div className="heading-below-line">
                                <h3>OFFERING</h3>
                            </div>
                            <div className="business-offer-list" >
                                <ul>
                                    <li>High-functioning phone systems </li>
                                    <li>Increase your company’s performance </li>
                                    <li>All at an affordable price</li>
                                    <li>Integrate your fax system</li>
                                </ul>
                            </div>
                            <div className="business-offer-link">
                                <div className="business_quote__div">
                                    <div>
                                        <h3>Request a Quote</h3>
                                        <p className="alignment__left">
                                            Need a quote for our Managed IT Services? Please click on the button below to provide more information about your requirements and a rep will reach out to you.
                                </p>
                                        <Link to="/contactus" className="bussiness-offer-button" style={{ textDecoration: 'none' }}>Get Quote</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BussinesSolution
