import ProductServices from '../../Services/ProductServices';
import * as ActionTypes from './actiionTypes';



const MSTData = new ProductServices()

export const mspFetch = () => (dispatch) => {
    MSTData.manageSipTrucking()
        .then((res) => {
            dispatch({ type: ActionTypes.ADD_MSP, payload: res.data })
        })
        .catch((Error) => {
            alert('Network Error', Error)
        })
}

export const addtoCartMST = (data) => {

    return {
        type: ActionTypes.ADD_TO_CART_MST,
        payload: data
    }
}
export const removeToCartMST = (i) => {
    return {
        type: ActionTypes.REMOVE_TO_CART_MST,
        payload: i
    }
}

export const mstchangeMonth = (data) => {
    return {
        type: ActionTypes.MSP_CHANGE_MONTH,
        payload: data
    }
}

export const mststartedData = (date) => {
    return {
        type: ActionTypes.MSP_STRATED_DATE,
        payload: date
    }
}

export const resetmstCart = () => {
    return {
        type: ActionTypes.RESET_MSP_CART,
        payload: []
    }
}
