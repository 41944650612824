import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Prompt } from 'react-router';
import Slider from '../navBar/Slider';
import PhoneIcon from '../../images/cta-phone-icon.png';
import number1 from '../../images/number-icon1.png';
import number2 from '../../images/number-icon2.png';
import number3 from '../../images/number-icon3.png';
import service1 from '../../images/service-icon1.png';
import service2 from '../../images/service-icon2.png';
import service3 from '../../images/service-icon3.png';
import service4 from '../../images/service-icon4.png';
import cloudImg from '../../images/what-more-img1.png';
import '../../css/one.css';
import Authentication from '../../authentication';
import { connect } from 'react-redux';
import getHardwareAction from '../../store/reducers/hardwareReducer';
import {
  guestLoginFalse,
  setChatToInitial,
} from '../../store/actions/ChatAction';
import userImg from '../../scs-img/testimonials3.png';
import Reveal from 'react-reveal/Reveal';
import LongQuoteBar from '../Quote/QuoteSecond/LongQuoteBar';
import RecuringCard from '../../layout/pages/recurringCard/RecuringCard';
import { resetSIPTrunking } from '../../store/actions/siptrunkingAciton';
import Counter from '../counter/counter';
const auth = new Authentication();

const Home = ({ priceData }) => {
  useEffect(() => {
    if (auth.getRegisterUserData() === null) {
      sessionStorage.setItem('userChatId', '111ccd111305110004782bb5');
    }
    // props.setChatToInitial()
    // props.guestFalse()
    // props.resetSIPtrunking()
  }, []);

  return (
    <div>
      <Slider />
      <LongQuoteBar />

      {/* <!-- Our Services Start --> */}
      <section className='our-services'>
        <div className='container'>
          <div className='title'>
            <h2 className='font__weight'>We offer your needs</h2>
            <span className='heading-line'></span>{' '}
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div
                data-aos='fade-right'
                className='services service-box-secundary-one'
              >
                <div className='cloud-icon'>
                  {' '}
                  <img src={service1} alt='' />
                </div>
                <h3>Business Fibers</h3>
                <p>
                  With the equal upload and download speeds of up to 10 Gig
                  Fiber+ Internet, you can simultaneously run your company and
                  deliver business applications. Our fast speeds in both
                  directions help you improve customer experiences, backup data,
                  share large files and run cloud applications.
                </p>
              </div>
            </div>
            <div data-aos='fade-up' className='col-lg-4 col-md-6 col-sm-12'>
              <div className='services service-box-secundary-one'>
                <div className='cloud-icon'>
                  {' '}
                  <img src={service2} alt='' />
                </div>
                <h3>On Premise Service</h3>
                <p>
                  SCSTelcom is customer driven and is important to be
                  customer-responsive to trait for success in the field. The
                  SCSTelcom online support has been helping a number of
                  companies across the US to cater to customer needs and to help
                  them provide superior experience to their customers.
                </p>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div
                data-aos='fade-right'
                className='services service-box-secundary-one'
              >
                <div className='cloud-icon'>
                  {' '}
                  <img src={service3} alt='' />
                </div>
                <h3>Cloud Service</h3>
                <p>
                  Prior to installation, we provide a consultation to determine
                  your needs and resources. Our goal is to pre-plan so that the
                  installation goes smoothly and within your budget. All
                  installations performed by SCSTelcom ensure expert cabling and
                  hardware Installation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our Services End --> */}
      {/* <!-- Hosting Plans Wrapper Start --> */}
      {/* {
        priceData.length !== 0 ? <RecuringCard priceData={priceData} /> : null
      } */}
      {/* <!-- Hosting Plans Wrapper End -->  */}

      {/* <!-- Satisfied Wrapper start --> */}
      <section className='satisfied-wrapper'>
        <div className='container'>
          <div className='title'>
            <h2 className='font__weight'>Why Choose Us</h2>
            <span className='heading-line'></span>{' '}
          </div>
        </div>
        <div className='container'>
          <div className='statistics'>
            <div className='row'>
              <div className='col-sm-6 col-md-6 col-lg-4'>
                <div className='counter counter-icon-secondary'>
                  <div className='number '>
                    {' '}
                    <span>
                      <Counter target={150} duration={2} />
                    </span>
                  </div>
                  <p>Reliable Services</p>
                  <div className='shape'>
                    <img src={number1} alt='' />
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-6 col-lg-4'>
                <div className='counter counter-icon-secondary'>
                  <div className='number'>
                    {' '}
                    <span>
                      {' '}
                      <Counter target={400} duration={2} />{' '}
                    </span>
                  </div>
                  <p>Up Time</p>
                  <div className='shape'>
                    <img src={number2} alt='' />
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-6 col-lg-4'>
                <div className='counter counter-icon-secondary'>
                  <div className='number'>
                    {' '}
                    <span>
                      {' '}
                      <Counter target={120} duration={2} />{' '}
                    </span>
                  </div>
                  <p>24/7 Support</p>
                  <div className='shape'>
                    <img src={number3} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our Testimonials Start --> */}
      <section className='testimonials-home-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='heading-below-line'>
                <h3>Our Satisfied Client</h3>
              </div>
              <OwlCarousel className='owl-theme' items='1' autoplay loop dots>
                {/* slider item one */}
                <div className='testimonial-home-item'>
                  <div className='testimonial-slider-inner'>
                    <div className='testimonial-home-start'>
                      <div className='testimonial-home-starts'>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                      </div>
                    </div>
                    <div className='testimonial-home-message'>
                      <p>
                        In skilled care, the biggest issue is that nobody is
                        sitting in the same office on any given day. We need to
                        be able to be reached, we need to be able to reach our
                        families, we need to be able to reach our staff, we need
                        to keep track of our staff, keep track of our patients.
                        And, so, we really needed a phone that could do that for
                        us. And SCS Telcom has been able to give us that.
                      </p>
                    </div>
                    <div className='testimonial-profile'>
                      {/* <div className="testimonial-profile-picture"></div> */}
                      <div className='testimonial-profile-name'>
                        <h4>Chris B </h4>
                        {/* <h5>Designation</h5> */}
                      </div>
                      <div className='testimonial-home-profile-quote'>
                        <i className='fa fa-quote-right' aria-hidden='true'></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* slider item one end */}
                {/* slider item two */}
                <div className='testimonial-home-item'>
                  <div className='testimonial-slider-inner'>
                    <div className='testimonial-home-start'>
                      <div className='testimonial-home-starts'>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                      </div>
                    </div>
                    <div className='testimonial-home-message'>
                      <p>
                        We were thrilled to learn we could get more for less
                        with SCS Telcom. The journey to a stable, high quality,
                        lower cost communications system was possible.
                      </p>
                    </div>
                    <div className='testimonial-profile'>
                      {/* <div className="testimonial-profile-picture"></div> */}
                      <div className='testimonial-profile-name'>
                        <h4>Vanessa A</h4>
                        {/* <h5>Designation</h5> */}
                      </div>
                      <div className='testimonial-home-profile-quote'>
                        <i className='fa fa-quote-right' aria-hidden='true'></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* slider item two end */}
                {/* slider item three */}
                <div className='testimonial-home-item'>
                  <div className='testimonial-slider-inner'>
                    <div className='testimonial-home-start'>
                      <div className='testimonial-home-starts'>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                      </div>
                    </div>
                    <div className='testimonial-home-message'>
                      <p>
                        Very knowledgeable and willing to work within our
                        budget.{' '}
                        <span style={{ opacity: '0' }}>dkljhfj0 jkdsfkuds</span>
                      </p>
                    </div>
                    <div className='testimonial-profile'>
                      {/* <div className="testimonial-profile-picture"></div> */}
                      <div className='testimonial-profile-name'>
                        <h4>Henry S</h4>
                        {/* <h5>Designation</h5> */}
                      </div>
                      <div className='testimonial-home-profile-quote'>
                        <i className='fa fa-quote-right' aria-hidden='true'></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* slider item three end */}
                {/* slider four three */}
                <div className='testimonial-home-item'>
                  <div className='testimonial-slider-inner'>
                    <div className='testimonial-home-start'>
                      <div className='testimonial-home-starts'>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                      </div>
                    </div>
                    <div className='testimonial-home-message'>
                      <p>
                        SCSTelcom is a great and decent company who knows the
                        business and wants to get the job done for their
                        customers.
                      </p>
                    </div>
                    <div className='testimonial-profile'>
                      {/* <div className="testimonial-profile-picture"></div> */}
                      <div className='testimonial-profile-name'>
                        <h4>Stephen L </h4>
                        {/* <h5>Designation</h5> */}
                      </div>
                      <div className='testimonial-home-profile-quote'>
                        <i className='fa fa-quote-right' aria-hidden='true'></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* slider item four end */}
                {/* slider five three */}
                <div className='testimonial-home-item'>
                  <div className='testimonial-slider-inner'>
                    <div className='testimonial-home-start'>
                      <div className='testimonial-home-starts'>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                        <i className='fa fa-star' aria-hidden='true'></i>
                      </div>
                    </div>
                    <div className='testimonial-home-message'>
                      <p>
                        Great communication, great service...fixed my phone &
                        Fiber outage issues and now i'm back in business with
                        more features for less. Thanks SCSTelcom for a job well
                        done!.
                      </p>
                    </div>
                    <div className='testimonial-profile'>
                      {/* <div className="testimonial-profile-picture"></div> */}
                      <div className='testimonial-profile-name'>
                        <h4> Pam M</h4>
                        {/* <h5>Designation</h5> */}
                      </div>
                      <div className='testimonial-home-profile-quote'>
                        <i className='fa fa-quote-right' aria-hidden='true'></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* slider item five end */}
              </OwlCarousel>
            </div>
            <div className='col-lg-6'>
              <div
                data-aos='fade-right'
                data-aos-offset='500'
                data-aos-duration='500'
                className='home-testimonial-slider-image'
              >
                <img
                  src='http://themes.webdevia.com/voip-wordpress-theme/wp-content/uploads/2016/11/calling.jpg'
                  alt='testomonial Image'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our Testimonials End -->  */}
      {/* <!-- What More wrapper Start --> */}
      <section className='what-more-wrapper inner-tabs'>
        <div className='container'>
          <div className='title'>
            <h2 className='font__weight'>What More We Offer</h2>
            <span className='heading-line'></span>
          </div>
        </div>
        <div className='container'>
          <div
            className='row'
            style={{ justifyContent: 'center', textalign: 'center' }}
          >
            <div className='col-sm-10 col-sm-offset-1 col-xs-offset-0'>
              <div className='classic-tab-panel with-bg'>
                <div className='tabbable-line'>
                  <ul className='nav nav-tabs '>
                    <li>
                      {' '}
                      <a href='#tab_default_1' data-toggle='tab'>
                        Fiber internet
                      </a>{' '}
                    </li>
                    <li>
                      {' '}
                      <a href='#tab_default_2' data-toggle='tab'>
                        VoIP Phone
                      </a>{' '}
                    </li>
                    <li>
                      {' '}
                      <a href='#tab_default_3' data-toggle='tab'>
                        Telcom Equipment
                      </a>{' '}
                    </li>
                    <li>
                      {' '}
                      <a href='#tab_default_5' data-toggle='tab'>
                        Business solution
                      </a>{' '}
                    </li>
                  </ul>
                  <div className='tab-content'>
                    <div className='tab-pane active' id='tab_default_1'>
                      <div className='row'>
                        <div className='col-sm-2 text-center'>
                          <img className='img-fluid' src={cloudImg} alt='' />
                        </div>
                        <div data-aos='zoom-in' className='col-sm-10'>
                          <Reveal effect='fadeInUp'>
                            <p>
                              Gamers, internet surfers and drama TV watchers now
                              can enjoy broadband at the same time under the
                              same roof!Everyone in the family can enjoy smooth
                              internet connection while multi-tasking on their
                              mobile phones, tablets and laptops with Singtel
                              Fibre Broadband
                            </p>
                          </Reveal>
                        </div>
                      </div>
                    </div>
                    <div className='tab-pane' id='tab_default_2'>
                      <div className='row'>
                        <div className='col-sm-2 text-center'>
                          <img src={cloudImg} alt='' />
                        </div>

                        <div data-aos='zoom-in' className='col-sm-10'>
                          <Reveal effect='fadeInUp'>
                            <p>
                              Voice over Internet Protocol, also called IP
                              telephony, is a method and group of technologies
                              for the delivery of voice communications and
                              multimedia sessions over Internet Protocol
                              networks, such as the Internet.
                            </p>
                          </Reveal>
                        </div>
                      </div>
                    </div>
                    <div className='tab-pane' id='tab_default_3'>
                      <div className='row'>
                        <div className='col-sm-2 text-center'>
                          <img src={cloudImg} alt='' />
                        </div>

                        <div className='col-sm-10'>
                          <Reveal effect='fadeInUp'>
                            <p>
                              It includes a wide range of communication
                              technologies – from transmission lines and
                              communication satellites to radios and answering
                              machines. Examples of telecommunications equipment
                              include switches, telecom towers, fiber-optic
                              cables, routers, voice over internet protocol
                              (VoIP), and smartphones.
                            </p>
                          </Reveal>
                        </div>
                      </div>
                    </div>
                    <div className='tab-pane' id='tab_default_4'>
                      <div className='row'>
                        <div className='col-sm-2 text-center'>
                          <img src={cloudImg} alt='' />
                        </div>
                        <div className='col-sm-10'>
                          <Reveal effect='fadeInUp'>
                            <p>
                              Our mission is to provide high quality business
                              solutions that will allow our customers to easily
                              manage their existing and future voice and data
                              requirements.
                            </p>
                          </Reveal>
                        </div>
                      </div>
                    </div>
                    <div className='tab-pane' id='tab_default_5'>
                      <div className='row'>
                        <div className='col-sm-2 text-center'>
                          <img src={cloudImg} alt='' />
                        </div>
                        <div className='col-sm-10'>
                          <Reveal effect='fadeInUp'>
                            <p>
                              Our mission is to provide high quality business
                              solutions that will allow our customers to easily
                              manage their existing and future voice and data
                              requirements.
                            </p>
                          </Reveal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- What More wrapper End -->  */}
      {/* <!-- cta wrapper Start --> */}
      <div className='cta-wrapper'>
        <div className='container'>
          <div className='text-center'>
            <div className='cta-icon'>
              <img src={PhoneIcon} alt='' />{' '}
            </div>
            <h3>Need Help? Call Us 24/7</h3>
            <h2>844-727-8353</h2>
          </div>
        </div>
      </div>
      {/* <!-- cta wrapper End -->  */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHardwareData: (data) => {
      dispatch(getHardwareAction());
    },
    resetSIPtrunking: () => {
      dispatch(resetSIPTrunking());
    },
    guestFalse: () => {
      dispatch(guestLoginFalse());
    },
    setChatToInitial: () => dispatch(setChatToInitial()),
  };
};
const mapStateToProps = (state) => ({
  priceData: state.pricing.pricing,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
