import React, { useEffect } from 'react';

const TermsOfUse = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    
    return (
        <div className="container" style={{textAlign : 'justify' , marginBottom : '100px'}} >
            <div className="business_solution_heading" style={{ margin: '50px 0 '   }} >
                <h2>Terms of Use</h2>
                <span className="heading-line"></span>
            </div>
            <div className="services-aggrement-1" >
                <p>Welcome to this website, a service of SCS Telcom dba Y2J, inc and its subsidiaries (collectively, “SCS Telcom,” “we,” or “us”). Please read this Visitor Agreement before you use this website. By using this website, you agree to abide by the terms of this Visitor Agreement. We may change the terms of this Visitor Agreement from time to time. By continuing to use the website after we post any changes, you accept and agree to this Visitor Agreement, as modified.
                This Visitor Agreement applies to the website(s) owned and operated by SCS Telcom on which it is posted. Some SCS Telcom websites may have different or additional terms for their respective visitor agreements, terms of use, subscriber agreements, privacy policies, or other terms and policies. In these cases, those agreements, terms, and policies apply to those sites. By accessing any other SCS Telcom website, you agree to abide by each website’s applicable visitor agreement, terms of use, subscriber agreement, privacy policy, or other terms and policies.</p>
            </div>



            <div className="heading-below-line">
                <h4> Privacy  </h4>
            </div>
            <div className="services-aggrement-2" >
                <p>SCS Telcom respects the privacy of our visitors to and users of this website. Please click hereto review our Privacy Statement.</p>
            </div>



            <div className="heading-below-line">
                <h4>Feedback  </h4>
            </div>
            <div className="services-aggrement-3" >
                <p>We invite you to send in your questions or comments about this website, or to bring to our attention any material you believe to be inaccurate. If you would like to communicate with us directly, you may do so using the Contact Us information found on the website, and we will, if appropriate, respond to you.</p>
            </div>



            <div className="heading-below-line">
                <h4 >SContents and Linking </h4>
            </div>
            <div className="services-aggrement-4" >
                <p>The material that appears on this website is for general informational purposes only. While we try to ensure that any information we post to this website is both timely and accurate, errors may appear from time to time. This website may not be updated daily, and certain information may not be the most current information available. Though we may post follow-up information and reports, and may continue to provide access to the original information and reports, as in an archive of news stories, for example, we may not go back and change the original report to reflect new developments. If you're looking for the most recent information on a given subject be sure you're not looking at an out of date report. Before you act on information you've found on our website, you should independently confirm any facts that are important to your decision.</p>
                <p>SCS Telcom is not responsible for, and cannot guarantee the performance of, any products and services provided by any advertisers or others, including those to whose websites we link. A link to another website does not constitute an endorsement of that site (nor of any product, service or other material offered on that site) by SCS Telcom or its licensors.</p>
                <p>Although we make this website freely accessible, we don't intend to give up our rights, or anyone else's rights, to the materials appearing on the website. The materials available through this website are the property of SCS Telcom or its licensors, and are protected by copyright, trademark and other intellectual property laws. Except for the limited licenses expressly granted to you in this Visitor Agreement, SCS Telcom and its licensors expressly reserve all other rights and licenses. You are free to display and print for your personal, non-commercial use information you receive through this website, but you may not otherwise reproduce or modify any of the materials without the prior written consent of their owner. You may not distribute copies of materials found on this website in any form (including by e-mail or other electronic means), without prior written permission from their owner. Of course, you are free to encourage others to access the information themselves on this website and to tell them how to find it. Requests for permission to reproduce or distribute materials found on this website should be sent to: Billing@SCSTelcom.com</p>
                <p>We welcome links to the homepage of our website. You are free to establish a hypertext link to the homepage of this site so long as the link does not state or imply any affiliation, connection, sponsorship, or approval of your site by SCS Telcom. We do not permit framing or inline linking to our website or any portion of it.</p>            
            
            </div>


            <div className="heading-below-line">
                <h4 > Trademarks </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>We don't want anyone to be confused as to which materials and services are provided by SCS Telcom and which aren't. You may not use any trademark or service mark appearing on this website without the prior written consent of the owner of the mark. SCS TELCOM and the SCS TELCOM and design logo are registered trademarks and/or service marks of SCS Telcom or its subsidiaries. Other trademarks appearing on this website or other sites linked to from this website are the property of SCS Telcom or their respective owners.</p>
            </div>


            <div className="heading-below-line">
                <h4 >Ordering Products and Services </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>You may order certain SCS Telcom products and services through this website. All orders you make through this website are subject to the availability, terms, and other conditions that apply to the particular products and services at the time you place your order. All products and services, their contents, availability, and pricing are subject to change at any time with or without notice. Please fully read the terms and disclaimers accompanying any products or services that you order through this website.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Submissions and Postings; Disclosure </h4>
            </div>
            <div className="services-aggrement-5" >
               <p>You are solely responsible for any information that you submit or post on this website. By using this website you agree not to submit, post or transmit any defamatory, abusive, obscene, threatening or illegal material, or any other material that infringes on the ability of others to enjoy this website or that infringes on the rights of others. We retain the right to deny access to anyone who we believe has violated these terms or any other term of this Visitor Agreement.</p>
                <p>We reserve the right (but assume no obligation) to monitor, delete, move, or edit any submissions or postings that come to our attention that we consider unacceptable or inappropriate, whether for legal or other reasons. We will comply with all requirements of the law regarding disclosure of any submissions or postings on this website to others, including to law enforcement agencies and parties making civil legal requests. We will also comply with any other requirements of the law regarding disclosure of other aspects of your use of this website.</p>
                <p>SCS Telcom welcomes your feedback about this website and our products and services. We ask that you limit your feedback to these items. Nevertheless, any communications you send to this website or otherwise to SCS Telcom are deemed to be submitted on a non-confidential basis and become the sole property of SCS Telcom. SCS Telcom may, in its sole discretion, reproduce, use, publish, modify, disclose, distribute, or otherwise use these communications in any way and for any purpose. All such uses by SCS Telcom shall be without liability or obligation of any kind to you. These uses may include, for example, use of the content of any such communications, including any works, marks or names, ideas, inventions, concepts, techniques or know-how disclosed therein, for any purpose without any obligation to compensate the originator of such communications and without liability to that person.</p>

            </div>


            <div className="heading-below-line">
                <h4 > Termination </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>SCS Telcom reserves the right at any time to terminate your use of this website if you fail to comply in full with any term of this Visitor Agreement, or any other terms, agreements, or policies that apply to this website and the use of it.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Indemnification </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>You agree to indemnify, defend, and hold harmless SCS Telcom (including its parents, subsidiaries, and affiliates and all of their respective officers, directors, employees, agents, licensors, suppliers and any third-party information providers) against all claims, losses, expenses, damages and costs (including reasonable attorney fees) resulting from any breach of this Visitor Agreement or unauthorized use of this website. Your indemnification obligation shall survive the termination of this Visitor Agreement. At our expense and election, we reserve the right to assume the exclusive defense and control of any matter subject to indemnification by you and you agree to cooperate with SCS Telcom in connection with our defense.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Disclaimer of Warranties and Liability </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>YOU AGREE THAT YOUR USE OF THIS WEBSITE IS AT YOUR SOLE RISK. BECAUSE OF THE NUMBER OF POSSIBLE SOURCES OF INFORMATION AVAILABLE THROUGH THE WEBSITE, AND THE UNCERTAINTIES OF ELECTRONIC DISTRIBUTION, THERE MAY BE INTERRUPTIONS, DELAYS, OMISSIONS, INACCURACIES, OR OTHER PROBLEMS WITH SUCH INFORMATION. IF YOU RELY ON THIS WEBSITE OR ANY MATERIAL AVAILABLE THROUGH THIS WEBSITE, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM ANY MATERIAL AND/OR DATA DOWNLOADED FROM OR OTHERWISE PROVIDED THROUGH THIS WEBSITE.</p>
                <p>THIS WEBSITE IS PROVIDED TO YOU "AS IS," "WITH ALL FAULTS," AND "AS AVAILABLE." SCS TELCOM AND ITS AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, USEFULNESS, TIMELINESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE INFORMATION AVAILABLE THROUGH THE WEBSITE, NOR DO THEY GUARANTEE THAT THE WEBSITE WILL BE ERROR-FREE, OR CONTINUOUSLY AVAILABLE, OR THAT THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                <p>UNDER NO CIRCUMSTANCES SHALL SCS TELCOM (INCLUDING ITS PARENTS, SUBSIDIARIES, AND AFFILIATES) OR ITS AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES ARISING OUT OF ANY USE OR MISUSE OF THIS WEBSITE, INCLUDING, WITHOUT LIMITATION, LIABILITY FOR CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES, EVEN IF ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES, REGARDLESS OF THE FORM OR CAUSE OF ACTION INCLUDING, BUT NOT LIMITED TO, CONTRACT, NEGLIGENCE, AND OTHER TORT ACTIONS. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES OF DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, THE LIABILITY OF SCS TELCOM AND ITS AGENTS AND LICENSORS IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW. YOU AGREE THAT THE LIABILITY OF SCS TELCOM (INCLUDING ITS PARENTS, SUBSIDIARIES, AND AFFILIATES) AND ITS AGENTS AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM IN ANY WAY CONNECTED TO THE WEBSITE SHALL NOT EXCEED THE AMOUNT YOU PAID TO SCS TELCOM FOR THE USE OF THE WEBSITE.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Miscellaneous </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>To obtain access to certain services on our website, you may be required to register. As part of any such registration process, you will select a user name and a password. You agree that the information you supply during that registration process will be accurate and complete and that you will not register under the name of, nor attempt to enter the service under the name of, another person. SCS Telcom reserves the right to reject or terminate any user name that, in its judgment, it deems offensive. You will be responsible for preserving the confidentiality of your password and will notify us of any known or suspected unauthorized use of your account. At certain places on this website there may be additional or other terms and policies that apply to your use of this website and the services on it. By using the website or those services, you agree to abide by those terms and policies. We may change those terms and policies from time to time. By continuing to use the website after we post any changes, you accept and agree to those terms and policies, as modified. This Visitor Agreement has been made in and shall be construed in accordance with the laws of the State of Texas. By using this website, you consent to the exclusive jurisdiction of the state and federal courts in Pearland, Texas Brazoria County, in all disputes arising out of or relating to this Visitor Agreement or this website. In the event that any portion of this Visitor Agreement is held to be unenforceable, the unenforceable portion shall be construed in accordance with applicable law as nearly as possible to reflect the original intentions of the parties and the remainder of the provisions shall remain in full force and effect.</p>
            </div>



           
        </div>
    )
}

export default TermsOfUse
