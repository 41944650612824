import React, { useState } from 'react';
import './BillingDetail.css';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
    return {
        sipDataRes: state.sipTrunkings.SipCart,
        pricingDataRes: state.pricing.priceCart,
        hardware: state.hardwares.HardwareCart,
    }
}


const BillingDetail = (props) => {
    const getUserInfo = JSON.parse(localStorage.getItem('user'))
    const [userDetail, setuserDetail] = useState(getUserInfo)
    const [isSuccessful, setisSuccessful] = useState(false)
    const [submitError, setsubmitError] = useState('')
    const [check, setCheck] = useState(false)
    const [onSubmittError, setonSubmittError] = useState(false)
    const [cartDetail, setcartDetail] = useState(props.location.state)
    const history = useHistory()
    const [error, setError] = useState([]);
    const [BillingDetail, setBillingDetail] = useState({
        firstName: userDetail.user.userInfo.first_Name ? userDetail.user.userInfo.first_Name : '',
        lastName: userDetail.user.userInfo.last_Name ? userDetail.user.userInfo.last_Name : '',
        email: userDetail.user.userInfo.email ? userDetail.user.userInfo.email : '',
        company: '',
        address: '',
        state: "",
        city: '',
        country: 'USA',
        phone: userDetail.user.userInfo.phone ? userDetail.user.userInfo.phone : '',
        mobile: '',
    })

    // const mergeArray = props.sipDataRes.concat(props.pricingDataRes, props.hardware)
    console.log(cartDetail)

    const billingInputHandler = (e) => {
        setBillingDetail({
            ...BillingDetail,
            [e.target.name]: e.target.value
        })
    }
    const billingFormSubmitHandler = (e) => {
        setError([])
        setonSubmittError(false)
        e.preventDefault()
        window.scrollTo({
            top: 100,
            left: 100,
            behavior: 'smooth'
        });
        if (BillingDetail.firstName === '') {
            setonSubmittError(true)
            setisSuccessful(false)
            // setsubmitError('First Name is required')
            setError(prev => [...prev, 'First Name is required'],() => setCheck(true))

            console.log(error)
        }
        if (BillingDetail.lastName === '') {
            setonSubmittError(true)
            setisSuccessful(false)
            // setsubmitError('Last Name is required')
            setError(prev => [...prev, 'Last Name is required'],() => setCheck(true))
        }
        
        if(BillingDetail.address === ""){
            setonSubmittError(true)
            setisSuccessful(false)
            // setsubmitError('Address Is Required')
            setError(prev => [...prev, 'Address Is Required'],() => setCheck(true))
        }
        if(BillingDetail.state === ""){
            setonSubmittError(true)
            setisSuccessful(false)
            // setsubmitError('State Is Required')
            setError(prev => [...prev, 'State Is Required'])
        }
        if(BillingDetail.city === ""){
            setonSubmittError(true)
            setisSuccessful(false)
            setsubmitError('City Is Required')
            setError(prev => [...prev, 'City Is Required'])
        }
        if(BillingDetail.company === ""){
            setonSubmittError(true)
            setisSuccessful(false)
            setsubmitError('Company Is Required')
            setError(prev => [...prev, 'Company Is Required'])
        }
        if(BillingDetail.country === ""){
            setonSubmittError(true)
            setisSuccessful(false)
            setsubmitError('Country Is Required')
            setError(prev => [...prev, 'Country Is Required'])
        }
        if (!/\S+@\S+\.\S+/.test(BillingDetail.email)) {
            setonSubmittError(true)
            setisSuccessful(false)
            // setsubmitError('Required valid email address')
            setError(prev => [...prev, 'A valid email address is required'],() => setCheck(true))
        }

        // else if (BillingDetail.phone != "" || BillingDetail.phone == ) {
        //     setisSuccessful(false)
        //     setsubmitError('Must be phone number is required')
        // }
        if (!/^(0|[1-9][0-9]*)$/.test(BillingDetail.phone)) {
            setonSubmittError(true)
            setisSuccessful(false)
            // setsubmitError('Must be phone number is required')
            setError(prev => [...prev, 'Phone Number is required'],() => setCheck(true))
        }
        if(BillingDetail.firstName !== ''&& BillingDetail.lastName !== '' && BillingDetail.address !== "" && BillingDetail.state !== "" && BillingDetail.city !== "" && BillingDetail.company !== "" && BillingDetail.country !== "" ) {
            console.log('sdfsdsfs',error)
            setisSuccessful(true)
            history.push({
                pathname: '/authorized',
                state: {
                    userDetails: BillingDetail,
                    cartDetail: { cart: cartDetail.cart, tax: cartDetail.tax, totalAmount: cartDetail.totalAmount },
                    platform: 'Web',
                    billStatus : 'paid',
                    billingDetails: {
                        invoice_no: new Date().getTime(),
                    }


                }
            }
            )
        }else{
            console.log('error')
        }


    }
    return (
        <div className="billingDetail-main-div" >
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2>Billing Detail</h2>
                        </div>

                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Products</li>
                                    <li>Billing Detail</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            <div className="billingDetail-content" >
                <div className="container" >
                    {isSuccessful ?
                        <div className="alert alert-success" role="alert">
                            You have successful submit your Query
                        </div> : error.length > 0 ? error.map(item => (
                                <div className="alert alert-danger" role="alert">
                               
                                <p className="alert-errorMessage" >{item}</p>
                            </div>
                            ))
                             : ''
                    }
                    <div className="row" >
                        <div className="col-lg-6" >
                            <div className="billingDetail-form-content" >
                                <div className="heading-below-line">
                                    <h3>Billing Detail</h3>
                                </div>
                                <form onSubmit={billingFormSubmitHandler} className="billingDetail-form" >
                                    <div className="billingDetail-name-input" >
                                        <input onChange={billingInputHandler} value={BillingDetail.firstName} name="firstName" className="billingDetail-input" placeholder="First name*" type="text" />
                                        <input onChange={billingInputHandler} value={BillingDetail.lastName} name="lastName" className="billingDetail-input" placeholder="Last name*" type="text" />
                                    </div>
                                    <input onChange={billingInputHandler} value={BillingDetail.email} name="email" className="billingDetail-input" placeholder="E-mail*" type="email" />
                                    <input onChange={billingInputHandler} value={BillingDetail.phone} maxlength="12" name="phone" className="billingDetail-input" placeholder="Phone Number*" type="tel" />
                                    <input onChange={billingInputHandler} value={BillingDetail.mobile} maxlength="12" name="mobile" className="billingDetail-input" placeholder="Mobile Number" type="tel" />
                                    <input onChange={billingInputHandler} value={BillingDetail.company} name="company" className="billingDetail-input" placeholder="Company*" type="text" />
                                    <input onChange={billingInputHandler} value={BillingDetail.address} name="address" className="billingDetail-input" placeholder="Address*" type="text" />
                                    <input onChange={billingInputHandler} value={BillingDetail.city} name="city" className="billingDetail-input" placeholder="City*" type="text" />
                                    {/* <input onChange={billingInputHandler} value={BillingDetail.country} name="country" className="billingDetail-input" placeholder="Country" type="text" /> */}
                                    <input onChange={billingInputHandler} value={BillingDetail.state} name="state" className="billingDetail-input" placeholder="State*" type="text" />
                                    <div className="billingDetail-pay-button" >
                                        <input className="billingDetail-pay-btn" type="submit" text="Submit" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 billing-detail-summary-main"  >

                            <div className="billing-detail-summary" >
                                <div className="heading-below-line">
                                    <h3>Order Summary</h3>
                                </div>
                                <div className="BillingDetail-total-content" >

                                    {/* <div className="BillingDetail-total-amount" >
                                        <p>Purchased Products : </p>
                                        <ul>
                                            {mergeArray.map((pro) => {
                                                return (
                                                    <div className="billing-summary-product" >
                                                        <li> {pro.heading} </li>
                                                        <li> {pro.name} </li>
                                                        <li> {pro.product_name} </li>
                                                    </div>
                                                )
                                            })}
                                        </ul>
                                    </div> */}

                                    <div className="BillingDetail-total-amount" >
                                        <p>Subtotal : </p>
                                        <h6> ${cartDetail.subTotal} </h6>
                                    </div>
                                    <div className="BillingDetail-total-amount" >
                                        <p>Shipping : </p>
                                        <h6>---</h6>
                                    </div>
                                    <div className="BillingDetail-total-amount" >
                                        <p>Tax : </p>
                                        <h6>${cartDetail.tax}</h6>
                                    </div>
                                    <hr />
                                    <div className="BillingDetail-total-amount" >
                                        <p>Total Amount : </p>
                                        <h6> ${cartDetail.totalAmount} </h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(BillingDetail);
