import {Link} from 'react-router-dom'
import { Carousel } from 'react-bootstrap';
import './slider.css';
import slider1img from '../../scs-img/Slider-1_compressed.jpg';
import slider2img from '../../scs-img/Slider-2_compressed.jpg';
import slider3img from '../../scs-img/Slider-3_compressed.jpg';
import Quote from '../Quote/QuoteSecond/ssQuote';


const Slider = () => {

	const heroSliderContent = [
		{
			img : slider1img,
			caption: 'We are always ready to help you',
			heading: 'collaborating ideas',
			para: 'Do you have cross-functional teams within your organization who work on corporate initiatives, new products, programs and/or marketing campaigns? They likely get together on a regular basis to discuss progress and share ideas.'
		},
		{
			img : slider2img,
			caption: 'We are always ready to help you',
			heading: 'ON PREMISE SERVICE',
			para: 'Telecom industry is one of the fastest growing industries in the world.  The technological developments combined with cut-throat competition have  made this industry more competitive than ever.'
		},
		{
			img : slider3img,
			caption: 'We are always ready to help you',
			heading: 'CLOUD BASED SERVICE',
			para: 'Prior to installation, we provide a consultation to determine your needs  and resources. Our goal is to pre-plan so that the installation goes  smoothly and within your budget.'
		},
	]



	return (
		<div>
					<Carousel interval={null} >
						{
							heroSliderContent.map((sliderC , i) => {
								return (
							
										<Carousel.Item key={i}  >
											
											<div className="hero-slider">
											<div className="home-slider" style={{ backgroundImage: `url("${sliderC.img}")`}} > 
											<div class="container" >
											<div class="row">
												<div class="col-lg-7 col-md-12 col-sm-12">
													<div class="welcome-text">
														<div class="hero-text">
															<h4>{sliderC.caption}</h4>
															<div className="slider-heading-div" >
															<h1>{sliderC.heading}</h1>
															</div>
															<div class="p-text">
																<p>{sliderC.para}</p>
															</div>
															<div class="button">
																<Link to="/contactus" className="bizwheel-btn theme-1 effect">Work with us</Link>
																{/* <Link to="/" className="bizwheel-btn theme-2 effect">Our Solutions</Link> */}
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-5 col-md-12 col-sm-12" >
													<Quote/>
												</div>
											</div>
										</div>
											</div>
											</div>
										
									</Carousel.Item>
							
									
								)
							})
						}
					</Carousel>
		</div>
	)
}

export default Slider;
