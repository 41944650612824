import { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Phone from '../../../images/cta-phone-icon.png';
import { Form, Tab, Table } from 'react-bootstrap'
import { manageTrunking, sampleValue } from '../../../DummyData/managedTrunking';
import OwlCarousel from "react-owl-carousel";
import './style.css';
import './ManageSipTrucking.css';
import { connect } from 'react-redux'
import { mspFetch } from '../../../store/actions/MSPaction';
import { addtoCartMST } from '../../../store/actions/MSPaction';


const mapDispatchToProps = (dispatch) => {
    return {
        getMSTRes: () => { dispatch(mspFetch()) },
        addtoCartMST: (data) => dispatch(addtoCartMST(data))
    }
}

const mapStateToProps = (state) => {
    return {
        mst: state.mstResData.mstRes
    }
}

const List = manageTrunking
var currentIndex = 0
const ManageSipTrucking = ({ getMSTRes, mst, addtoCartMST }) => {
    let firstObject = { heading: 'Features', pricing: { tax_excluded: '' } }

    const [requiredIndex, setRequiredIndex] = useState(0)
    const [arrayToDisplay, setArrayToDisplay] = useState([])
    const [headingToDisplay, setHeadingToDisplay] = useState([])




    var headingfromStore
    var allList = []
    useEffect(() => {
        getMSTRes()

        if (mst.length === 0) return (
            <div style={{ margin: '130px 0' }} className="d-flex justify-content-center">
                <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        )



        headingfromStore = mst && mst.map(res => {
            let data = { heading: res.headingList[0], pricing: res.pricing }
            return data
            //    return [...res.headingList[0],res.pricing]
        })
        headingfromStore.unshift(firstObject)
        let pricing = mst.map(res => res.pricing)
        let newArray = [...headingfromStore, pricing]

        allList = mst && mst.map(result => result.allFeaturesValue.map(x => x))
        let arr = allList && allList[0].map((res, i) => res.allData.map(x => x))
        let abc = []
        var otherArr = []
        allList.map((res, i) => {
            if (i !== 0) {
                res.map((secondRes, index) => {
                    let valueToAdd = otherArr[index] !== undefined ? otherArr[index] : ''
                    otherArr[index] = valueToAdd !== '' ? secondRes.allData[1] + "," + valueToAdd : secondRes.allData[1]
                })
            }

            // abc.concat(res.allData[1])

            // otherArr[i] = res.allData[1] + ","+ otherArr[i]

            // }
        })
        var finalArray = []

        for (let i = 0; i < otherArr.length; i++) {


            finalArray[i] = { allData: [...arr[i], otherArr[i]] }
        }
        // = [...arr, ...otherArr]
        setArrayToDisplay(finalArray)
        setHeadingToDisplay(headingfromStore)

    }, [])

    let tablebody = List.allList
    let removedBody = tablebody.slice(1)



    let removefirst = List.headingList
    let removedHead = removefirst.slice(1)





    if (mst.length === 0) return (
        <div style={{ margin: '130px 0' }} className="d-flex justify-content-center">
            <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    )
    else {
        return (
            <div className="main_div_MST">
                {/* <!-- Breadcrumb Wrapper Start --> */}
                <div className="breadcrumb-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h2>Managed SIP Trunking</h2>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="inner-breadcrumb">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li>Products</li>
                                        <li>Managed SIP Trunking</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Breadcrumb Wrapper End -->  */}
                {/* <!-- Hosting Plans Wrapper Start --> */}
                <section className="hosting-plans-wrapper">
                    <div className="container">
                        <div className="title">
                            <h2>Wide range of Hosting Plans</h2>
                            <span className="heading-line"></span> </div>
                        {/* DIV FOR BACKGROUND OF THE TABLE */}
                        {/* <div className="pricing-table pricing-section-secondary"> */}
                        {/* <div className="row" >
                            </div> */}
                        <div data-aos="fade-right" class="row" style={{ justifyContent: "center", marginTop: "20px" }}>
                            <div className="col-lg-12 col-md-12 col-sm-12">

                                <div className="" style={{ justifyContent: "center " }}>
                                    <div class="row" className="wrapper">
                                        {/* <Table striped bordered hover responsive className="recurring-table">
                                            <thead>
                                                <tr >
                                                    {
                                                        List.headingList.map((res, i) => {
                                                            return (
                                                                <th class={i === 0 ? "sticky-col first-col" : ""}>
    
                                                                    <div className="pricing_div">
                                                                        <p className="sub">Per Month</p>
                                                                        <span class={i === 0 ? "no_display" : "price_span"}><span >$</span>{res.price}</span>
                                                                    </div>
    
                                                                    <h3 className="price-title">   {res.heading}</h3>
                                                                
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {
                                                    List.allList.map((res, i) => {
                                                        return (
                                                            < tr >
    
                                                                {
                                                                    res.allData.map((resSecond, i) => {
                                                                        currentIndex = currentIndex + 1
    
                                                                        return (
    
                                                                            <td className={i === 0 ? "sticky-col first-col" : ""}><span className="table_span">{res.allData[0]}</span> {resSecond} </td>
    
                                                                        )
    
                                                                    })
                                                                }
    
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table> */}
                                        {/* this is  */}
                                        {/* <Table striped bordered hover responsive className="recurring-table">
                                            <thead>
                                                <tr >
                                                    {
                                                        List.headingList.map((res, i) => {
                                                            return (
                                                                <th class={i === 0 ? "sticky-col first-col" : ""}>
    
                                                                    <div className="pricing_div">
                                                                        <p className="sub">Per Month</p>
                                                                        <span class={i === 0 ? "no_display" : "price_span"}><span >$</span>{res.price}</span>
                                                                    </div>
    
                                                                    <h3 className="price-title">   {res.heading}</h3>
    
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {
                                                    List.allList.map((res, i) => {
                                                        return (
                                                            < tr >
    
                                                                {
                                                                    res.allData.map((resSecond, i) => {
                                                                        currentIndex = currentIndex + 1
    
                                                                        return (
    
                                                                            <td className={i === 0 ? "sticky-col first-col" : ""}>{resSecond} </td>
    
                                                                        )
    
                                                                    })
                                                                }
    
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table> */}
                                        <Table striped bordered hover responsive className="recurring-table">
                                            <thead>
                                                <tr >
                                                    {

                                                        // List.headingList.map((res, i) => {
                                                        headingToDisplay && headingToDisplay.map((res, i) => {
                                                            return (
                                                                <th class={i === 0 ? "sticky-col first-col" : ""}>
                                                                    <div className="pricing_div">
                                                                        <p class={i > 0 ? "no_display" : ""} >Per Month</p>
                                                                        <span class={i === 0 ? "no_display" : ""} ><span >$</span>{res.pricing.tax_excluded}</span>
                                                                        <span class={i === 0 ? "no_display" : ""}><button onClick={() => addtoCartMST({ mst: res, index: i })} className="addtocartmst" >Add to cart</button> </span>
                                                                    </div>
                                                                    {/* <span class={i === 0 ? "no_display" : "price_span"}><span >$</span>{res.pricing.tax_included} </span> */}


                                                                    <h3 className="price-title">   {res.heading.heading}</h3>


                                                                </th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {
                                                    // List.allList.map((res, i) => {
                                                    arrayToDisplay && arrayToDisplay.map((res, i) => {
                                                        return (
                                                            < tr >

                                                                {
                                                                    res.allData.map((resSecond, i) => {
                                                                        currentIndex = currentIndex + 1

                                                                        return (

                                                                            <td className={i === 0 ? "sticky-col first-col" : ""}>{resSecond} </td>

                                                                        )

                                                                    })
                                                                }

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </section>
                {/* SECOND TABLE */}

                <div className="main-slider-manage" >
                    <div className="title" style={{ margin: '30px 0' }} >
                        <h2>Wide range of Hosting Plans</h2>
                        <span className="heading-line"></span> </div>



                    <div className="" >



                        <OwlCarousel
                            className="owl-theme"
                            items="1"
                        >


                            {
                                headingToDisplay.map((res, i) => {

                                    let arr = arrayToDisplay
                                    return (
                                        <div >
                                            <th className={i === 0 ? "" : ""} >


                                                <div className="pricing_div" >
                                                    <p class={i > 0 ? "no_display" : ""} >Per Month</p>
                                                    <span class={i === 0 ? "no_display" : ""} ><button onClick={() => addtoCartMST({ mst: res, index: i })} className="addtocartmst" >Add to cart</button> </span>

                                                    <span class={i === 0 ? "no_display" : "price_span"} ><span >$</span>{res.pricing.tax_excluded}</span>
                                                </div>

                                                <h3 className="price-title">   {res.heading.heading}</h3>

                                            </th>
                                            <tbody className={i === 0 ? "" : ""} >
                                                {
                                                    arrayToDisplay.map((res, i) => {
                                                        console.log(res, 'headingToDisplayheadingToDisplay')

                                                        return (
                                                            < tr >

                                                                {
                                                                    res.allData.map((resSecond, i) => {
                                                                        currentIndex = currentIndex + 1

                                                                        return (

                                                                            <td className={i > 0 ? "display" : ""} ><span className="table_span" >{res.allData[0]}first</span> {resSecond} second</td>

                                                                        )

                                                                    })
                                                                }

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </div>
                                    )
                                })


                            }





                        </OwlCarousel>


                    </div>

                </div>
















                {/* SECOND TABLE  */}
                {/* <Table striped bordered hover responsive className="recurring-table">
    
                    <thead>
                        <tr  >
    
                            {
                                List.headingList.map((res, i) => {
                                    return (
    
                                        <th class={i === 0 ? "sticky-col first-col" : ""} width="100%" >
                                            <div className="pricing_div">
                                                <p className="sub">Per Month</p>
                                                <span class={i === 0 ? "no_display" : "price_span"}><span >$</span>{res.price}</span>
                                            </div>
                                            <h3 className="price-title">   {res.heading}</h3>
                                        </th>
                                    )
                                })
                            }
    
                        </tr>
                    </thead>
                    <OwlCarousel
                        className="owl-theme"
                        items="1"
    
                    >
                    </OwlCarousel>
                    <tbody >
                        {
                            List.allList.map((res, i) => {
                                return (
                                    < tr >
    
                                        {
                                            res.allData.map((resSecond, i) => {
                                                currentIndex = currentIndex + 1
    
                                                return (
    
                                                    <td className={i === 0 ? "sticky-col first-col" : ""}><span className="table_span">{res.allData[0]}</span> {resSecond}</td>
    
                                                )
    
                                            })
                                        }
    
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table> */}

                {/* <!-- Hosting Plans Wrapper End -->  */}

            </div >
        )
    }

}

// export default ManageSipTrucking;
export default connect(mapStateToProps, mapDispatchToProps)(ManageSipTrucking)
