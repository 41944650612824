import './voip.css';
import voip from '../../../scs-img/Mask Group 1.png';
import { Link } from 'react-router-dom';

const Voip = () => {
  return (
    <div>
      {/* <!-- Breadcrumb Wrapper Start --> */}
      <div class="breadcrumb-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-sm-7">
              <h2>VoIP Phone System</h2>
            </div>
            <div class="col-sm-5">
              <div class="inner-breadcrumb">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>Solutions</li>
                  <li>VoIP Phone System</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Breadcrumb Wrapper End -->  */}
      <div className="voipsolution" >
        <div className="container" >
          <div className="row" >
            <div className="col-lg-6" >
              <div className="first-voip-section-part" >
                <div className="voip-section1-heading" >
                  <h1 data-aos="fade-left"  >Unleash your <span style={{ color: '#7ebc12' }} >client</span></h1>
                  <p  data-aos="fade-right"  >VoIP phone system for <span style={{ fontWeight: "900" }}  >modern</span> business.</p>
                  <h6>Hosted VoIP phone solution for any sized business.</h6>
                  <h5 data-aos="fade-left" >Fast setup. Prebuilt integrations. HD call quality.</h5>
                </div>
              </div>
              <div data-aos="zoom-out"  className="voip-btn-enterprise" >
                <Link to="/contactus" className="voip-offer-button" style={{ textDecoration: 'none' }}>Enterprise Solution?</Link>
              </div>

            </div>
            <div className="col-lg-6" >
              <div className="voip-section1-img" >
                <img data-aos="zoom-in"  src={voip} alt="voipsectionimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="voip-services" >
        <div className="container" >
          <div className="voip-services-heading" >
            <h1>VoIP services for all your employees</h1>
          </div>
          <div className="voip-scs-text" >
            <p>SCS Telcom provides VoIP system repair, including phone hardware (Handsets, PBX, etc.), and replacement services with the same fast response time and great savings as our installations. SCS Telcom is the repair center of choice for businesses of all sizes. We are able to fix any issue you may have with your VoIP system, plus we can provide maintenance services and training. Our experts are ready to help. When you need a helping hand, you know who to call.</p>

          </div>
          {/* <div className="voip-services-detail" >
            <div className="row" >
              <div className="col-lg-6" >
                <div className="voip-services-detail-1" >
                  <div className="detial-1-part-1" >
                    <h5 className="detail-head" >
                      <span> <i class="fa fa-clock-o" aria-hidden="true"></i></span>
                      <span className="services-detial-heading" >Fast Step</span>
                    </h5>
                    <p>Super easy 3 minute setup. No hardware, zero wires, and no IT knowledge needed</p>
                  </div>
                  <div className="detial-1-part-1" >
                    <h5 className="detail-head" >
                      <span> <i class="fa fa-retweet" aria-hidden="true"></i> </span>
                      <span className="services-detial-heading" >Reached with Your Business</span>
                    </h5>
                    <p>Super easy 3 minute setup. No hardware, zero wires, and no IT knowledge needed</p>
                  </div>
                  <div className="detial-1-part-1" >
                    <h5 className="detail-head" >
                      <span> <i class="fa fa-volume-control-phone" aria-hidden="true"></i> </span>
                      <span className="services-detial-heading" >Handle With phone</span>
                    </h5>
                    <p>Super easy 3 minute setup. No hardware, zero wires, and no IT knowledge needed</p>
                  </div>
                </div>
              </div>
             
              <div className="col-lg-6" >
                <div className="voip-services-detail-1" >
                  <div className="detial-1-part-1" >
                    <h5 className="detail-head" >
                      <span> <i class="fa fa-clock-o" aria-hidden="true"></i></span>
                      <span className="services-detial-heading" >Fast Step</span>
                    </h5>
                    <p>Super easy 3 minute setup. No hardware, zero wires, and no IT knowledge needed</p>
                  </div>
                  <div className="detial-1-part-1" >
                    <h5 className="detail-head" >
                      <span> <i class="fa fa-retweet" aria-hidden="true"></i> </span>
                      <span className="services-detial-heading" >Reached with Your Business</span>
                    </h5>
                    <p>Super easy 3 minute setup. No hardware, zero wires, and no IT knowledge needed</p>
                  </div>
                  <div className="detial-1-part-1" >
                    <h5 className="detail-head" >
                      <span> <i class="fa fa-volume-control-phone" aria-hidden="true"></i> </span>
                      <span className="services-detial-heading" >Handle With phone</span>
                    </h5>
                    <p>Super easy 3 minute setup. No hardware, zero wires, and no IT knowledge needed</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Voip;