import React from 'react'

const TransferDomain = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div class="breadcrumb-wrapper">
            <div class="container">
                <div class="row">
                <div class="col-sm-7">
                    <h2>Transfer Your Domain</h2>
                </div>
                <div class="col-sm-5">
                    <div class="inner-breadcrumb">
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li>Domain</li>
                        <li>Transfer Your Domain</li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
{/* <!-- Breadcrumb Wrapper End -->  */}
{/* <!-- Inner Wrapper Start --> */}
<div class="inner-page-wrapper transfer-domain-text">
<div class="container">
<div class="row">
<div class="col-sm-5">
<div class="domain-transfer-img"><img src="https://placeholdit.imgix.net/~text?txtsize=60&bg=1E2832&txtclr=fff&txt=600%C3%97400&w=600&h=400" alt=""/></div>
</div>
<div class="col-sm-7">
<div class="domain-transfer-text">
<h2>Why Domain transfer to us</h2>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
<ul>
<li>Full Access</li>
<li>Quality Support</li>
<li>FAST Domain Transfer</li>
<li>Domain Manager</li>
<li>Domain Tools</li>
</ul>
</div>
</div>
</div>
</div>
</div>
{/* <!-- Inner Wrapper End -->  */}
        </div>
    )
}

export default TransferDomain
