import OwlCarousel from 'react-owl-carousel';

const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    autoplay: false,
    smartSpeed: 1000,
    dots : true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,
  
        }
    },
  };

const Registerdomain = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
        <div className="breadcrumb-wrapper">
        <div className="container">
            <div className="row">
            <div className="col-sm-7">
                <h2>Register a Domain</h2>
            </div>
            <div className="col-sm-5">
                <div className="inner-breadcrumb">
                <ul>
                    <li><a href="index.html">Home</a></li>
                    <li>Domain</li>
                    <li>Register a Domain</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        </div>
        { /* <!-- Breadcrumb Wrapper End -->  */}
    <div className="inner-page-wrapper domain-register-wrapper">
    <div className="container">
    <OwlCarousel
            {...options}
            >
        <div className="item">
        <div className="domain-register">
          <h3>.com</h3>
          <h4>$1.59 <span>$2.39</span></h4>
          <p>Lorem ipsum dolor sit amet sit</p>
          <a href="javascript:void(0)">Register</a> </div>
      </div>
        <div className="item">
        <div className="domain-register">
          <h3>.com</h3>
          <h4>$1.59 <span>$2.39</span></h4>
          <p>Lorem ipsum dolor sit amet sit</p>
          <a href="javascript:void(0)">Register</a> </div>
      </div>
        <div className="item">
        <div className="domain-register">
          <h3>.com</h3>
          <h4>$1.59 <span>$2.39</span></h4>
          <p>Lorem ipsum dolor sit amet sit</p>
          <a href="javascript:void(0)">Register</a> </div>
      </div>
        <div className="item">
        <div className="domain-register">
          <h3>.com</h3>
          <h4>$1.59 <span>$2.39</span></h4>
          <p>Lorem ipsum dolor sit amet sit</p>
          <a href="javascript:void(0)">Register</a> </div>
      </div>
        </OwlCarousel>
    </div>
    </div>
        </div>
    )
}

export default Registerdomain;
