import React, { useState } from 'react';
import footerLogo from '../../images/telcom-logo.c828d489.png';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import authorizeImg from '../../scs-img/seal-authorize copy.png';
import useScript from '../component/script/script';
import './Footer.css';

const Footer = (props) => {
  var ANS_customer_id = 'b74f10ea-8169-4ccb-ad7e-cbe1b0bbb348';
  const history = useHistory();
  const [query, setquery] = useState({
    email: '',
    message: '',
  });

  const handlequeryinput = (e) => {
    setquery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };
  const handleQuerrySubmitt = (e) => {
    e.preventDefault();

    history.push({
      pathname: `/contactus`,
      state: { query },
    });
  };
  return (
    <div>
      <section className='footer-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4 col-sm-6'>
              {' '}
              <img
                width='30%'
                height='auto'
                className='img-fluid'
                src={footerLogo}
                alt='Trendy Host'
              />
              <p>
                SCS Telcom's team has been fulfilling the telephone systems
                needs of businesses in Houston and surrounding area with
                reliable and feature-rich systems.
              </p>
              <ul>
                <li>
                  <i className='fa fa-phone' aria-hidden='true'></i>844-727-8353
                </li>
                <li>
                  <i className='fa fa-envelope' aria-hidden='true'></i>
                  Info@SCSTelcom.com
                </li>
                <li>
                  <i class='fa fa-globe' aria-hidden='true'></i>
                  www.SCSTelcom.com
                </li>
                <li>
                  <i className='fa fa-map-marker' aria-hidden='true'></i>
                  &nbsp;P.O. Box 751901 <br /> &nbsp;&nbsp;&nbsp; Houston, Texas
                  77275{' '}
                </li>
              </ul>
            </div>
            <div className='col-md-4 col-sm-6'>
              <div className='second'>
                <h3>Quick Links</h3>
                <ul className='list'>
                  <li>
                    <Link className='nav-link' to='/services_aggrement'>
                      Services Agreement
                    </Link>
                  </li>
                  <li>
                    <Link className='nav-link' to='/terms&uses'>
                      Terms & Uses
                    </Link>
                  </li>
                  <li>
                    <Link className='nav-link' to='/user-aggrement'>
                      User Agreement
                    </Link>
                  </li>
                  {/* <li><Link className="nav-link" to="/">VoIP Agreement</Link></li> */}
                </ul>
              </div>

              <div className='authorize-badge'>
                {/* <a href="https://www.authorize.net/about-us/terms.html"><img src={authorizeImg} alt="authorizeImg" /></a> */}
                <div class='AuthorizeNetSeal'>
                  {/* <!-- (c) 2005, 2021. Authorize.Net is a registered trademark of CyberSource Corporation --> */}
                  <useScript url='//verify.authorize.net:443/anetseal/seal.js' />
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12'>
              <div className='bottom-form'>
                <h3>Get a Free Quote</h3>
                <form onSubmit={handleQuerrySubmitt}>
                  <div className='form-group'>
                    <input
                      required
                      value={query.email}
                      onChange={handlequeryinput}
                      name='email'
                      placeholder='Email'
                      type='email'
                    />
                  </div>
                  <div className='form-group'>
                    <textarea
                      value={query.message}
                      onChange={handlequeryinput}
                      name='message'
                      placeholder='Message'
                    ></textarea>
                  </div>
                  <button type='submit' className='btn'>
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className='social'>
            <ul>
              <li>
                <a href='https://www.facebook.com/scstelcom1' target='_blank'>
                  <i className='fa fa-facebook' aria-hidden='true'></i>
                </a>
              </li>
              {/* <li><Link to="/twitter"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                            <li><Link to="/google"><i className="fa fa-google-plus" aria-hidden="true"></i></Link></li>
                            <li><Link to="/linkdin"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                            <li><Link to="/youtube"><i className="fa fa-youtube-play" aria-hidden="true"></i></Link></li> */}
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Footer --> */}
      <footer>
        <div className='container'>
          {/* <p>Term of Use | User Agreement | Shopping Policy | VoIP Agreement</p> */}
          <p>&copy; Copyright 2021, SCS Telcom | All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
