import React, { useState, useEffect } from 'react';
import Cleave from 'cleave.js/react';
import './Authorized.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TransactionService from '../../../Services/TransactionService';
import { resetHardwareCart } from '../../../store/actions/HardwareActions';
import { resetmstCart } from '../../../store/actions/MSPaction';
import { resetPricingCart } from '../../../store/actions/PricingActionCreator';
import { resetSIPTrunking } from '../../../store/actions/siptrunkingAciton';

import Swal from 'sweetalert2';

const billingTransaction = new TransactionService();
const Authorized = (props) => {
  const getUserInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [FN, setFN] = useState('');
  const [LN, setLN] = useState('');
  const [isSubmitted, setisSubmitted] = useState(false);
  const [isSuccessful, setisSuccessful] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const [billingDetails, setBillingDetail] = useState(props.location.state);
  const [authorize, setauthorize] = useState({
    cardNo: '',
    expiry: '',
    cvc: '',
    accountNumber: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const successfulAlert = () => {
    Swal.fire({
      icon: 'success',
      text: 'Your Transaction had been successful',
      confirmButtonColor: '#7ebc12',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.value) {
        props.history.push('/');
      }
    });
  };

  const unSuccefullAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong! Check your provided information again',
    });
  };
  const authorizedInputHandler = (e) => {
    setauthorize({
      ...authorize,
      [e.target.name]: e.target.value,
    });
  };

  const billCreateHandler = () => {
    billingTransaction
      .createCashedBill(billingDetails)
      .then((res) => {
        setisLoading(false);
        successfulAlert();
      })
      .catch((err) => {
        setisLoading(false);
        unSuccefullAlert();
      });
  };
  const authorizationSubmitHandler = (e) => {
    let userInformation = {
      firstName: getUserInfo.first_Name,
      lastName: getUserInfo.last_Name,
      address: getUserInfo.address,
      city: getUserInfo.city,
      state: getUserInfo.state,
      zip: getUserInfo.zip_Code,
      country: getUserInfo.country,
      email: getUserInfo.email,
      phone: getUserInfo.phone,
      fax: getUserInfo.fax,
      cardNo: authorize.cardNo,
      expiry: authorize.expiry,
      cvc: authorize.cvc,
      accountNumber: authorize.accountNumber,
      billDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      totalAmount: billingDetails.cartDetail.totalAmount,
      taxes: billingDetails.cartDetail.tax,
    };
    setisLoading(true);
    setisSuccessful(false);
    setisSubmitted(false);
    e.preventDefault();
    billingTransaction
      .chargePayment(userInformation)
      .then((res) => {
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth',
        });

        let checkMSG = res.data.errorMessage;
        if (checkMSG == 'A duplicate transaction has been submitted.') {
          seterrorMessage('A duplicate subscription will not be created.');
          setisSuccessful(true);
          setisLoading(false);
        } else if (checkMSG == 'The credit card number is invalid.') {
          setisSuccessful(true);
          setisLoading(false);

          seterrorMessage('The credit card number is invalid.');
        } else if (checkMSG == 'The credit card has expired.') {
          setisSuccessful(true);
          setisLoading(false);

          seterrorMessage('The credit card has expired.');
        } else if (
          checkMSG == 'The merchant does not accept this type of credit card.'
        ) {
          setisSuccessful(true);
          setisLoading(false);

          seterrorMessage(
            'The merchant does not accept this type of credit card.'
          );
        } else if (checkMSG) {
          setisSuccessful(true);
          setisLoading(false);
          console.log(checkMSG);
          seterrorMessage('Your provided information is not complete');
        } else if (res.data.messages.resultCode == 'Ok') {
          billCreateHandler();
          props.resetmstCart();
          props.resetHardwareCart();
          props.resetSIPTrunking();
          props.resetPricingCart();
        } else {
          setisLoading(false);
          seterrorMessage(res.data.errorMessage);
        }
      })
      .catch((err) => {
        setisLoading(false);
        unSuccefullAlert();
      });

    // if (props.hardware.length > 0 && props.pricingDataRes.length == 0 && props.mst.length == 0 && props.sipDataRes.length == 0) {

    //     let findtotalPrice = 0
    //     let totalTax = 0
    //     props.hardware.map((res) => {
    //         let price = res.quantity * res.pricing.tax_excluded
    //         findtotalPrice = findtotalPrice + price
    //         let tax = res.quantity * res.pricing.tax_rate
    //         totalTax = totalTax + tax

    //     })
    //     const totalAmount = findtotalPrice + totalTax
    //     if (props.hardware.length > 0) {

    //     }
    //     billingTransaction.chargePayment({ totalAmount: totalAmount, ...authorize, ...transactionDetail })
    //         .then((res) => {
    //             console.log('res of hardware')
    //         })
    //         .catch((err) => {
    //             console.log('err in hardware', err)
    //         })

    // }

    // if (props.hardware.length > 0 && props.pricingDataRes.length === 0 && props.mst.length === 0 && props.sipDataRes.length === 0) {
    //     let findtotalPrice = 0
    //     let totalTax = 0
    //     props.hardware.map((res) => {
    //         let price = res.quantity * res.pricing.tax_excluded
    //         findtotalPrice = findtotalPrice + price
    //         let tax = res.quantity * res.pricing.tax_rate
    //         totalTax = totalTax + tax

    //     })
    //     const totalAmount = findtotalPrice + totalTax
    //     billingTransaction.chargePayment({ totalAmount: totalAmount, ...authorize, ...transactionDetail })
    //         .then((res) => {
    //             console.log(res.data, 'res in hardware api')
    //             if (res.data.messages?.resultCode && res.data.messages?.resultCode === "Ok") {
    //                 setisSubmitted(true)
    //                 setisSuccessful(false)
    //                 setisLoading(false)
    //                 billingTransaction.createCashedBill({ totalAmount : billingDetails.totalAmount, ...authorize, ...orderHistoryDetail })
    //                     .then((res) => {
    //                         console.log('res of cash bill history', res)
    //                     })
    //                     .catch((err) => {
    //                         console.log('res of cash bill history', err)
    //                     })
    //             }
    //             else {
    //                 let checkMSG = res.data.errorMessage
    //                 if (checkMSG == "A duplicate transaction has been submitted.") {
    //                     seterrorMessage('A duplicate subscription will not be created.')
    //                     setisSuccessful(true)
    //                     setisLoading(false)

    //                 }
    //                 else if (checkMSG == "The credit card number is invalid.") {
    //                     setisSuccessful(true)
    //                     setisLoading(false)

    //                     seterrorMessage("The credit card number is invalid.")
    //                 }
    //                 else if (checkMSG == "The credit card has expired.") {
    //                     setisSuccessful(true)
    //                     setisLoading(false)

    //                     seterrorMessage("The credit card has expired.")
    //                 }
    //                 else if (checkMSG) {
    //                     setisSuccessful(true)
    //                     setisLoading(false)

    //                     seterrorMessage("Your provided information is not complete")
    //                 }
    //                 else {
    //                     setisLoading(false)

    //                     seterrorMessage(res.data.errorMessage)
    //                     console.log('ye lo else ma')
    //                 }
    //             }
    //         })
    //         .catch((Error) => {
    //             setisLoading(false)

    //             console.log('Something went wrong in catch', Error)
    //         })
    // }
    // else if (props.hardware.length === 0 && props.pricingDataRes.length > 0 || props.mst.length > 0 || props.sipDataRes.length > 0) {
    //     if (props.pricingDataRes.length > 0) {
    //         props.pricingDataRes.map(async (res) => res.type === 'Pricing' ? {
    //             ...res,
    //             await: billingTransaction.subcription({ ...res, ...authorize, totalAmount: res.pricing.tax_excluded, ...billingDetails.BillingDetail, ...userInformation })
    //                 .then((res) => {
    //                     console.log(res.data, 'res in pricing')
    //                     if (res.data.response.messages?.resultCode && res.data.response.messages?.resultCode === "Ok") {
    //                         setisSubmitted(true)
    //                         setisLoading(false)
    //                         billingTransaction.createCashedBill({ totalAmount : billingDetails.totalAmount, ...authorize, ...orderHistoryDetail })
    //                         .then((res) => {
    //                             console.log('res of cash bill history', res)
    //                         })
    //                         .catch((err) => {
    //                             console.log('res of cash bill history', err)
    //                         })
    //                     }

    //                     else {
    //                         let checkMSG = res.data.response
    //                         let errorMSG = checkMSG.split(".")
    //                         let status = errorMSG[1]
    //                         if (status == "A duplicate customer payment profile already exists.") {
    //                             // alert('A duplicate subscription will not be created')
    //                             seterrorMessage('A duplicate subscription will not be created.')
    //                             setisSuccessful(true)
    //                             setisLoading(false)

    //                         }
    //                         else if (checkMSG == "Credit Card Number is invalid.") {
    //                             seterrorMessage('Credit Card Number is invalid.')
    //                             setisSuccessful(true)
    //                             setisLoading(false)
    //                         }
    //                         else if (checkMSG) {
    //                             seterrorMessage('Your provided information is not complete')
    //                             setisSuccessful(true)
    //                             setisLoading(false)
    //                         }

    //                     }
    //                 })
    //                 .catch((Error) => {

    //                     seterrorMessage('Something went wrong.')
    //                     console.log('Something went hgfhgfhggh  in cach', Error)
    //                 })
    //         } : res)
    //     }
    //     else if (props.mst.length > 0) {
    //         props.mst.map(async (res) => res ? {
    //             ...res,
    //             await: billingTransaction.subcription({ ...res, totalAmount: res.totalOccurrences * res.pricing.tax_excluded, ...authorize, ...billingDetails.BillingDetail, ...userInformation })
    //                 .then((res) => {
    //                     console.log(res.data.response, 'res in mst')
    //                     if (res.data.response.messages?.resultCode && res.data.response.messages?.resultCode === "Ok") {
    //                         setisSubmitted(true)
    //                         setisLoading(false)
    //                         setisSuccessful(false)
    //                         billingTransaction.createCashedBill({ totalAmount : billingDetails.totalAmount, ...authorize, ...orderHistoryDetail })
    //                         .then((res) => {
    //                             console.log('res of cash bill history', res)
    //                         })
    //                         .catch((err) => {
    //                             console.log('res of cash bill history', err)
    //                         })
    //                     }
    //                     else {

    //                         let checkMSG = res.data.response
    //                         if (checkMSG === "You have submitted a duplicate of Subscription 7306606. A duplicate subscription will not be created.") {
    //                             seterrorMessage('A duplicate subscription will not be created.')
    //                             setisSuccessful(true)
    //                             setisLoading(false)
    //                         }
    //                         else if (checkMSG == "Credit Card Number is invalid.") {
    //                             seterrorMessage("Credit Card Number is invalid.")
    //                             setisSuccessful(true)
    //                             setisLoading(false)
    //                         }
    //                         else if (checkMSG) {
    //                             seterrorMessage("Your provided information is not complete")

    //                             setisSuccessful(true)
    //                             setisLoading(false)
    //                         }
    //                     }
    //                 })
    //                 .catch((Error) => {
    //                     console.log('Something went wrong in cach', Error)
    //                 })
    //         } : res)
    //     }
    //     else if (props.sipDataRes.length > 0) {
    //         props.sipDataRes.map(async (res) => res ? {
    //             ...res,
    //             await: billingTransaction.subcription({ ...res, totalAmount: res.totalOccurrences * res.pricing.tax_excluded, ...transactionDetail })
    //                 .then((res) => {
    //                     console.log(res, 'res in siptruncking')
    //                     if (res.data.messages?.resultCode && res.data.messages?.resultCode === "Ok") {
    //                         setisSubmitted(true)
    //                         setisLoading(false)
    //                         setisSuccessful(false)
    //                         billingTransaction.createCashedBill({ totalAmount : billingDetails.totalAmount, ...authorize, ...orderHistoryDetail })
    //                         .then((res) => {
    //                             console.log('res of cash bill history', res)
    //                         })
    //                         .catch((err) => {
    //                             console.log('res of cash bill history', err)
    //                         })
    //                     }
    //                     else {
    //                         let errRes = res.data.response

    //                         if (errRes == "You have submitted a duplicate of Subscription 7306606. A duplicate subscription will not be created." ) {
    //                             seterrorMessage('A duplicate subscription will not be created.')
    //                             setisSuccessful(true)
    //                             setisSubmitted(false)
    //                             setisLoading(false)

    //                         }
    //                         else if (errRes == "The credit card number is invalid.") {
    //                             setisSuccessful(true)
    //                             setisLoading(false)

    //                             seterrorMessage("The credit card number is invalid.")
    //                         }
    //                         else if (errRes == "The credit card has expired." ) {
    //                             setisSuccessful(true)
    //                             setisLoading(false)

    //                             seterrorMessage("The credit card has expired." )
    //                         }
    //                         else if (errRes == "A duplicate customer payment profile already exists." ) {
    //                             setisSuccessful(true)
    //                             setisLoading(false)

    //                             seterrorMessage("A duplicate customer payment profile already exists." )
    //                         }
    //                         else if (errRes) {
    //                             setisSuccessful(true)
    //                             setisLoading(false)

    //                             seterrorMessage("Your provided information is not complete")
    //                         }
    //                     }

    //                 })
    //                 .catch((Error) => {
    //                     setisLoading(false)
    //                     alert('Something went wrong', Error)
    //                 })
    //         } : res)
    //     }

    // }
    // else{
    //     alert('all product is added')
    //     console.log('ye else ma a gya ha')
    // }
  };

  return (
    <div>
      {isSubmitted ? successfulAlert() : ''}

      <div className='authorization-inner'>
        <div className='container'>
          <div className='payment-detail-interface'>
            {isSuccessful ? (
              <div class='alert alert-danger' role='alert'>
                <p style={{ fontSize: '1rem', margin: '0px' }}>
                  {' '}
                  {errorMessage}
                </p>
              </div>
            ) : (
              ''
            )}

            <div class='heading-below-line'>
              <h3>Payment/Authorization Detail</h3>
            </div>
            <div className='authorized-inputs'>
              {/* <Cleave
                                placeholder="Account Number"
                                options={{
                                    blocks: [18],
                                    numericOnly: true
                                }}
                                onChange={authorizedInputHandler}
                                value={authorize.accountNumber}
                                name="accountNumber"
                            /> */}
              <Cleave
                placeholder='First Name'
                value={FN}
                options={{
                  blocks: [16],
                }}
                name='ln'
                onChange={(e) => setFN(e.target.value)}
                required
              />
              <Cleave
                required
                placeholder='Last Name'
                value={LN}
                options={{
                  blocks: [16],
                }}
                name='ln'
                onChange={(e) => setLN(e.target.value)}
              />
              <Cleave
                placeholder='Card No'
                options={{
                  blocks: [16],
                  numericOnly: true,
                }}
                onChange={authorizedInputHandler}
                value={authorize.cardNo}
                name='cardNo'
              />
              <Cleave
                placeholder='MM-YY'
                options={{ date: true, datePattern: ['m', 'y'] }}
                onChange={authorizedInputHandler}
                value={authorize.expiry}
                name='expiry'
              />
              <Cleave
                placeholder='CVC'
                options={{
                  blocks: [4],
                  numericOnly: true,
                }}
                onChange={authorizedInputHandler}
                value={authorize.cvc}
                name='cvc'
              />
              <button
                type='submit'
                className='transaction-pay-btn'
                onClick={(e) => {
                  if (FN && LN !== '') {
                    authorizationSubmitHandler(e);
                  } else {
                    unSuccefullAlert();
                  }
                }}
              >
                {' '}
                {isLoading ? (
                  <div class='spinner-border text-dark' role='status'>
                    <span class='sr-only'>Loading...</span>
                  </div>
                ) : (
                  'Continue'
                )}{' '}
              </button>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    sipDataRes: state.sipTrunkings.SipCart,
    pricingDataRes: state.pricing.priceCart,
    hardware: state.hardwares.HardwareCart,
    mst: state.mstResData.mstCart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetHardwareCart: () => dispatch(resetHardwareCart()),
    resetmstCart: () => dispatch(resetmstCart()),
    resetPricingCart: () => dispatch(resetPricingCart()),
    resetSIPTrunking: () => dispatch(resetSIPTrunking()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Authorized)
);
