import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import io from 'socket.io-client'
// import { API_BASE } from '../../Config'
import InfoBar from '../Chat/InfoBar/InfoBar'
import Input from '../Chat/Input/Input'
import Messages from '../Chat/Message/Messages/Messages'
import ChatService from '../../Services/ChatService'

const chatService = new ChatService
let socket
function Chat() {
  const [name, setName] = useState('')
  const [room, setRoom] = useState('')
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [dialog, setDialog] = useState([])
  const [user, setUser] = useState("")
  const [chatId, setChatId] = useState("111ccd111305110004782bb5")
  const ENDPOINT = 'localhost:8080' // API_BASE
  const location = window.location.search

  //GETTING A USER BY USERNAME/EMAIL
  useEffect(() => {
    if (name !== "") {
      let data = {
        name: name
      }
      chatService.getChatContact(data)
        .then(res => {
          setUser(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    }

  }, [name])
  //RECEIVING A INITIAL MESSAGE FROM ADMIN
  // useEffect(() => {
  //   // alert('in userEffect')
  //   const { name, room } = queryString.parse(window.location.search)
  //   setName(name)
  //   setRoom(room)

  //   socket = io.connect(ENDPOINT, { transport: ['websocket'] }) //localhost:8080
  //   // setRoom(socket.id)
  //   console.log(socket, 'socket')

  //   room && socket.emit('join', { name, room }, ({ error }) => {
  //     if (error) alert(error);

  //   })

  
  // }, [])




  //SETTING A NEW MESSAGE IN CONVERSATION
  useEffect(() => {
    socket.on('message', (message) => {
      console.log(message, 'message object from backe-end')
      // let newDialog = dialog
      // newDialog.push({
      //   who: message.contactId,
      //   message: message.text,
      //   time: new Date()
      // })
      // setDialog(newDialog)
      setMessages([...messages, { text: message.text, user: message.user }])
    })
    console.log('in the required useEffect')
    socket.on('information', (data) => {
      console.log(data, 'data from back-end socket')
    })

  }, [messages, window.location.search])



  // EMIT A NEW *MESSAGE 
  const sendMessage = async (e) => {
    e.preventDefault()
    // alert('send messsage method')
    if (message) {
      socket.emit('sendMessage', { message, contactId: user._id }, () => setMessage(''))

    }
    console.log(message, 'this is our message')
    let newDialog = dialog
    newDialog.push({
      who: user._id,
      message: message,
      time: new Date()
    })
    let newMsg = {
      who: user._id,
      message: message,
      time: new Date()
    }


    let dataToSend = {
      newMessage: newMsg,
      contactId: user._id,
      chatIdFromWeb: chatId,
      from: 'website'
    }
    console.log(dataToSend, ' dataToSenddataToSend')
    await chatService.createChatAndChatList(dataToSend)
      .then(res => {
        setDialog(newDialog)
        console.log(res.data, 'responseJson create chat')
        setChatId(res.data.chatId)
      })
      .catch(err => {
        console.log(err)
      })

  }

  console.log(message, messages)
  return (
    <div className="outerContainer">
      <div className="container">
        <InfoBar room={room} />
        <Messages messages={messages} name={name} />
        <Input setMessage={setMessage} message={message} sendMessage={sendMessage} />

      </div>
    </div>
  )
}

export default Chat
