import * as  ActionTypes from './actiionTypes';
import TransactionService from '../../Services/TransactionService'


const orderDetail = new TransactionService()

export const orderDetailFetched = (email) => (dispatch) => {
    orderDetail.getCashBillByEmail(email)
        .then((res) => {

            dispatch({ type: ActionTypes.ORDER_DETAIL, payload: res.data })

        })
        .catch((Error) => {
            alert('Network Error', Error)
        })
}

export const getUnpadBillAction = (email) => (dispatch) => {
    orderDetail.unpaidBills(email)
        .then((res) => {
            dispatch({ type: ActionTypes.UNPAIDBILL, payload: res.data })
        })
        .catch((err) => {
            alert('Error', err)
        })
}
export const getBillByInvoice = (invoiceNo) => {
    return orderDetail.getCashBillByInvoive(invoiceNo)
    .then(res => res.data)
}