import { Component } from 'react'
import axios from 'axios';
import { API_BASE } from '../Config'


let instance = axios.create({
    baseURL: API_BASE + 'api/product',
})

class ProductServices extends Component {

    newPromotion = (data) => {
        return instance.post('/promotions/addNew' , data)
    }
    getProducts = () => {
        return instance.get('/getHardware')
    }
    pricingData = () => {
        return instance.get('/getAllPricing')
    }
    sipTruckingData = () => {
        return instance.get('/sipTrunking/getAll')
    }
    manageSipTrucking = () => {
        return instance.get('/mst-getAll')
    }


}
export default ProductServices
