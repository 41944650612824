import React from 'react'
import '../css/style.css';
import '../css/one.css';
import { BrowserRouter as Router, Route, Switch, useParams, withRouter } from 'react-router-dom';
import NavBarNew from '../layout/navBar/NavBarNew';
import TopBarNew from '../layout/navBar/TopBarNew';
import ContectUs from './pages/aboutUs/contactus';
import WarningPage from './pages/404';
import Error from './pages//Error'
import Footer from './footer/Footer';
import AuthUI from './pages/AuthUI';
import Home from './pages/Home';
import RegisterDomain from './pages/domains/Registerdomain';
import TransferDomain from './pages/domains/TransferDomain';
import DomainPrice from './pages/domains/DomainPrice';
import WhySCS from './pages/aboutUs/WhySCS';
import Voip from './pages/solution/Voip';
import SipTrucking from './pages/products/SipTrucking';
import Pricing from './pages/products/Pricing';
import Promotion from './pages/products/Promotion';
import ManageSitTrucking from './pages/products/ManageSipTrucking';
import supportInstallation from './pages/support/Installation/Installation'
import signUp from './pages/signup/SignUp';
import ChangePassword from './pages/signup/forgotPassword/ChangePassword';
import ResetPassword from './pages/signup/forgotPassword/ResetPassword.jsx';
import ForgotPassword from './pages/signup/forgotPassword/ForgotPassword';
import Hardware from '../layout/pages/hardware/Hardware'
// import ProductDescription from '../layout/pages/hardware/ProductDescription'
import Chat from './Chat/Chat';
import Join from './Chat/Join';
import ProductDescription from '../layout/pages/hardware/ProductDescription';
import BussinesSolution from '../layout/pages/solution/BussinesSolution';
import PrePackage from '../layout/pages/solution/PrePackage';
import Installation from '../layout/pages/solution/Installaton';
import SuccessAlert from '../layout/component/successAlert/SuccessAlert';
import Cart from './cart/Cart';
import BillingDetail from './pages/BillingDetail/BillingDetail';
import Customerprofile from './pages/CustomerProfile/Customerprofile';
import QuickPay from './pages/CustomerProfile/quickPay/QuickPay';
import RepairReplacement from './pages/support/Repair-replacement/RepairReplacement'
import OnsiteSupport from './pages/support/onsite-support/OnsiteSupport'
import Maintenance from './pages/support/maintance/Maintance';
import Authorized from './component/authorization/Authorized';
import ChatComponent from './Chat/NewChatComponent/ChatComponent';
import ServicesAgreement from './pages/terms&aggrement/ServicesAgreement'
import TermsOfUse from './pages/terms&aggrement/TermsOfUse';
import UserAggrement from './pages/terms&aggrement/UserAggrement';




const Main = (props) => {


    const pageNotFound = false
    const routeParams = window.location.pathname
    const topBarHandler = () => {
        alert('alert in main')
    }
    const showConfirm = () => {
        alert('this is requirement')
    }
    // const productHardware = () => {
    //     console.log('callback')
    // }
    // if (routeParams === "/") {
    //     window.location.replace('/home')
    // }
    return (

        <div>
            <Router>
                <TopBarNew topBarHandler={topBarHandler} />
                {/* <NB /> */}
                {/* <NavBAr /> */}
                <NavBarNew />
                {/* <ExampleNavBar /> */}
                <Switch>

                    <Route path="/contactus" component={ContectUs} />
                    <Route path="/profile" component={Customerprofile} />
                    <Route path="/quickpay" component={QuickPay} />
                    {/* <Route path="/career" component={Career} /> */}

                    {/* solution pages */}
                    <Route path="/solutioninstallation" component={Installation} />
                    <Route path="/bussinesssolution" component={BussinesSolution} />
                    <Route path="/prepackage" component={PrePackage} />
                    <Route path="/voip" component={Voip} />


                    <Route path="/registerdomain" component={RegisterDomain} />
                    <Route path="/transferdomain" component={TransferDomain} />
                    <Route path="/domainprice" component={DomainPrice} />
                    {/* <Route path="/blogsdetail" component={BlogsDetail} /> */}
                    <Route path="/authui" component={AuthUI} />
                    <Route path='/resetpassword/:token/:email' component={ResetPassword} />
                    <Route path="/forgotpassword" component={ForgotPassword} />
                    <Route path="/whyscstelcom" component={WhySCS} />
                    <Route path="/pagenotfount" component={Error} />
                    <Route path="/404" component={WarningPage} />

                    {/* Here is products route */}
                    <Route path="/siptrunking" component={SipTrucking} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/promotion" component={Promotion} />
                    <Route path="/managesiptrunking" component={ManageSitTrucking} />
                    <Route path="/hardware" component={Hardware} ></Route>
                    <Route path="/productDetail/:id" component={ProductDescription} />
                    <Route path="/signup" component={signUp} />
                    <Route path="/changepassword" component={ChangePassword} />
                    {/* Hereis Products routes end */}

                    {/* Support routes  */}
                    <Route path="/support-installation" component={supportInstallation} />
                    <Route path="/repair-replacement" component={RepairReplacement} />
                    <Route path="/onsitesupport" component={OnsiteSupport} />
                    <Route path="/maintanence" component={Maintenance} />


                    {/* ServicesAgreement */}
                    <Route path="/services_aggrement" component={ServicesAgreement} />
                    <Route path="/terms&uses" component={TermsOfUse} />
                    <Route path="/user-aggrement" component={UserAggrement} />

                    {/* Chat component */}
                    <Route path="/chat" component={Chat} />
                    <Route path="/join" component={Join} />


                    {/* cart and checkout routes */}
                    <Route path="/cart" component={Cart} />
                    <Route path="/billingdetail" component={BillingDetail} />
                    <Route path="/authorized" component={Authorized} />

                    <Route path="/successalert" component={SuccessAlert} />

                    <Route path="/" exact><Home /></Route>
                </Switch>
                <ChatComponent />
                <Footer />

            </Router>
        </div>
    )

}

export default Main