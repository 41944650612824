import ProductServices from '../../Services/ProductServices';
import * as ActionTypes from './actiionTypes';

const FetchedPricing = new ProductServices()

export const FetchPricing = () => (dispatch) => {
    FetchedPricing.pricingData()
    .then((res) => {
        const { data } = res
        dispatch(addPricing(data))
    })
    .catch((Error) => {
       alert('Network Error' , Error)
    })
}


export const addToCart = (item) => {
 
    return {
        type : ActionTypes.ADD_TO_CART_PRICING,
        payload : {
            price : item
        }
    }
}

export const PricingremoveFromCart = (item) => {
    return {
        type : ActionTypes.REMOVE_FROM_CART_PRICING,
        payload : {
            price : item
        }
    }
}

export const pricingIncrementAction = (item) => {
    return{
        type : ActionTypes.INC_PRICING_CART,
        payload : {
            price : item
        }
    }
}
export const pricingDecrementAction = (item) => {
    return{
        type : ActionTypes.DEC_PRICING_CART,
        payload : {
            price : item
        }
    }
}

export const addPricing = (pricing) => {
    return {
        type : ActionTypes.ADD_PRICING,
        payload : pricing
    }
}

export const updatePricing = (data) => {
    return {
        type : ActionTypes.CHANGE_MONTH,
        payload : data
    }
}

export const startedData = (date) => {
    return{
        type : ActionTypes.STARTED_DATE,
        payload : date
    }
}


export const resetPricingCart = () => {
    return {
        type: ActionTypes.RESET_PRICING_CART,
        payload: []
    }
}