import '../../css/style.css';
import '../../css/one.css';
import { useHistory } from 'react-router-dom'
const WarningPage = () => {
  const history = useHistory()


  return (
    <div id="oops" className="warning-main">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div className="vc-parent">
              <div className="vc-child">
                <div className="section-title">
                  <h2>404</h2>
                </div>
                <div className="description">
                  <p>Sorry, the page you're looking for is not found yet.</p>
                  <a className="btn" onClick={() => history.push('/home')}>Go to home page</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WarningPage;