import { toast } from 'react-toastify';
import * as ActionTypes from '../actions/actiionTypes';
import ProductService from '../../Services/ProductServices'
const productService = new ProductService();

toast.configure()
export const PricingReducers = (state = {
    pricing: [],
    priceCart: []

}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_PRICING:
            return { ...state, pricing: action.payload }

        case ActionTypes.ADD_TO_CART_PRICING:
            toast.warning('Your Product is added to cart', { autoClose: 3000 })

            if (state.priceCart.length === 0) {
                return {
                    ...state,
                    priceCart: [...state.priceCart, { ...action.payload.price, value: action.payload.price.pricing.tax_excluded }]
                }
            } else {
                let exist = state.priceCart.find(res => res._id == action.payload.price._id)
                if (exist) {
                    let existingObject = JSON.parse(JSON.stringify(exist))
                    if (!existingObject) {
                        return {
                            ...state,
                            pricings: [...state.pricings, action.payload.price]
                        }
                    } else {
                        return state
                    }
                } else {
                    return {
                        ...state,
                        priceCart: [...state.priceCart, { ...action.payload.price, value: action.payload.price.pricing.tax_excluded }]
                    }
                }
            }

        case ActionTypes.REMOVE_FROM_CART_PRICING:
            return {
                ...state,
                priceCart: state.priceCart.filter((id) => id._id !== action.payload.price._id)
            }

        case ActionTypes.CHANGE_MONTH:
            const priceCart = [...state.priceCart];
            const item = priceCart.filter((inc) => inc._id === action.payload.id)

            if (item) {
                const priceItem = priceCart.map(item => item._id === action.payload.id ? {
                    ...item,
                    month : action.payload.value,
                    pricing: {
                        ...item.pricing,
                        tax_excluded: item.value * action.payload.value
                    }
                } : item);

                return { ...state, priceCart: priceItem }
            }

        case ActionTypes.STARTED_DATE:

            const selectCart = [...state.priceCart];

            const setDate = selectCart.filter((res) => res._id === action.payload.id)

            if (setDate) {
                const dateItem = selectCart.map((res) => res._id === action.payload.id ? {
                    ...res,
                    startDate: action.payload.startDate
                } : res)
                return { ...state, priceCart: dateItem }

            }

            case ActionTypes.RESET_PRICING_CART:
                return { ...state, priceCart: [] }


        default:
            return state
    }
}