import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import './quickPay.css';
import ModalExample from './transactionPopup';
import { getUnpadBillAction, getBillByInvoice } from '../../../../store/actions/OrdreDetailAction'


const mapStateToProps = (state) => {
    return {
        unpaidOrderHistory: state.orderDetailReducers.unpaidBillDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUnpaidBill: (email) => dispatch(getUnpadBillAction(email))
    }
}

const QuickPay = (props) => {
    const getUserInfo = JSON.parse(localStorage.getItem('userInfo'))

    const [email, setemail] = useState(getUserInfo?.email)
    const [search, setSearch] = useState('');
    const [searchedInvoice, setSearchedInvoice] = useState(null);
    const [clicked, setClicked] = useState(false);


    useEffect(() => {

        props.getUnpaidBill(email)
    },

        [])

    if(getUserInfo){
        if (props.unpaidOrderHistory?.bill?.length == 0) {
        return (
            <div className='d-flex justify-content-center' style={{ marginTop: '50px', height: '100vh' }}>
                <h2>You have no unpaid bill</h2>
            </div>
        )
    }
    else {
        return (
            <div>
                {/* <!-- Breadcrumb Wrapper Start --> */}
                <div className="breadcrumb-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7">
                                <h2>Quick Pay</h2>
                            </div>
                            <div className="col-sm-5">
                                <div className="inner-breadcrumb">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li>Quick Pay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Breadcrumb Wrapper End -->  */}
                <div className="container" >

                    <div className="order-detail-main" >
                        <div className="heading-below-line">
                            <h3>Your Unpaid Orders</h3>
                        </div>
                        <div className="order-detail"  >
                            <Table hover className="table-main-order" style={{fontSize  :'1rem'}}  >
                                <thead>
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Invoice Number</th>
                                        <th>Total Products/Services</th>
                                        <th>Total Amount</th>
                                        <th>Expiry Date</th>
                                        <th>Pay</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.unpaidOrderHistory?.bill?.map((res, i) => {
                                        console.log(res);
                                        return (
                                            <tr key={i}>
                                                <th>{i + 1}</th>
                                                <th>{res.billingDetails?.invoice_number}</th>
                                                <th>
                                                    {res.cartDetail?.cart?.nonRecurring?.map((res) => <p>{res.product_name}</p>)}
                                                    {res.cartDetail?.cart?.recurring?.map((res) => <p>{res.packageName}</p>)}
                                                </th>
                                                <th>{res.cartDetail?.totalAmount}</th>
                                                <th>{res.billingDetails.chargesAfterDueDate}</th>
                                                <th> <ModalExample totalAmount={res.cartDetail?.totalAmount} invoicenumber={res.billingDetails?.invoice_number} userDetails={res.userDetails}/> </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    }else{
        if (!clicked) {
        return (
            <div>
                <div className="breadcrumb-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7">
                                <h2>Quick Pay</h2>
                            </div>
                            <div className="col-sm-5">
                                <div className="inner-breadcrumb">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li>Quick Pay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='center' style={{ marginTop: '50px', height: '100vh' }}>
                
                    <div className='searchInvoice'>
                            <input
                    value={search}
                    type='text' 
                    placeholder='search bills' 
                    onChange={e => setSearch(e.target.value)}
                    />
                    
                    <button onClick={
                            
                            ()=>{ 
                                    getBillByInvoice(search).then(res => {
                                        setSearchedInvoice(res)
                                        setClicked(true)
                                        })
                                }
                            
                        }>Search</button>
                        </div>
                        <br />
                    <h2>Search Your Invoices</h2>
                </div>
             </div>
        )
    }else{
        return (
            <div>
                {/* <!-- Breadcrumb Wrapper Start --> */}
                <div className="breadcrumb-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7">
                                <h2>Quick Pay</h2>
                            </div>
                            <div className="col-sm-5">
                                <div className="inner-breadcrumb">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li>Quick Pay</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Breadcrumb Wrapper End -->  */}
                <div className="container" >
                <div className='center' style={{ marginTop: '50px', height: 'auto' }}>
                    <div className='searchInvoice'>
                        <input
                 value={search}
                 type='text' 
                 placeholder='search bills' 
                 onChange={e => setSearch(e.target.value)}
                //  style={{marginTop:'50px'}}
                />
                <button 
                //   style={{marginTop:'50px'}}
                  onClick={
                        
                        ()=>{ 
                                getBillByInvoice(search).then(res => {
                                    setSearchedInvoice(res)
                                    console.log(searchedInvoice)
                                    setClicked(true)
                                    })
                            }
                        
                    }>Search</button>
                    </div>
                    {
                        searchedInvoice !== 'nothing found'
                    ?(
                        <div className="order-detail-main" >
                        <div className="heading-below-line">
                            <h3>Your Invoice</h3>
                        </div>
                        <div className="order-detail"  >
                            <Table hover className="table-main-order" style={{fontSize  :'1rem'}}  >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Invoice Number</th>
                                        <th>Total Products/Services</th>
                                        <th>Total Amount</th>
                                        <th>Expiry Date</th>
                                        <th>Pay</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchedInvoice?.map((res, i) => {
                                        return (
                                            <tr key={i}>
                                                <th>{i + 1}</th>
                                                <th>{res.billingDetails?.invoice_number}</th>
                                                <th>
                                                    {res.cartDetail?.cart?.nonRecurring?.map((res) => <p>{res.product_name}</p>)}
                                                    {res.cartDetail?.cart?.recurring?.map((res) => <p>{res.packageName}</p>)}
                                                </th>
                                                <th>{res.cartDetail?.totalAmount}</th>
                                                <th>{res.billingDetails.chargesAfterDueDate}</th>
                                                <th> <ModalExample totalAmount={res.cartDetail?.totalAmount} invoicenumber={res.billingDetails?.invoice_number} userDetails={res.userDetails} /> </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    ): (
                        <div style={{height:'80vh'}}>
                                <br />
                                <h2>No Invoice found</h2>
                            
                        </div>
                        ) }
                    </div>
                </div>
            </div>
        );
    }}
        

    

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickPay))
