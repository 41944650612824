import React, { Component } from 'react'
import axios from 'axios';
import { API_BASE } from '../Config'

let instance = axios.create({
    baseURL: API_BASE + 'api/chat'
})

class ChatService extends Component {
    createChatContact(data) {
        return instance.post('/create/chatContacts', data)
    }
    getChatContact(data) {
        return instance.post('/get/chatContact', data)
    }
    createChatAndChatList(data) {
        return instance.post('/post-newMessage', data)
    }
    getChat(data) {
        return instance.post('/getChat-with-click', data)
    }
}

export default ChatService
