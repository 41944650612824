import React from 'react'
import './style.css';
import cover from '../../../../images/supp_installation.jpg';
import {Link} from 'react-router-dom'
function Installation() {
    return (
        <div className="support__inst__main__div">
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div class="breadcrumb-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-7">
                            <h2>Installation</h2>
                        </div>

                        <div class="col-sm-5">
                            <div class="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Support</li>
                                    <li>Installation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}

            {/* <!-- Inner Wrapper Start --> */}
            <div className="inner-page-wrapper">
                <div className="support_inst_main_div">
                    <div class="row" >
                    <div  data-aos="fade-right" class="col-lg-8 col-md-8 col-sm-12 secondColumn" >
                            <img src={cover} alt="" className="cover__image"></img>
                            <div className="supp__ins_content">
                                <h2>Let SCS Telcom help your business with our variety of service plans and phone hardware options.</h2>
                                <p>
                                SCS Telcom provides Voice Over IP Phone Solutions with voicemail, auto-attendants, multi-line capabilities, and more for you and your business.
                                
                                </p>
                                <p>
                                <strong style={{ color: '#7ebc12' , fontSize:"initial" }}>SCS Telcom</strong>&nbsp;
                                making the switch to a VoIP phone system will cut costs, increase functionality, increase flexibility, enhance your team’s mobility, and more. 
                                </p>
                                <div className="cabling__list">   
                                
                                    <ul >
                                        <li>High-functioning phone systems </li>
                                        <li>Forward calls to your cell phones</li>
                                        <li>Integrate your fax system</li>
                                        <li>Customize your settings </li>
                                        <li>Increase your company’s performance </li>
                                        <li>Stability</li>
                                        <li>All at an affordable price</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 firstColumn">
                            <div  data-aos="zoom-in"  className="quote__div">
                                <div>
                                <h3>Request a Quote</h3>
                                <p className="alignment__left">
                                Need a quote for our Managed IT Services? Please click on the button below to provide more information about your requirements and a rep will reach out to you.
                                </p>
                                <Link to="/" className="request-quote-button" style={{ textDecoration: 'none' }}>Get a Quote</Link>
                            </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Installation
