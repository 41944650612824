import React, { useEffect, useState } from 'react';
import './Cart.css';
import swal from 'sweetalert';
import { Link, withRouter, useHistory, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import Default from '../../scs-img/default.jpg';
import { API_BASE } from './../../Config';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  PricingremoveFromCart,
  pricingIncrementAction,
  pricingDecrementAction,
  updatePricing,
  startedData,
} from '../../store/actions/PricingActionCreator';
import {
  sipremoveFromCart,
  sipChangeDate,
  sipChangeMonth,
  checkSipDate,
} from '../../store/actions/siptrunkingAciton';
import {
  HardwareremoveFromCart,
  HardwareIncrementAction,
  HardwareDecrementAction,
} from '../../store/actions/HardwareActions';
import {
  removeToCartMST,
  mstchangeMonth,
  mststartedData,
} from '../../store/actions/MSPaction';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const Cart = (props) => {
  console.log(props);
  let history = useHistory();
  const [token, setToken] = useState(sessionStorage.getItem('access_Token'));
  const [issubmtted, setissubmtted] = useState(true);
  const [errorMessage, seterrorMessage] = useState('');
  const [NonRecurring, setNonRecurring] = useState([]);
  const [Recurring, setRecurring] = useState([]);
  const [dateNow, setdateNow] = useState(
    new Date().toISOString().split('T')[0]
  );

  const removeCartItemHandler = (pro) => {
    props.removeHardwareHandler(pro);
    props.removeSipHandler(pro);
    props.removePricingHandler(pro);
    props.removeMSPFromCart(pro);
  };

  const popUpDeleteAlert = (pro) => {
    swal({
      title: 'Are you sure?',
      text: `You want to delete it`,
      buttons: ['Cancel', 'Delete'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        removeCartItemHandler(pro);
      }
    });
  };

  const totalHarwarePrice = () => {
    let totalAmount = 0;
    props.hardware.HardwareCart.map((result) => {
      let amount = result.quantity * result.pricing.tax_excluded;
      totalAmount = totalAmount + amount;
    });
    return totalAmount;
  };

  const totalSipPrice = () => {
    let totalsip = 0;
    props.sipDataRes.map((result) => {
      let amount = result.month * result.pricing.tax_excluded;
      totalsip = totalsip + amount;
    });

    return totalsip;
  };

  const totalmstPrice = () => {
    let findtotal = props.mst
      .map((res) => res.pricing.tax_included)
      .reduce((first, second) => parseInt(first) + parseInt(second), 0);
    return findtotal;
  };

  const totalmsttaxt = () => {
    let findtax = props.mst
      .map((res) => res.pricing.tax_rate)
      .reduce((first, second) => parseInt(first) + parseInt(second), 0);
    return findtax;
  };
  const totalPricingPrice = () => {
    let totalprice = 0;
    props.pricingDataRes.map((res, i) => {
      let amount = res.month * res.pricing.tax_excluded;
      totalprice = parseFloat((totalprice + amount).toFixed(2));
    });

    return totalprice;
  };
  const hardwareTax = () => {
    let taxvalue = 0;
    props.hardware.HardwareCart.map((result) => {
      let amount = result.quantity * result.pricing.tax_rate;
      taxvalue = taxvalue + amount;
    });
    return taxvalue;
  };

  const priceTax = () => {
    let value = 0;
    let taxamount = props.pricingDataRes.map((tax) => {
      let taxamount = tax.month * tax.pricing.tax_rate;
      value = value + taxamount;
    });
    return value;
  };

  const sipTax = () => {
    let value = 0;
    let taxamount = props.sipDataRes.map((tax) => {
      let taxamount = tax.month * tax.pricing.tax_rate;
      value = value + taxamount;
    });
    return value;
  };

  const totalCartProductPrice =
    totalHarwarePrice() +
    totalPricingPrice() +
    totalSipPrice() +
    totalmstPrice();
  const totalCartProductTax =
    hardwareTax() + priceTax() + totalmsttaxt() + sipTax();
  const totalBeforeRoundOff = totalCartProductPrice + totalCartProductTax;
  const totalAmountString = totalBeforeRoundOff.toFixed(2);
  const totalAmount = parseFloat(totalAmountString);
  console.log(totalAmount);

  const checkoutHandler = () => {
    setRecurring([]);
    setNonRecurring([]);
    if (props.sipDataRes.length > 0) {
      props.sipDataRes.map((res) => {
        let withpackageName = { ...res, packageName: res.heading };
        Recurring.push(withpackageName);
      });
    }
    if (props.pricingDataRes.length > 0) {
      props.pricingDataRes.map((res) => {
        let withpackageName = { ...res, packageName: res.name };
        Recurring.push(withpackageName);
      });
    }
    if (props.mst.length > 0) {
      props.mst.map((res) => {
        let withpackageName = { ...res, packageName: res.heading.heading };
        Recurring.push(withpackageName);
      });
    }
    if (props.hardware.HardwareCart.length > 0) {
      props.hardware.HardwareCart.map((res) => {
        NonRecurring.push(res);
      });
    }
    checkDataAndMonth();
  };

  const mergeProductArray = (NewArray) => {
    let cartDataObj = {};
    if (NonRecurring.length > 0) {
      cartDataObj['nonRecurring'] = NonRecurring;
    }
    if (Recurring.length > 0) {
      cartDataObj['recurring'] = NewArray;
    }

    checkOutPassed(cartDataObj);
  };

  const checkDataAndMonth = () => {
    let checkExpireDate = 0;
    for (let i = 0; i < Recurring.length; i++) {
      if (Recurring[i].startDate) {
        checkExpireDate++;
      } else {
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth',
        });
        setissubmtted(false);
        seterrorMessage('Select date and month');
        break;
      }
    }
    checkExpiry(checkExpireDate);
  };
  function checkExpiry(check) {
    let NewArray = [];
    if (Recurring.length == check) {
      let recArray = Recurring;

      recArray.map((ress) => {
        let expireDate = moment(ress.startDate, 'YYYY-MM-DD')
          .add(ress.month, 'M')
          .format('YYYY-MM-DD');
        let newRes = { ...ress, expireDate: expireDate };
        console.log('newRes', newRes);

        NewArray.push(newRes);
        console.log('NewArray a', NewArray);
        setRecurring(NewArray);
      });
      mergeProductArray(NewArray);
    }
  }

  function checkOutPassed(allProductArray) {
    if (token) {
      props.history.push({
        pathname: '/billingdetail',
        state: {
          cart: allProductArray,
          tax: totalCartProductTax,
          totalAmount: totalAmount,
          subTotal: totalCartProductPrice,
        },
      });
    } else {
      Swal.fire({
        title: 'Oops !',
        icon: 'error',
        text: 'Please sign in first!',
        confirmButtonColor: '#7ebc12',
        confirmButtonText: 'Sign In',
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          history.push('/authui');
        }
      });
    }
  }

  const modalImageHandler = (image) => {
    Swal.fire({
      imageUrl: image,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: image,
      // showCloseButton: true,
      buttons: false,
    });
  };

  if (
    props.hardware.HardwareCart.length !== 0 ||
    props.sipDataRes.length !== 0 ||
    props.pricingDataRes.length !== 0 ||
    props.mst.length !== 0
  ) {
    return (
      <div className='cart-main'>
        <div className='container'>
          {issubmtted ? (
            ''
          ) : (
            <div class='alert alert-danger' role='alert'>
              {' '}
              <p style={{ fontSize: '1rem', margin: '0px' }}>
                {errorMessage}
              </p>{' '}
            </div>
          )}
          <div className='cart-heading'>
            <div className='title'>
              <h2>Cart</h2>
              <span className='heading-line'></span>
            </div>
          </div>
          <div className='product-item-list'>
            <div className='cart-table-view'>
              <Table
                striped
                bordered
                hover
                style={{ textAlign: 'center', fontSize: '1rem' }}
              >
                <thead style={{ backgroundColor: '#7ebc12', color: 'white' }}>
                  <tr>
                    <th scope='col'>Quantity</th>
                    <th scope='col'>Product Image</th>
                    <th scope='col'>Products</th>
                    <th scope='col'>Value</th>
                    <th scope='col'>Quantity/Month</th>
                    {props.sipDataRes.length > 0 ||
                    props.mst.length > 0 ||
                    props.pricingDataRes.length > 0 ? (
                      <th> Start Date</th>
                    ) : (
                      ''
                    )}
                    <th scope='col'>Price</th>
                    <th scope='col'>Delete</th>
                  </tr>
                </thead>

                <tbody>
                  {props.hardware.HardwareCart.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Hardware Products
                    </p>
                  ) : (
                    ''
                  )}

                  {props.hardware.HardwareCart &&
                    props.hardware.HardwareCart.map((pro, index) => {
                      console.log('ptooooooooooooooo........', pro);

                      let images = pro.productImage.split(/\\|\//g);
                      let imgUrl = images[images.length - 1];

                      return (
                        <tr key={pro._id}>
                          <th scope='row'>{index + 1}</th>
                          {/* <td> <img className="img-fluid img-thumbnail cart-image" width="100px" src={Default} alt={pro.productName} />  </td> */}
                          <td>
                            {' '}
                            <img
                              onClick={() =>
                                modalImageHandler(API_BASE + imgUrl)
                              }
                              className='img-fluid img-thumbnail cart-image'
                              width='100px'
                              src={imgUrl ? API_BASE + imgUrl : Default}
                              alt={pro.productName}
                            />{' '}
                          </td>
                          <td> {pro.product_name}</td>
                          <td>${pro.pricing.tax_excluded}</td>
                          <td className='main-counter-cart'>
                            <div className='cart-qu-div'>
                              <div className='counter-cart-button'>
                                <button
                                  onClick={() =>
                                    props.HardwareDecrementAction(pro)
                                  }
                                  className='counter-cart-dec-btn'
                                >
                                  -
                                </button>
                                <span className='counter-cart'>
                                  {' '}
                                  {pro.quantity}{' '}
                                </span>
                                <button
                                  onClick={() =>
                                    props.HardwareIncrementAction(pro)
                                  }
                                  className='counter-cart-inc-btn'
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </td>
                          {props.sipDataRes.length > 0 ||
                          props.mst.length > 0 ||
                          props.pricingDataRes.length > 0 ? (
                            <td></td>
                          ) : (
                            ''
                          )}
                          <td className='cart-price-fun'>
                            ${pro.quantity * pro.pricing.tax_excluded}
                          </td>
                          <td>
                            {' '}
                            <span onClick={() => popUpDeleteAlert(pro)}>
                              <i className='fa fa-trash' aria-hidden='true'></i>
                            </span>{' '}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tbody>
                  {props.pricingDataRes && props.pricingDataRes.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Pricing Detail
                    </p>
                  ) : (
                    ''
                  )}
                  {props.pricingDataRes &&
                    props.pricingDataRes.map((pro, index) => {
                      console.log('pricing--------------', pro);
                      return (
                        <tr key={pro._id}>
                          <th scope='row'>{index + 1}</th>
                          <td>
                            {' '}
                            <img
                              className='img-fluid img-thumbnail cart-image'
                              width='100px'
                              src={Default}
                              alt={pro.productName}
                            />{' '}
                          </td>
                          <td> {pro.name}</td>
                          <td>${pro.value}</td>
                          <td className='main-counter-cart'>
                            <div className='cart-qu-div'>
                              <div className='pricing-month-style'>
                                {/* <select value={pro.month} onChange={(e) => props.updatePricingMonths({ value: e.target.value, id: pro._id })} className="form-select form-select-sm dropdown-pricing" aria-label=".form-select-sm example" >
                                                                    <option value={1} >1 Month</option>
                                                                    <option value={3} >3 Months</option>
                                                                    <option value={6} >6 Months</option>
                                                                    <option value={9} >9 Months</option>
                                                                </select> */}
                                <p>
                                  Item is charged
                                  <br />
                                  monthly recurring billing
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='pricing-month-style'>
                              <input
                                min={dateNow}
                                required
                                data-date-format='YYYY MMMM DD'
                                className='dropdown-pricing'
                                value={pro.startDate}
                                type='date'
                                onChange={(e) =>
                                  props.updateSelectedDate({
                                    startDate: e.target.value,
                                    id: pro._id,
                                  })
                                }
                                onKeyDown={(e) => e.preventDefault()}
                              />
                              {/* <DatePicker
                                                         minDate={dateNow}
                                                         selected={pro.startDate}
                                                         onChange={(date) => {
                                                             props.updateSelectedDate({ startDate: date, id: pro._id })
                                                             console.log(date)
                                                         }}
                                                         dateFormat='yyyy/MM/dd'
                                                         placeholderText='YYYY/MM/DD'
                                                         /> */}
                            </div>
                          </td>
                          <td>${pro.pricing.tax_excluded}</td>
                          <td>
                            {' '}
                            <span onClick={() => popUpDeleteAlert(pro)}>
                              <i className='fa fa-trash' aria-hidden='true'></i>
                            </span>{' '}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tbody>
                  {props.mst && props.mst.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Managed SIP Trunking
                    </p>
                  ) : (
                    ''
                  )}
                  {props.mst &&
                    props.mst.map((pro, index) => {
                      return (
                        <tr key={index}>
                          <th scope='row'>{index + 1}</th>
                          <td>
                            {' '}
                            <img
                              className='img-fluid img-thumbnail cart-image'
                              width='100px'
                              src={Default}
                              alt={pro.productName}
                            />{' '}
                          </td>
                          <td> {pro.heading.heading}</td>
                          <td>${pro.value}</td>
                          <td className='main-counter-cart'>
                            <div className='cart-qu-div'>
                              <div className='pricing-month-style'>
                                {/* <select value={pro.month} onChange={(e) => props.selectedchangeMonth({ selectMonth: e.target.value, mst: pro })} className="form-select form-select-sm dropdown-pricing" aria-label=".form-select-sm example" >
                                                                    <option value={1} >1 Month</option>
                                                                    <option value={3} >3 Months</option>
                                                                    <option value={6} >6 Months</option>
                                                                    <option value={9} >9 Months</option>
                                                                </select> */}
                                <p>
                                  Item is charged
                                  <br />
                                  monthly recurring billing
                                </p>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className='pricing-month-style'>
                              <input
                                min={dateNow}
                                onChange={(e) =>
                                  props.mstselectedData({
                                    startDate: e.target.value,
                                    mst: pro,
                                  })
                                }
                                className='dropdown-pricing'
                                value={pro.startDate}
                                type='date'
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </div>
                          </td>

                          <td>${pro.pricing.tax_included}</td>
                          <td>
                            {' '}
                            <span onClick={() => popUpDeleteAlert(index)}>
                              <i className='fa fa-trash' aria-hidden='true'></i>
                            </span>{' '}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tbody>
                  {props.sipDataRes && props.sipDataRes.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Sip Trunking Detail
                    </p>
                  ) : (
                    ''
                  )}
                  {props.sipDataRes &&
                    props.sipDataRes.map((pro, index) => {
                      // let images = pro.image.split('\\')
                      // let imgUrl = images[images.length -1]

                      return (
                        <tr key={pro._id}>
                          <th scope='row'>{index + 1}</th>
                          <td>
                            {' '}
                            <img
                              className='img-fluid img-thumbnail cart-image'
                              width='100px'
                              src={Default}
                              alt={pro.heading}
                            />{' '}
                          </td>
                          {/* <td> <img onClick={() => modalImageHandler(API_BASE+imgUrl)} className="img-fluid img-thumbnail cart-image" width="100px" src={imgUrl?API_BASE+imgUrl:Default} alt={pro.productName} />  </td> */}
                          <td> {pro.heading}</td>
                          <td>${pro.value}</td>
                          <td className='main-counter-cart'>
                            <div className='cart-qu-div'>
                              <div className='pricing-month-style'>
                                {/* <select value={pro.month} onChange={(e) => props.updateSipMonth({ selectMonth: e.target.value, sip: pro })} className="form-select form-select-sm dropdown-pricing" aria-label=".form-select-sm example" >
                                                                    <option value={1} >1 Month</option>
                                                                    <option value={3} >3 Months</option>
                                                                    <option value={6} >6 Months</option>
                                                                    <option value={9} >9 Months</option>
                                                                </select> */}
                                <p>
                                  Item is charged
                                  <br />
                                  monthly recurring billing
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='pricing-month-style'>
                              <input
                                min={dateNow}
                                value={pro.startDate}
                                onChange={(e) =>
                                  props.updateSipDate({
                                    startDate: e.target.value,
                                    sip: pro,
                                  })
                                }
                                className='dropdown-pricing'
                                type='date'
                                onKeyDown={(e) => e.preventDefault()}
                              />
                            </div>
                          </td>

                          <td>${pro.pricing.tax_excluded}</td>
                          <td>
                            {' '}
                            <span onClick={() => popUpDeleteAlert(pro)}>
                              <i className='fa fa-trash' aria-hidden='true'></i>
                            </span>{' '}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            {/* ----------------------------------------------------------------------------------------------------------------------------------- -----------------------------------------*/}
            <div className='cart-table-view'>
              <div className='heading-below-line'>
                <h3>Tax List</h3>
              </div>
              <Table
                striped
                bordered
                hover
                style={{ textAlign: 'center', fontSize: '0.8rem' }}
              >
                <thead style={{ backgroundColor: '#7ebc12', color: 'white' }}>
                  <tr>
                    <th scope='col'>Sales Tax</th>
                    <th scope='col'>County Sales Tax</th>
                    <th scope='col'>City Sales Tax</th>
                    <th scope='col'>State Sales and Telephone Tax</th>
                    <th scope='col'>
                      State Cost Recovery Charge
                      {/*, Munincipal Row Fee, Universal
                      Connectivity Charge*/}
                    </th>
                    <th scope='col'>
                      State P.U.C. Surcharge {/*  Federal Cost Recovery Fee, */}
                    </th>
                    <th scope='col'>Total Tax</th>
                  </tr>
                </thead>

                <tbody>
                  {props.hardware.HardwareCart.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Hardware Products
                    </p>
                  ) : (
                    ''
                  )}

                  {props.hardware.HardwareCart &&
                    props.hardware.HardwareCart.map((pro, index) => {
                      console.log('ptooooooooooooooo........', pro);

                      return (
                        <tr key={pro._id}>
                          <td>
                            ${(pro.pricing.sales_tax * pro.quantity).toFixed(2)}
                          </td>
                          <td>$0</td>
                          {/* <td> <img className="img-fluid img-thumbnail cart-image" width="100px" src={Default} alt={pro.productName} />  </td> */}
                          <td>$0</td>
                          <td>$0</td>
                          <td>$0</td>
                          <td>$0</td>
                          <td className='cart-price-fun'>
                            ${(pro.pricing.tax_rate * pro.quantity).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tbody>
                  {props.pricingDataRes && props.pricingDataRes.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Pricing Detail
                    </p>
                  ) : (
                    ''
                  )}
                  {props.pricingDataRes &&
                    props.pricingDataRes.map((pro, index) => {
                      console.log('pricing--------------', pro.pricing);
                      return (
                        <tr key={pro._id}>
                          <td>$0.00</td>
                          <td>
                            ${(pro.pricing.county_tax * pro.month).toFixed(2)}
                          </td>
                          {/* <td> <img className="img-fluid img-thumbnail cart-image" width="100px" src={Default} alt={pro.productName} />  </td> */}
                          <td>
                            ${(pro.pricing.city_tax * pro.month).toFixed(2)}
                          </td>
                          <td>
                            ${(pro.pricing.state_tax * pro.month).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(pro.pricing.universal_charge * pro.month).toFixed(
                              2
                            )}
                          </td>
                          <td>
                            $
                            {(pro.pricing.puc_surcharge * pro.month).toFixed(2)}
                          </td>
                          <td className='cart-price-fun'>
                            ${(pro.pricing.tax_rate * pro.month).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tbody>
                  {props.mst && props.mst.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Managed SIP Trunking
                    </p>
                  ) : (
                    ''
                  )}
                  {props.mst &&
                    props.mst.map((pro, index) => {
                      console.log(pro);
                      return (
                        <tr key={pro._id}>
                          <td>$0.00</td>
                          <td>${pro.pricing.county_tax.toFixed(2)}</td>
                          {/* <td> <img className="img-fluid img-thumbnail cart-image" width="100px" src={Default} alt={pro.productName} />  </td> */}
                          <td>${pro.pricing.city_tax.toFixed(2)}</td>
                          <td>${pro.pricing.state_tax.toFixed(2)}</td>
                          <td>${pro.pricing.universal_charge.toFixed(2)}</td>
                          <td>${pro.pricing.puc_surcharge.toFixed(2)}</td>
                          <td className='cart-price-fun'>
                            ${pro.pricing.tax_rate.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tbody>
                  {props.sipDataRes && props.sipDataRes.length > 0 ? (
                    <p style={{ color: '#7ebc12', margin: '0' }}>
                      Sip Trunking Detail
                    </p>
                  ) : (
                    ''
                  )}
                  {props.sipDataRes &&
                    props.sipDataRes.map((pro, index) => {
                      // let images = pro.image.split('\\')
                      // let imgUrl = images[images.length -1]

                      return (
                        <tr key={pro._id}>
                          <td>$0.00</td>
                          <td>
                            ${(pro.pricing.county_tax * pro.month).toFixed(2)}
                          </td>
                          {/* <td> <img className="img-fluid img-thumbnail cart-image" width="100px" src={Default} alt={pro.productName} />  </td> */}
                          <td>
                            ${(pro.pricing.city_tax * pro.month).toFixed(2)}
                          </td>
                          <td>
                            ${(pro.pricing.state_tax * pro.month).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(pro.pricing.universal_charge * pro.month).toFixed(
                              2
                            )}
                          </td>
                          <td>
                            $
                            {(pro.pricing.puc_surcharge * pro.month).toFixed(2)}
                          </td>
                          <td className='cart-price-fun'>
                            ${(pro.pricing.tax_rate * pro.month).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            {/* ----------------------------------------------------------------------------------------------------------------------------- */}

            <div className='card-total-div'>
              <div className='heading-below-line'>
                <h3>Cart Total</h3>
              </div>
              <div className='cart-total-content'>
                <div className='cart-total-amount'>
                  <p>Subtotal : </p>
                  <h6>${totalCartProductPrice} </h6>
                </div>
                <div className='cart-total-amount'>
                  <p>Shipping : </p>
                  <h6>---</h6>
                </div>
                <div className='cart-total-amount'>
                  <p>Tax : </p>
                  <h6>${totalCartProductTax.toFixed(2)}</h6>
                </div>
                <hr />
                <div className='cart-total-amount'>
                  <p>Total Amount : </p>
                  <h6> ${totalAmount} </h6>
                </div>
              </div>
            </div>
            <div className='price-cart-section'>
              <div className='row'>
                <div className='col-lg-6 checkout-button'>
                  <button
                    onClick={checkoutHandler}
                    className='cart-coupon-button'
                  >
                    Proceed to checkout
                  </button>
                </div>
                <div className='col-lg-6'>
                  <div className='row coupon-div'>
                    <div className='col-lg-6 '>
                      <div className='coupon-code-div'>
                        <input
                          className='coupon-input'
                          type='text'
                          placeholder='Coupon Code'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <button className='cart-coupon-button'>
                        Apply Coupon
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='cart-empty-message'>
        <div className='container'>
          <div className='cart-heading'>
            <div className='title'>
              <h2>Cart</h2>
              <span className='heading-line'></span>
            </div>
          </div>
          <div className='empty-cart'>
            <h1>Cart is empty</h1>
          </div>
          <div className=''>
            <Link to='/' className='cart-coupon-button'>
              Go to home
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    sipDataRes: state.sipTrunkings.SipCart,
    pricingDataRes: state.pricing.priceCart,
    hardware: state.hardwares,
    mst: state.mstResData.mstCart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // removeHandler: (id) => dispatch(removeFromCart(id)),

    //here is hardware dispatch fucn
    removeHardwareHandler: (pro) => dispatch(HardwareremoveFromCart(pro)),
    HardwareIncrementAction: (pro) => dispatch(HardwareIncrementAction(pro)),
    HardwareDecrementAction: (pro) => dispatch(HardwareDecrementAction(pro)),

    //here is pricing dispatch fucn
    removePricingHandler: (price) => dispatch(PricingremoveFromCart(price)),
    incrementPricingHandler: (price) => dispatch(pricingIncrementAction(price)),
    decrementPricingHandler: (price) => dispatch(pricingDecrementAction(price)),
    updatePricingMonths: (data) => dispatch(updatePricing(data)),
    updateSelectedDate: (date) => dispatch(startedData(date)),

    //here is siptrucnking dispatch fuck
    removeSipHandler: (pro) => dispatch(sipremoveFromCart(pro)),
    updateSipMonth: (month) => dispatch(sipChangeMonth(month)),
    updateSipDate: (date) => dispatch(sipChangeDate(date)),
    checkSipDate: (no) => dispatch(checkSipDate(no)),
    // removeHardware:(data)=>dispatch(removeHardware(data))

    //here is msp sip trcking dispatcher
    removeMSPFromCart: (i) => dispatch(removeToCartMST(i)),
    selectedchangeMonth: (data) => dispatch(mstchangeMonth(data)),
    mstselectedData: (data) => dispatch(mststartedData(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
