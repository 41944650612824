
let initialState = {
    isGuestLogin: false,
    userChatId: '111ccd111305110004782bb5',
    user: null
}

const ChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_INITIAL_STATE':
            return {
                ...initialState
            }
        case 'GUEST_LOGIN_TRUE':
            console.log(action.payload, 'in the chat reducer')
            return {
                ...state,
                isGuestLogin: true,
                user: action.payload
            }
        case 'GUEST_LOGIN_FALSE':
            return {
                ...state,
                isGuestLogin: false
            }

    }
    return state
}


export default ChatReducer;