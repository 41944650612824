import React from 'react'
import { useParams, Link, withRouter } from 'react-router-dom';
import { HardwareProduct } from '../hardware/hardwareSampleData';
import './Hardware.css';
import { connect } from 'react-redux';
import { API_BASE } from './../../../Config'
import Default from '../../../scs-img/default.jpg';


const mapStateToProps = (state) => {
    return {
        products: state.hardwares.products,

    }
}



const ProductDescription = (products) => {
    const param = useParams()
    const requiredIndex = param.id
    const get = products[requiredIndex]



    const getspecificid = products.products.filter((id) => id._id === requiredIndex).map(res => {
        return {
            name : res.product_name,
            productDetail : res.detail,
            description : res.description,
            features : res.features,
            productImage : res.productImage
        }
    })




    let images = getspecificid[0].productImage.split('\\')

    let imgUrl = images[images.length - 1]

    console.log(imgUrl , 'imgUrl')

    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            {/* <h2>{get.productName}</h2> */}
                        </div>

                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/hardware">Products</Link></li>
                                    <li>{getspecificid[0].name}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            
            <div className="container bcontent">
                <div className="heading-below-line">

                    <h3>{getspecificid[0].name}</h3>
                </div>
                <div className="card" style={{ width: "100%", background: '#f2f2f2' }}>
                    <div className="row no-gutters">
                        <div className="col-sm-5">
                            <img className="card-img" src={API_BASE + imgUrl} alt={getspecificid[0].name} />
                        </div>
                        <div className="col-sm-7">
                            <div className="card-body">
                                <p className="card-text"> {getspecificid[0].productDetail} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="heading-below-line">
                        <h3>Description</h3>
                    </div>
                    <div className="hardware-feature-description" >
                        <p>{getspecificid[0].description}</p>
                    </div>
                </div>
                <div>
                    <div className="heading-below-line">
                        <h3>Features</h3>
                    </div>
                    {getspecificid[0].features.map((feature) => {
                        return (
                            <div className="hardware-feature-list">
                                <ul >
                                    <li>{feature}</li>
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps)(ProductDescription))
