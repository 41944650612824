import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Phone from '../../../images/cta-phone-icon.png';
import './sipTrucking.css';
import { connect } from 'react-redux'
import { siptrunkingFetched } from '../../../store/actions/siptrunkingAciton';
import { toast } from 'react-toastify';
import { sipaddToCart } from '../../../store/actions/siptrunkingAciton'

const mapDispatchToProps = (dispatch) => {
    return {
        siptrunkingData: () => { dispatch(siptrunkingFetched()) },
        sendItemToAction: (pro) => { dispatch(sipaddToCart(pro)) }
    }
}

const mapStateToProps = (state) => {
    return {
        sipTrunkingD: state.sipTrunkings.sipTrunk
    }
}
const SipTrucking = (props) => {
    useEffect(() => { props.siptrunkingData() }, [])

    if (props.sipTrunkingD.length === 0) return (
        <div style={{ margin: '130px 0' }} className="d-flex justify-content-center">
            <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    )
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2>SIP Trunking</h2>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Products</li>
                                    <li>SIP Trunking</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            {/* <!-- Hosting Plans Wrapper Start --> */}
            <section className="hosting-plans-wrapper">
                <div className="container">
                    <div className="title">
                        <h2>Wide range of Hosting Plans</h2>
                        <span className="heading-line"></span> </div>
                    <div className="pricing-table pricing-section-secondary">
                        <div className="row" >
                            {props.sipTrunkingD.map((data, i) => {
                                return (
                                    <div data-aos="fade-right" key={i} className="col-lg-4 col-md-4 col-sm-6" >
                                        <div className="price-content-wrap">
                                            <div className="plan-price">
                                                <h4><span>$</span>{data.pricing.tax_excluded}</h4>
                                            </div>
                                            <div className="plan-name">
                                                <p className="sub"> {data.duration} </p>
                                                <h3 className="price-title"> {data.heading} </h3>
                                            </div>
                                            <ul className="price-details">
                                                {data.packages.map((listD, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="pakage-card-row">
                                                                <span className="s1"><p>{listD.feature}</p></span>
                                                                <span className="s2"><p>&#8212;</p></span>
                                                                <span className="s3"><p>{listD.description}</p></span>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            {/* <button onClick={() => addsiptruckingCartHandler(data)} className="btn mian-btn price-btn">Add to cart</button> */}
                                            <button onClick={() => props.sendItemToAction(data)} className="btn mian-btn price-btn">Add to cart</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Hosting Plans Wrapper End -->  */}
            {/* <!-- cta wrapper Start --> */}
            <div class="cta-wrapper">
                <div class="container">
                    <div class="text-center">
                        <div class="cta-icon"><img src={Phone} alt="" /> </div>
                        <h3>Need Help? Call Us 24/7</h3>
                        <h2>+1 123-456-7890</h2>
                    </div>
                </div>
            </div>
            {/* <!-- cta wrapper End -->  */}
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SipTrucking));
