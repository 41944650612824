import React, { useEffect, useState } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import './TopBarNew.css';

const mapStateToProps = (state) => {
    return {
        SipCart: state.sipTrunkings.SipCart,
        priceCart: state.pricing.priceCart ? state.pricing.priceCart : [],
        hardwareCart: state.hardwares,
        mst: state.mstResData.mstCart
    }
}



const TopBarNew = (props) => {
const history = useHistory()



    let tok = sessionStorage.getItem('access_Token')
    const [token, setToken] = useState(sessionStorage.getItem('access_Token'))


    const cartItem = props.hardwareCart.HardwareCart.length + props.priceCart.length + props.SipCart.length + props.mst.length


    function logoutHandler() {
        sessionStorage.removeItem('access_Token')
        sessionStorage.removeItem('register_user')
        localStorage.removeItem('userInfo')
        setToken(null)
        window.location.replace('/')
    }

    function checkQuickPay(){
        history.push({
                pathname: "/quickpay",
            });
    }

    



    return (
        <div className="scs-top-bar" >
            <div className="container-fluid" >
                <div className="row" style={{ justifyContent: "space-between" }}>
                    <div className="col-lg-4">
                        <div className="scs-top-bar-contact-side" >
                            <div className="top-bar-contact-num" >
                                <Link className="scs-top-bar-phone">844-727-8353<i className="fa fa-phone"></i></Link>
                            </div>
                            {/* <div className="top-bar-live-chat" >
                                <Link to="/404" className="scs-top-bar-chat-link" ><i className="fa fa-commenting-o"></i> Live Chat</Link>
                            </div> */}
                            {/* <div className="top-bar-language" >
                                <DropdownButton id="top-bar" title="Language" >
                                    <Dropdown.Item href="#/action-1">Language 1</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Language 2</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Language 3</Dropdown.Item>
                                </DropdownButton>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-8" >
                        <div className="scs-top-bar-auth-side" >
                          
                            <div className="top-bar-cart" >
                                <Link to="/cart" className="scs-top-bar-charitem" ><i aria-hidden="true" className="fa fa-shopping-basket"></i> View Cart

                                   {" "} <span class="badge badge-warning"> {cartItem} </span>

                                </Link>
                            </div>
                            <div className="top-bar-contact-num" >
                                <Link to="/contactus" className="scs-top-bar-charitem" ><i class="fa fa-address-card-o" aria-hidden="true"></i>&nbsp; Get A Quote</Link>
                            </div>
                            <div className="top-bar-login" >
                                {token ?
                                    <div className="top-bar-sign" >
                                        <Link onClick={checkQuickPay} className="scs-top-bar-login-link" ><i class="fa fa-credit-card" aria-hidden="true"></i> Quick Pay</Link>
                                        <Link to="/" className="scs-top-bar-login-link" onClick={logoutHandler} ><i className="fa fa-user"></i> Sign out</Link>
                                        <Link to="/profile" className="scs-top-bar-login-link"  ><i className="fa fa-user"></i> Profile</Link>
                                    </div>
                                    :
                                    <div className="top-bar-sign" >
                                        <Link onClick={checkQuickPay} className="scs-top-bar-login-link" ><i class="fa fa-credit-card" aria-hidden="true"></i> Quick Pay</Link>
                                        <Link to="/signup" className="scs-top-bar-signup-link" ><i className="fa fa-user-plus"></i> Sign Up</Link>
                                        <Link to="/authui" className="scs-top-bar-login-link"  ><i className="fa fa-user"></i> Sign in</Link>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(TopBarNew)
