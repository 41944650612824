import React, { useEffect } from 'react'
import './App.css'
import Main from './layout/Main'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles


function App () {
  useEffect(() => {
    // AOS.init({ duration: 2000 })
    AOS.init({disable: 'mobile' , duration : 1000});
  }, [])

  return (
    <div>
      <Main/>
    </div>
  )
}

export default App
