import React from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.css';
import { Modal, Button } from 'react-bootstrap';
// import { MDBContainer, MDBAlert } from 'mdbreact';
// import ImageUploader from 'react-images-upload';
import { useState, useEffect } from 'react';
import AuthService from '../../../Services/AuthService';
import Validate from '../../validation/Validate';
import Swal from 'sweetalert2';

const authService = new AuthService();
const SignUp = (props) => {
  const unSuccefullAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong! Please Check Your connection',
    });
  };

  const [phone, setPhone] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [country, setCountry] = useState('USA');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState(null);
  const [fax, setFax] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyAddressSecond, setCompanyAddressSecond] = useState('');
  const [countryCompany, setCountryCompany] = useState('USA');
  const [cityCompany, setCityCompany] = useState('');
  const [noEmployee, setNoEmployee] = useState('');
  const [stateCompany, setStateCompany] = useState('');
  const [zipCompany, setZipCompany] = useState('');
  const [terms, setTerms] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [isCompany, setIsCompany] = useState(false);
  const [isSucceed, setisSucceed] = useState(false);
  const [errorToShow, setErrorToShow] = useState('');
  const [isSubmited, setIsSubmited] = useState(false);
  const [error, setError] = useState([]);

  const signupValidation = (e) => {
    setError([]);
    setErrorToShow(null);
    e.preventDefault();
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'smooth',
    });
    var role = isCompany ? 'Admin' : 'user';
    console.log(userPassword.length);
    if (firstName == '') {
      setError((prev) => [...prev, 'First name is required']);
    }
    if (lastName == '') {
      setError((prev) => [...prev, 'Last name is required']);
    }
    if (userEmail == '') {
      setError((prev) => [...prev, 'Email is required']);
    }
    if (userPassword == '') {
      setError((prev) => [...prev, 'Password is required']);
    } else if (userPassword.length < 6) {
      setError((prev) => [...prev, 'Password minimum should be 6 character']);
    }
    if (phone == null) {
      setError((prev) => [...prev, 'Phone is required']);
    }
    if (terms == false) {
      setError((prev) => [...prev, 'Terms and condition should be mandatory']);
    }


    if (city == '') {
      setError((prev) => [...prev, 'City name is required']);
    }
    if (address == '') {
      setError((prev) => [...prev, 'Address field is required']);
    }
    if (state == '') {
      setError((prev) => [...prev, 'State name is required']);
    }

    if (zip == '') {
      setError((prev) => [...prev, 'Zip Code is required']);
    }

    if (role == 'Admin') {
      if (companyName == '') {
        setError((prev) => [...prev, 'Company is required']);
      }
      if (companyAddress == '') {
        setError((prev) => [...prev, 'Company Address is required']);
      }

      if (cityCompany == '') {
        setError((prev) => [...prev, 'City Of the Company is required']);
      }

      if (stateCompany == '') {
        setError((prev) => [...prev, 'Company State is required']);
      }
      if (zipCompany == '') {
        setError((prev) => [...prev, 'Company Zip Code is required']);
      }
      if (noEmployee == '') {
        setError((prev) => [...prev, 'Number of Employees is required']);
      }
    }

    if (error.length === 0) {
      submitAction(e);
    }
  };
  const submitAction = (e) => {
    setIsSubmited(true);

    e.preventDefault();
    var role = isCompany ? 'Admin' : 'user';
    let dataToSend = {
      userInfo: {
        first_Name: firstName,
        last_Name: lastName,
        email: userEmail,
        password: userPassword,
        role: isCompany ? 'Admin' : 'user',
        phone: phone,
        mobile: mobile,
        country: 'USA',
        city: city,
        address: address,
        state: state,
        fax: fax,
        zip_Code: zip,
      },
      companyInfo: isCompany
        ? {
          company_Name: companyName,
          address: companyAddress + companyAddressSecond,
          country: 'USA',
          state: stateCompany,
          city: cityCompany,
          zip_Code: zipCompany,
          employees: noEmployee,
        }
        : {},
    };
    let formData = new FormData();
    formData.append('first_Name', firstName);
    formData.append('last_Name', lastName);
    formData.append('email', userEmail);
    formData.append('password', userPassword);
    formData.append('role', role);
    formData.append('phone', phone);
    formData.append('mobile', mobile ? mobile : 0);
    formData.append('country', 'USA');
    formData.append('city', city);
    formData.append('address', address);
    formData.append('state', state);
    formData.append('fax', fax);
    formData.append('zip_Code', zip);
    formData.append('userImage', profileImage);
    if (role === 'Admin') {
      formData.append('company_Name', companyName);
      formData.append('conmpanyAddress', companyAddress + companyAddressSecond);
      formData.append('companyCountry', 'USA');
      formData.append('companyState', stateCompany);
      formData.append('companyCity', cityCompany);
      formData.append('companyZip_Code', zipCompany);
      formData.append('employees', noEmployee);
    }

    authService
      .signupRequest(formData)
      .then((res) => {
        setIsSubmited(false);
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth',
        });

        if (res.data.emailSend === true) {
          setisSucceed(true);
          setFirstName('');
          setLastName('');
          setUserEmail('');
          setUserPassword('');
          setPhone('');
          setMobile('');
          setCity('');
          // setCountry('');
          setZip('');
          setState('');
          setFax('');
          setCompanyName('');
          setCompanyAddressSecond('');
          setNoEmployee('');
          setZipCompany('');
          setCityCompany('');
          setStateCompany('');
          // setCountryCompany('');
          setCompanyAddress('');
        } else {
          // let error = ErrorHandler(res.data)
          // setErrorToShow(error)
          setisSucceed(false);
          if (res.data === '"userImage" is not allowed') {
            setErrorToShow('Please select profile image');
          } else if (res.data === '"company_Name" is not allowed to be empty') {
            setErrorToShow('Company name is required');
          } else if (res.data === '"first_Name" is not allowed to be empty') {
            setErrorToShow('First name is required');
          } else if (res.data === '"last_Name" is not allowed to be empty') {
            setErrorToShow('Last name is required');
          } else if (res.data === '"email" is not allowed to be empty') {
            setErrorToShow('Email is required');
          } else if (res.data === '"password" is not allowed to be empty') {
            setErrorToShow('Password is required');
          } else if (res.data === '"mobile" must be a number') {
            setErrorToShow('Enter a valid mobile number');
          } else if (res.data === '"phone" must be a number') {
            setErrorToShow('Enter a valid phone number');
          } else if (res.data === '"country" is not allowed to be empty') {
            setErrorToShow('Country is required');
          } else if (res.data === '"country" is not allowed to be empty') {
            setErrorToShow('Country name is required');
          } else if (res.data === '"city" is not allowed to be empty') {
            setErrorToShow('City name is required');
          } else if (res.data === '"address" is not allowed to be empty') {
            setErrorToShow('Address field is required');
          } else if (res.data === '"state" is not allowed to be empty') {
            setErrorToShow('Enter you State or Province');
          } else if (res.data === '"fax" is not allowed to be empty') {
            setErrorToShow('Fax field is required');
          } else if (res.data === false) {
            setErrorToShow(
              'Something went wrong, please re-write your form and submit again.'
            );
          } else if (res.data === '') {
            setErrorToShow('Zip/Postal code is required');
          } else if (
            res.data === '"conmpanyAddress" is not allowed to be empty'
          ) {
            setErrorToShow('Please fill company address field');
          } else if (
            res.data === '"companyCountry" is not allowed to be empty'
          ) {
            setErrorToShow('Please choose your country');
          } else if (
            res.data === '"companyZip_Code" is not allowed to be empty'
          ) {
            setErrorToShow('Please add Zip code');
          } else if (res.data === '"employees" must be a number') {
            setErrorToShow('Please tell us your number of employees in number');
          } else if (res.data === 'User already exist') {
            setErrorToShow('User already exist with this email');
          } else {
            setErrorToShow(res.data);
          }
        }
      })
      .catch((err) => {
        setIsSubmited(false);
        setisSucceed(false);
        unSuccefullAlert();
      });
  };

  return (
    <div>
      {/* <!-- Breadcrumb Wrapper Start --> */}
      <div className='breadcrumb-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-7'>
              <h2>Sign Up</h2>
            </div>

            <div className='col-sm-5'>
              <div className='inner-breadcrumb'>
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>Sign Up</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Breadcrumb Wrapper End -->  */}
      {/* <!-- Inner Wrapper Start --> */}

      <div className='inner-page-wrapper login-wrapper'>
        <div className='container'>
          {isSucceed ? (
            <div class='alert alert-success' role='alert' id='success-alert'>
              Request successfully done!<br></br>
              We are sending you email to your provided email address, <br></br>
              <strong>Please verify your email</strong>
              <Link
                style={{ marginLeft: '20px' }}
                className='scs-button'
                to='/authui'
              >
                log in
              </Link>
            </div>
          ) : error.length > 0 ? (
            <div>
              {error.map((item) => (
                <div class='alert alert-danger' role='alert' id='success-alert'>
                  <p className='alert-errorMessage'> {item}</p>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}

          <div style={{ justifyContent: 'center' }}>
            <div className='col'>
              <div className='heading-below-line'>
                <h3>PERSONAL INFORMATION</h3>
              </div>

              <form onSubmit={signupValidation}>
                <small className='red'>
                  Fields marked with the (*) are required
                </small>
                <div
                  className='row'
                  style={{ justifyContent: 'center', marginTop: '20px' }}
                >
                  <div className='col-sm-6 '>
                    <input
                      placeholder='First name*'
                      value={firstName}
                      name='firstName'
                      type='text'
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className='col-sm-6 '>
                    <input
                      placeholder='Last name*'
                      value={lastName}
                      name='lastName'
                      type='text'
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div className='col-sm-6 '>
                    <input
                      placeholder='Email*'
                      value={userEmail}
                      name='userEmail'
                      type='email'
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </div>
                  <div className='col-sm-6 '>
                    <input
                      placeholder='Password*'
                      value={userPassword}
                      name='userPassword'
                      type='password'
                      onChange={(e) => setUserPassword(e.target.value)}
                    />
                  </div>

                  <div className='col-sm-6 '>
                    <label>
                      Phone<span className='red'>*</span>
                    </label>
                    <PhoneInput
                      country={'us'}
                      onlyCountries={['us']}
                      disableCountryCode={true}
                      value={phone}
                      placeholder='(702) 123-4567'
                      onChange={(e) => {
                        setPhone(e);
                      }}
                    />
                  </div>
                  <div className='col-sm-6 '>
                    <label>Mobile </label>
                    <PhoneInput
                      country={'us'}
                      onlyCountries={['us']}
                      disableCountryCode={true}
                      value={mobile}
                      placeholder='(702) 123-4567'
                      onChange={(e) => {
                        setMobile(e);
                      }}
                    />
                  </div>
                  {/* <div className='col-sm-6 ' style={{ marginTop: '18px' }}>
                    <input
                      placeholder='Country*'
                      value={country}
                      name='country'
                      type='text'
                      onChange={(e) => setCountry(e.target.value)}
                    />  col-sm-12 col-xs-12 col-lg-12
                  </div> */}
                  <div className='col-sm-6 ' style={{ marginTop: '18px' }}>
                    <input
                      placeholder='City*'
                      value={city}
                      name='city'
                      type='text'
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className='col-sm-6 ' style={{ marginTop: '18px' }}>
                    <input
                      placeholder='Address*'
                      value={address}
                      name='address'
                      type='text'
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className='col-sm-6 '>
                    <input
                      placeholder='State/Province*'
                      value={state}
                      name='state'
                      type='text'
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>
                  <div className='col-sm-6 '>
                    <input
                      step='10'
                      min='0'
                      max='100'
                      placeholder='Fax'
                      value={fax}
                      name='fax'
                      type='text'
                      onChange={(e) => setFax(e.target.value)}
                    />
                  </div>
                  <div className='col-sm-6 '>
                    <input
                      placeholder='Postal/Zip code*'
                      value={zip}
                      name='zip'
                      type='text'
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                  <div
                    class='col-sm-6'
                    style={{ float: 'left' }}
                  >
                    <input
                      placeholder=''
                      name='profileImage'
                      type='file'
                      onChange={(e) => setProfileImage(e.target.files[0])}
                    />
                  </div>
                  <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                    <div className='row' className='acc__type'>
                      <div
                        className='col-sm-12 col-xs-12 col-lg-12 col-md-12'
                        style={{ padding: 0 }}
                      >
                        <div className='row'>
                          <div className='col-sm-12 col-xs-12 col-lg-6 col-md-12'>
                            <h4 style={{ fontSize: '1rem' }}>
                              Please select your account type:
                            </h4>
                          </div>

                          <div className='col-sm-12 col-xs-12 col-lg-6 col-md-12'>
                            <div className='row'>
                              <div className='col-sm-12 col-xs-12 col-lg-3 col-md-6'>
                                <label className='radio__container'>
                                  Individual
                                  <input
                                    type='radio'
                                    checked={!isCompany ? true : false}
                                    name='isCompany'
                                    onClick={() => {
                                      setIsCompany(false);
                                    }}
                                  ></input>
                                  <span className='checkmark'></span>
                                </label>
                              </div>
                              <div
                                className='col-sm-12 col-xs-12 col-lg-3 col-md-6'
                                className='company__div'
                              >
                                <label className='radio__container__second'>
                                  Company
                                  <input
                                    type='radio'
                                    checked={isCompany ? true : false}
                                    name='isCompany'
                                    onClick={() => {
                                      setIsCompany(true);
                                    }}
                                  ></input>
                                  <span className='checkmark'></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row' style={{ marginTop: '20px' }}>
                      <div className='col-sm-6 col-xs-12 col-lg-6 col-md-6'>
                        <input
                          style={{
                            width: '0px',
                            border: 'none',
                            lineHeight: '25px',
                            padding: '0px',
                          }}
                          placeholder=''
                          name='profileImage'
                          type='file'
                          onChange={(e) => setProfileImage(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {isCompany ? (
                  <div className='bottom_Div'>
                    <div className='signup'>
                      <div className='heading-below-line'>
                        <h3>COMPANY INFORMATION</h3>
                      </div>
                      <div
                        className='row'
                        style={{ justifyContent: 'center', marginTop: '20px' }}
                      >
                        <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                          <input
                            placeholder='Company name'
                            value={companyName}
                            name='companyName'
                            type='text'
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                          <input
                            placeholder='Address'
                            value={companyAddress}
                            name='companyAddress'
                            type='text'
                            onChange={(e) => setCompanyAddress(e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                          <input
                            placeholder='Second Address'
                            value={companyAddressSecond}
                            name='companyAddressSecond'
                            type='text'
                            onChange={(e) =>
                              setCompanyAddressSecond(e.target.value)
                            }
                          />
                        </div>
                        {/* <div className='col-sm-6'>
                          <input
                            placeholder='Country'
                            value={countryCompany}
                            name='countryCompany'
                            type='text'
                            onChange={(e) => setCountryCompany(e.target.value)}
                          />
                        </div> */}
                        <div className='col-sm-6'>
                          <input
                            placeholder='State/Province'
                            value={stateCompany}
                            name='stateCompany'
                            type='text'
                            onChange={(e) => setStateCompany(e.target.value)}
                          />
                        </div>
                        <div className='col-sm-6'>
                          <input
                            placeholder='City'
                            value={cityCompany}
                            name='cityCompany'
                            type='text'
                            onChange={(e) => setCityCompany(e.target.value)}
                          />
                        </div>

                        <div className='col-sm-6'>
                          <input
                            placeholder='Postal/Zip code'
                            value={zipCompany}
                            name='zipCompany'
                            type='text'
                            onChange={(e) => setZipCompany(e.target.value)}
                          />
                        </div>
                        <div className='col-sm-6'>
                          <input
                            placeholder='No of employees'
                            value={noEmployee}
                            name='noEmployee'
                            type='number'
                            onChange={(e) => setNoEmployee(e.target.value)}
                          />
                        </div>
                        <div className='col-sm-6' style={{ marginTop: '18px' }}>
                          {/* <input  placeholder="State/Province" value="" name="state" type="text" />
                                            <input  placeholder="Postal/Zip code" name="zip" type="text" /> */}
                        </div>
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className='forget-password'>
                  <div className='pull-left' style={{ marginTop: '10px' }}>
                    <p><small className='red'>
                      *You must agree to our terms and conditions
                    </small></p>
                    <input
                      id='test2'
                      type='checkbox'
                      style={{ height: '15px', width: '15px' }}
                      onClick={() => {
                        setTerms(!terms);
                      }}
                    />
                    <label
                      for='test2'
                      style={{ marginTop: '5px', fontSize: '1rem' }}
                    >
                      <a href='/terms&uses'>Terms and Conditions apply</a>
                    </label>
                  </div>
                </div>
                {isSubmited ? (
                  <div className='Progress_indicator'>
                    <div
                      className='spinner-border'
                      style={{ color: '#7ebc12' }}
                      role='status'
                    >
                      <span className='visually-hidden'></span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <button
                  onClick-={signupValidation}
                  className='btn'
                  disabled={isSubmited}
                >
                  Register
                </button>
              </form>
            </div>
          </div>

          {/* COMPANY INFORMATION  */}
        </div>
      </div>

      {/* <!-- Inner Wrapper End -->  */}
    </div>
  );
};

export default SignUp;
