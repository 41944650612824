import React, { useState, useEffect } from 'react';
import userImg from '../../../scs-img/testimonials3.png';
import './CustomerProfile.css';
import { IMAGE_URL } from '../../../Config';
import { Tabs, Tab } from 'react-bootstrap';
import { API_BASE } from '../../../Config';
import CustomerOrder from './CustomerOrder/CustomerOrder';
import { Link } from 'react-router-dom';
import AuthService from '../../../Services/AuthService';
import Swal from 'sweetalert2';
const authService = new AuthService();

const Customerprofile = () => {
  const getUserInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [userDetail, setuserDetail] = useState(getUserInfo);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [image, setImage] = useState({ photoURL: userDetail?.photoURL });
  console.log(image);
  const [isSucceed, setisSucceed] = useState(false);
  const unSuccefullAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong! Please Check Your connection',
    });
  };
  const successfullAlert = () => {
    Swal.fire({
      icon: 'success',
      title: 'Updated',
      text: 'Your Profile Is Successfully Updated',
    });
  };

  const photo = String(userDetail?.photoURL);
  const cutImage = photo.replace('uploads', '');
  const actualImage = IMAGE_URL + cutImage;

  const clickHandler = (e) => {
    console.log(userDetail);
    e.preventDefault();
    if (updateProfile) {
      setUpdateProfile(false);
      const formData = new FormData();
      formData.append('first_Name', userDetail?.first_Name);
      formData.append('last_Name', userDetail?.last_Name);
      formData.append('email', userDetail?.email);
      formData.append('country', userDetail?.country);
      formData.append('city', userDetail?.city);
      formData.append('address', userDetail?.address);
      formData.append('state', userDetail?.state);
      formData.append('fax', userDetail?.fax);
      formData.append('zip_Code', userDetail?.zip_Code);
      formData.append('photoURL', image?.photoURL);
      authService.updateProfile(formData).then((res) => {
        setisSucceed(false);
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth',
        });
        if (res.data.status === 'done') {
          console.log(res.data);
          setisSucceed(true);
          setuserDetail((prev) => {
            prev['photoURL'] = res.data.path;
            return { ...prev };
          });
          localStorage.setItem('userInfo', JSON.stringify(userDetail));

          successfullAlert();
          console.log(userDetail.photoURL);
        } else {
          setisSucceed(false);
          unSuccefullAlert();
        }
      });
    } else {
      setUpdateProfile(true);
    }
  };

  if (getUserInfo) {
    return (
      <div className='container'>
        <div className='profile-main'>
          <div className='row'>
            <div className='col-lg-1'>
              <div className='profile-header'>
                {/* This is the profile photo section if its needed just un comment it */}
                {/* <div className="profile-img" >
                                    <img className="img-responsive" src={ userDetail.photoURL ? actualImage: userImg } alt="Profile Pic" id='profile' />
                                </div>
                                <div className="profile-user-detail" >
                                { updateProfile ?<div> <label class="form-label" for="customFile">Select Profile Photo</label>
<input type="file" class="form-control" id="customFile" onChange={(e) => { setImage((prev) => {
    prev[`photoURL`] = e.target.files[0];
    console.log(prev['photoURL']);
    return {
        ...prev
    }
}) }} />
</div> : " " } */}
                {/* <input value = {userDetail.first_Name} width="5px" onChange= { (e) => {
                                                             
                                                            if(updateProfile){
                                                                setuserDetail((prev) => {
                                                                    
                                                                    return {
                                                                        ...prev,
                                                                        first_Name: e.target.value
                                                                    };
                                                            })
                                                        }
                                                        }
                                                            
                                                         } />
                                    <input value= {userDetail.last_Name} 
                                        onChange= { (e) => {
                                                             
                                                            if(updateProfile){
                                                                setuserDetail((prev) => {
                                                                    
                                                                    return {
                                                                        ...prev,
                                                                        last_Name: e.target.value
                                                                    };
                                                            })
                                                        }
                                                        }
                                                            
                                                         }
                                    />
                                    <p> {userDetail.role} </p> */}
                {/* </div> */}
              </div>
            </div>
            <div className='col-lg-9'>
              <div className='profile-detail-section'>
                <div className='contact-informaton'>
                  <Tabs
                    className='profile-tabs'
                    defaultActiveKey='home'
                    transition={false}
                    id='noanim-tab-example'
                  >
                    <Tab
                      className='profile-tab'
                      eventKey='home'
                      title='Profile'
                    >
                      <form>
                        <div className='personal-detail'>
                          <div class='heading-below-line'>
                            <h3>Personal Information</h3>
                          </div>
                          <div className='personal-detail-main-info'>
                            <div className='personal-detail-info'>
                              <h6>First Name</h6>
                              <input
                                value={userDetail?.first_Name}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        first_Name: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className='personal-detail-info'>
                              <h6>Last Name</h6>
                              <input
                                value={userDetail?.last_Name}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        last_Name: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className='personal-detail-info'>
                              <h6>Email</h6>
                              <input value={userDetail?.email} />
                            </div>
                            <div className='personal-detail-info'>
                              <h6>Country</h6>
                              <input
                                value={userDetail?.country}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        country: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className='personal-detail-main-info'>
                            <div className='personal-detail-info'>
                              <h6>City</h6>
                              <input
                                value={userDetail?.city}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        city: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className='personal-detail-info'>
                              <h6>Address</h6>
                              <input
                                value={userDetail?.address}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        address: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className='personal-detail-main-info'>
                            <div className='personal-detail-info'>
                              <h6>State/Provision</h6>
                              <input
                                value={userDetail?.state}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        state: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className='personal-detail-info'>
                              <h6>Fax</h6>
                              <input
                                value={userDetail?.fax}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        fax: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className='personal-detail-main-info'>
                            <div className='personal-detail-info'>
                              <h6>Zip Code</h6>
                              <input
                                value={userDetail?.zip_Code}
                                onChange={(e) => {
                                  if (updateProfile) {
                                    setuserDetail((prev) => {
                                      return {
                                        ...prev,
                                        zip_Code: e.target.value,
                                      };
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className='personal-detail-main-info'>
                            <div className='personal-detail-info'>
                              <Link
                                to='/changepassword'
                                className='btn btn-danger'
                              >
                                Change Password
                              </Link>
                              &nbsp;
                              <Link
                                className='btn btn-danger'
                                type='submit'
                                onClick={clickHandler}
                              >
                                {updateProfile ? 'Done' : 'Update Profile'}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Tab>
                    <Tab
                      className='profile-tab'
                      eventKey='profile'
                      title='Orders'
                    >
                      <CustomerOrder />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p>You are not log in</p>
      </div>
    );
  }
};

export default Customerprofile;
