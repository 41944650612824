
export const setChatToInitial = (data) => {
    return (dispatch) => {
        dispatch({ type: 'SET_INITIAL_STATE', payload: data })
    }
}

export const guestLoginTrue = (data) => {
    console.log('guest login true action')
    return (dispatch) => {
        dispatch({ type: 'GUEST_LOGIN_TRUE', payload: data })
    }
}

export const guestLoginFalse = (data) => {
    return (dispatch) => {
        dispatch({ type: 'GUEST_LOGIN_FALSE', payload: data })
    }
}