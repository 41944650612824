import React, { useState, useEffect } from 'react';
import placeholder from '../../../scs-img/placeholder.svg';
import envelope from '../../../scs-img/envelope.svg';
import phone from '../../../scs-img/phone-call.svg';
import facebook from '../../../scs-img/facebook.svg';
import linkdin from '../../../scs-img/linkedin.svg';
import pinterest from '../../../scs-img/pinterest.svg';
import twitter from '../../../scs-img/twitter.svg';
import { Link, useParams, Redirect } from 'react-router-dom';
import './contactus.css';
import TransactionService from '../../../Services/TransactionService';
import Swal from 'sweetalert2';

const queryMsg = new TransactionService();
const ContectUs = (props) => {
  const [errorMsg, seterrorMsg] = useState('');
  const [isSubmitted, setisSubmitted] = useState(false);
  const [isSuccess, setisSuccess] = useState(true);
  const [contactUsInput, setContactUsInput] = useState({
    name: '',
    email: '',
    phone: '',
    installationAddress: '',
    compnayName: '',
  });

  useEffect(() => {
    window.scroll(0, 0);

    if (props.location.state && props.location.state.emaildata) {
      setContactUsInput({
        ...contactUsInput,
        email: props.location.state.emaildata,
      });
    } else if (
      props.location.state &&
      props.location.state.query.email &&
      props.location.state.query.message
    ) {
      setContactUsInput({
        ...contactUsInput,
        email: props.location.state.query.email,
        message: props.location.state.query.message,
      });
    }
  }, []);
  const contactInputHandler = (e) => {
    setContactUsInput({
      ...contactUsInput,
      [e.target.name]: e.target.value,
    });
  };

  const successfulAlert = () => {
    Swal.fire({
      icon: 'success',
      title: 'Your request has been submitted',
      showConfirmButton: true,
    });
  };
  const onValueChange = (e, value) => {
    setContactUsInput({
      ...contactUsInput,
      [e.target.name]: value,
    });
    console.log(contactUsInput);
  };
  const unSuccefullAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong! Check your provided information again',
    });
  };
  const getDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    return (today = yyyy + '-' + mm + '-' + dd);
  };

  const contactUsSubmitHandler = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'smooth',
    });
    if (contactUsInput.name === '') {
      seterrorMsg('Name is required');
      setisSuccess(false);
    } else if (!/\S+@\S+\.\S+/.test(contactUsInput.email)) {
      seterrorMsg('Valid email is required');
      setisSuccess(false);
    } else if (!/^(0|[1-9][0-9]*)$/.test(contactUsInput.phone)) {
      seterrorMsg('Phone number should be correct');
      setisSuccess(false);
    } else if (contactUsInput.installationAddress === '') {
      seterrorMsg('Installation Address is required');
      setisSuccess(false);
    } else if (contactUsInput.compnayName === '') {
      seterrorMsg('Name of the company is required');
      setisSuccess(false);
    } else {
      queryMsg
        .sendQuery(contactUsInput)
        .then((res) => {
          console.log(res);
          setisSubmitted(true);
          setisSuccess(true);
          successfulAlert();

          setContactUsInput({
            name: '',
            email: '',
            phone: '',
            compnayName: '',
            installationAddress: '',
          });
        })
        .catch((err) => {
          console.log(err);
          setisSuccess(true);
          setisSubmitted(false);
          unSuccefullAlert();
        });
    }
  };
  return (
    <div>
      {/* <!-- Breadcrumb Wrapper Start --> */}
      <div className='breadcrumb-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <h2>Contact Us</h2>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='inner-breadcrumb'>
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Breadcrumb Wrapper End -->  */}
      {/* <!-- Inner Wrapper Start --> */}
      <section className='main-contact-us'>
        <div className='container'>
          {isSuccess ? (
            ''
          ) : (
            <div class='alert alert-danger' role='alert'>
              <p className='alert-errorMessage'>{errorMsg}</p>
            </div>
          )}
          <div className='row  flex-column-reverse flex-lg-row'>
            <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12'>
              <div data-aos='fade-right' className='contact-detail'>
                <div className='contact-detail-heading'>
                  <h1>Get Our Contacts From Here</h1>
                </div>
                <div className='contact-detail-address'>
                  <div className='contact-detail-address-1'>
                    <div className='contact-icon-side'>
                      <span className='address-1-img'>
                        <img
                          className='img-fluid'
                          src={placeholder}
                          alt='placeholder-icon'
                        />
                      </span>
                    </div>
                    <div className='address-side'>
                      <div className='address-1-placeholder-heading'>
                        <h2>OUR LOCATION​​ </h2>
                        <p>
                          &nbsp;P.O. Box 751901 <br /> &nbsp;Houston, Texas
                          77275{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='contact-detail-address-1'>
                    <div className='contact-icon-side'>
                      <span className='address-1-img'>
                        <img
                          className='img-fluid'
                          src={phone}
                          alt='phone-icon'
                        />
                      </span>
                    </div>
                    <div className='address-side'>
                      <div className='address-1-placeholder-heading'>
                        <h2>PHONE NUMBER </h2>
                        <p>844-727-8353</p>
                      </div>
                    </div>
                  </div>
                  <div className='contact-detail-address-1'>
                    <div className='contact-icon-side'>
                      <span className='address-1-img'>
                        <img
                          className='img-fluid'
                          src={envelope}
                          alt='envelop-icon'
                        />
                      </span>
                    </div>
                    <div className='address-side'>
                      <div className='address-1-placeholder-heading'>
                        <h2>EMAIL ADDRESS </h2>
                        <p>info@scstelcom.com </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="contact-detail-icons" >
                                    <a href="https://www.facebook.com/scstelcom1" >
                                        <span className="icon" >

                                            <img className="img-fluid" src={facebook} alt="facebook-icon" />
                                        </span>
                                    </a>
                                </div> */}
              </div>
            </div>
            <div className='col-lg-8 contact-form-background'>
              <div data-aos='zoom-in' className='contact-form-main'>
                <div className='contact-form-heading'>
                  <div class='heading-below-line'>
                    <h3>CONTACT US</h3>
                  </div>
                  <h1>Get In Touch With Us</h1>
                </div>
                <div className='contact-form-inputs'>
                  <div className='radio_flex my-3'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='request'
                          checked={
                            contactUsInput?.request ===
                            'Fiber Internet Quote Request'
                          }
                          onChange={(e) => {
                            onValueChange(e, 'Fiber Internet Quote Request');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Fiber Internet Quote Request</span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='request'
                          checked={
                            contactUsInput?.request ===
                            'VoIP Phone System Quote Request'
                          }
                          onChange={(e) => {
                            onValueChange(e, 'VoIP Phone System Quote Request');
                          }}
                          className=''
                          type='radio'
                          placeholder='Email'
                        />
                        <span> VoIP Phone System Quote Request</span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='request'
                          checked={contactUsInput?.request === 'Both'}
                          onChange={(e) => {
                            onValueChange(e, 'Both');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Both</span>
                      </div>
                    </div>
                  </div>
                  <div className='radio_flex my-3'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='location'
                          checked={contactUsInput?.location === 'Single'}
                          onChange={(e) => {
                            onValueChange(e, 'Single');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Single </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='location'
                          checked={contactUsInput?.location === 'Multiple'}
                          onChange={(e) => {
                            onValueChange(e, 'Multiple');
                          }}
                          className=''
                          type='radio'
                          placeholder='Multiple'
                        />
                        <span> Multiple </span>
                      </div>
                    </div>
                  </div>
                  <small className='red'>
                    Fields marked with (*) are required
                  </small>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                      <div className='input-div'>
                        <input
                          name='name'
                          value={contactUsInput.name}
                          onChange={contactInputHandler}
                          className='contact-us-input'
                          type='text'
                          placeholder='Name*'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                      <div className='input-div'>
                        <input
                          name='email'
                          value={contactUsInput.email}
                          onChange={contactInputHandler}
                          className='contact-us-input'
                          type='text'
                          placeholder='Email*'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                      <div className='input-div'>
                        <input
                          name='phone'
                          value={contactUsInput.phone}
                          onChange={contactInputHandler}
                          className='contact-us-input'
                          type='text'
                          placeholder='Phone*'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                      <div className='input-div'>
                        <input
                          name='compnayName'
                          value={contactUsInput.compnayName}
                          onChange={contactInputHandler}
                          className='contact-us-input'
                          type='text'
                          placeholder='Company Name*'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <div className='input-div'>
                        <input
                          name='installationAddress'
                          value={contactUsInput.installationAddress}
                          onChange={contactInputHandler}
                          className='contact-us-input'
                          type='text'
                          placeholder='Installation Address*'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='contact-form-heading'>
                    <h5>Internet Speed</h5>
                  </div>
                  <div className='radio_flex'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='speed'
                          checked={contactUsInput?.speed === '100'}
                          onChange={(e) => {
                            onValueChange(e, '100');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 100 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='speed'
                          checked={contactUsInput?.speed === '200'}
                          onChange={(e) => {
                            onValueChange(e, '200');
                          }}
                          className=''
                          type='radio'
                          placeholder='Email'
                        />
                        <span> 200 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='speed'
                          checked={contactUsInput?.speed === '400'}
                          onChange={(e) => {
                            onValueChange(e, '400');
                          }}
                          className=''
                          type='radio'
                          placeholder='Email'
                        />
                        <span> 400 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='speed'
                          checked={contactUsInput?.speed === '500'}
                          onChange={(e) => {
                            onValueChange(e, '500');
                          }}
                          className=''
                          type='radio'
                          placeholder='Email'
                        />
                        <span> 500 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='speed'
                          checked={contactUsInput?.speed === '1G'}
                          onChange={(e) => {
                            onValueChange(e, '1G');
                          }}
                          className=''
                          type='radio'
                          placeholder='Email'
                        />
                        <span> 1G </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='speed'
                          checked={contactUsInput?.speed === 'Other'}
                          onChange={(e) => {
                            onValueChange(e, 'Other');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Other </span>
                      </div>
                    </div>
                  </div>
                  <div className='radio_flex my-4 heading-margin'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='type'
                          checked={contactUsInput?.type === 'Primary Internet'}
                          onChange={(e) => {
                            onValueChange(e, 'Primary Internet');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Primary Internet </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='type'
                          checked={contactUsInput?.type === 'Backup Internet'}
                          onChange={(e) => {
                            onValueChange(e, 'Backup Internet');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Backup Internet </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='type'
                          checked={contactUsInput?.type === 'COAX Backup'}
                          onChange={(e) => {
                            onValueChange(e, 'COAX Backup');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> COAX Backup </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='type'
                          checked={contactUsInput?.type === 'Fiber Backup'}
                          onChange={(e) => {
                            onValueChange(e, 'Fiber Backup');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Fiber Backup </span>
                      </div>
                    </div>
                  </div>
                  <div className='radio_flex my-4'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='contract'
                          checked={
                            contactUsInput?.contract ===
                            'Replacing Existing internet'
                          }
                          onChange={(e) => {
                            onValueChange(e, 'Replacing Existing internet');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Replacing Existing internet </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='contract'
                          checked={
                            contactUsInput?.contract === 'Existing Fiber'
                          }
                          onChange={(e) => {
                            onValueChange(e, 'Existing Fiber');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Existing Fiber </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='contract'
                          checked={contactUsInput?.contract === 'Existing COAX'}
                          onChange={(e) => {
                            onValueChange(e, 'Existing COAX');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Existing COAX </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='contract'
                          checked={
                            contactUsInput?.contract === 'Under Contract'
                          }
                          onChange={(e) => {
                            onValueChange(e, 'Under Contract');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> Under Contract </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <span> Contract Expiration Date: </span>
                        <input
                          name='contractExpiration'
                          style={{
                            border: 'none',
                            borderBottom: '1px solid green',
                          }}
                          onChange={contactInputHandler}
                          min={getDate()}
                          onKeyDown={(e) => e.preventDefault()}
                          type='date'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='contact-form-heading heading-margin'>
                    <h6>HOW MANY EMPLOYEES NEED A DESK PHONE?</h6>
                  </div>
                  <div className='radio_flex my-3'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='deskQ'
                          checked={contactUsInput?.deskQ === '1-10'}
                          onChange={(e) => {
                            onValueChange(e, '1-10');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 1-10 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='deskQ'
                          checked={contactUsInput?.deskQ === '11-21'}
                          onChange={(e) => {
                            onValueChange(e, '11-21');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 11-21 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='deskQ'
                          checked={contactUsInput?.deskQ === '21-50'}
                          onChange={(e) => {
                            onValueChange(e, '21-50');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 21-50 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <span>Custom: </span>
                        <span>
                          {' '}
                          <input
                            type='number'
                            placeholder='100'
                            name='deskQ'
                            onChange={(e) => {
                              contactInputHandler(e);
                            }}
                            style={{
                              width: '30%',
                              border: 'none',
                              borderBottom: '1px solid green',
                            }}
                          />{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='contact-form-heading heading-margin'>
                    <h6>HOW MANY EMPLOYEES NEED A CORDLESS PHONE?</h6>
                  </div>
                  <div className='radio_flex my-3'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='cordless'
                          checked={contactUsInput?.cordless === '1-10'}
                          onChange={(e) => {
                            onValueChange(e, '1-10');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 1-10 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='cordless'
                          checked={contactUsInput?.cordless === '11-20'}
                          onChange={(e) => {
                            onValueChange(e, '11-20');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 11-20 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='cordless'
                          checked={contactUsInput?.cordless === '21-50'}
                          onChange={(e) => {
                            onValueChange(e, '21-50');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 21-50 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <span>Custom: </span>
                        <span>
                          {' '}
                          <input
                            name='cordless'
                            type='number'
                            placeholder='100'
                            onChange={contactInputHandler}
                            style={{
                              width: '30%',
                              border: 'none',
                              borderBottom: '1px solid green',
                            }}
                          />{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='contact-form-heading heading-margin '>
                    <h6>HOW MANY PHONE NUMBERS DO YOU NEED?</h6>
                  </div>
                  <div className='radio_flex my-3'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='phoneNumbers'
                          checked={contactUsInput?.phoneNumbers === '1-10'}
                          onChange={(e) => {
                            onValueChange(e, '1-10');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 1-10 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='phoneNumbers'
                          checked={contactUsInput?.phoneNumbers === '11-20'}
                          onChange={(e) => {
                            onValueChange(e, '11-20');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 11-20 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='phoneNumbers'
                          checked={contactUsInput?.phoneNumbers === '21-50'}
                          onChange={(e) => {
                            onValueChange(e, '21-50');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> 21-50 </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <span>Custom: </span>
                        <span>
                          {' '}
                          <input
                            placeholder='100'
                            onChange={contactInputHandler}
                            name='phoneNumbers'
                            type='number'
                            style={{
                              width: '30%',
                              border: 'none',
                              borderBottom: '1px solid green',
                            }}
                          />{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='radio_flex'>
                    <div className='contact-form-heading heading-margin'>
                      <input
                        name='replaceNumber'
                        checked={contactUsInput?.replaceNumber === true}
                        onChange={(e) => {
                          onValueChange(e, true);
                        }}
                        className=''
                        type='checkbox'
                      />
                      <span>
                        <strong>
                          {' '}
                          ARE YOU REPLACING AN EXISITING PHONE SYSTEM?{' '}
                        </strong>
                      </span>
                    </div>
                  </div>
                  <div className='radio_flex'>
                    <div className='m-2'>
                      <span>Main Phone Number to be Ported over </span>
                      <input
                        type='text'
                        name='mainPhone'
                        onChange={contactInputHandler}
                        style={{
                          border: 'none',
                          borderBottom: '1px solid green',
                          marginLeft: '20px',
                        }}
                      />
                    </div>
                  </div>
                  <div className='radio_flex heading-margin'>
                    <div className='m-2'>
                      <div className=''>
                        <span>How many numbers needs to be ported over</span>
                        <span>
                          {' '}
                          <input
                            name='numbersToPort'
                            onChange={contactInputHandler}
                            type='number'
                            style={{
                              width: '30%',
                              border: 'none',
                              borderBottom: '1px solid green',
                            }}
                          />{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='contact-form-heading heading-margin'>
                    <h5>Other Services Needed</h5>
                  </div>
                  <div className='radio_flex'>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='otherServices'
                          checked={
                            contactUsInput?.otherServices === 'ANALOG FAX'
                          }
                          onChange={(e) => {
                            onValueChange(e, 'ANALOG FAX');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> ANALOG FAX </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='otherServices'
                          checked={
                            contactUsInput?.otherServices ===
                            'FIRE PANEL/ELEVATOR'
                          }
                          onChange={(e) => {
                            onValueChange(e, 'FIRE PANEL/ELEVATOR');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> FIRE PANEL/ELEVATOR </span>
                      </div>
                    </div>
                    <div className='m-2'>
                      <div className=''>
                        <input
                          name='otherServices'
                          checked={contactUsInput?.otherServices === 'E-FAX'}
                          onChange={(e) => {
                            onValueChange(e, 'E-FAX');
                          }}
                          className=''
                          type='radio'
                        />
                        <span> E-FAX </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='input-div'>
                        <button
                          onClick={contactUsSubmitHandler}
                          className='scs-button'
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Inner Wrapper End -->  */}
      {/* <!-- Google Map Start --> */}
      <div id='google-map'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198710.35112897935!2d-98.51489117772236!3d38.904562823631146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited+States!5e0!3m2!1sen!2sin!4v1471865832140'
          allowfullscreen=''
        ></iframe>
      </div>
      {/* <!-- Google Map End--> */}
    </div>
  );
};
export default ContectUs;
