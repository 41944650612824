import React, { useEffect } from 'react'

const ServicesAgreement = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="container" style={{ fontSize: '1rem', textAlign: 'justify', marginBottom: '100px' }} >
            <div className="business_solution_heading" style={{ margin: '50px 0 ' }} >
                <h2>SCS Telcom Master Services Agreement</h2>
                <span className="heading-line"></span>
            </div>
            <div className="services-aggrement-1" >
                <p>This Master Service Agreement is made effective between SCS Telcom, LLC, (SCS Telcom, LLC), a Texas company with principal offices in Texas, and Customer as listed on SCS Telcom, LLC Customer Service Order agreement (Customer). Each party is collectively referred to as Parties, or individually as Party. In order to use SCS Telcom, LLC Services, Customer must agree to these Terms. Customer may not use SCS Telcom, LLC Services if Customer does not accept these Terms. Each Party accepts these Terms by completing a SCS Telcom, LLC Customer Service Order Agreement or Account Activation Guide. The Customer Service Order may be completed by accepting these terms online at www.SCS Telcom, LLC.com as part of the registration process or by returning a signed and dated Customer Service Order, or by returning a signed Account Activation Guide to SCS Telcom, LLC at Info@SCSTelcom.com.  Small business SCS Telcom VoIP customers enter into this agreement on behalf of Customer name listed on service registration form at the time the Service was ordered online at www.SCS Telcom, LLC.com or through a Customer Service Order Agreement if signing a 3,4-, or 5-year term agreement.
                By executing Customer Service Order Agreement or Account Activation Guide, Customer acknowledges that Customer has read and understands Agreement completely, and that Customer agrees to the terms and conditions set forth below. Furthermore, the signatory of this Agreement is authorized to enter into this Agreement on behalf of Customer name on Service Order agreement and to become bound by its terms.
                </p>
            </div>



            <div className="heading-below-line">
                <h4> Recitals  </h4>
            </div>
            <div className="services-aggrement-2" >
                <p>WHEREAS SCS Telcom, LLC is in the business of providing various Services as set forth in Exhibit A and Customer desires to use said Services.
                NOW, THEREFORE, in consideration of the covenants and conditions set forth herein, the parties agree as follows:
                </p>
            </div>



            <div className="heading-below-line">
                <h4> Services Offered  </h4>
            </div>
            <div className="services-aggrement-3" >
                <p>A listing of the Services covered under this Agreement is set forth in Exhibit A. The Services listed in Exhibit A may, at the sole discretion of SCS Telcom, LLC, be changed from time to time.
                The specific Services to be provided to the Customer will be set forth in a Customer Service Order Agreement and will be provided in accordance with the terms of this Agreement. Pricing for the Service to be provided will be included in the Customer Service Order Agreement.  Pricing for additional services is listed in Exhibit B of this agreement. Pricing for additional hardware, and professional services will be provided upon request to by writing to sales@SCSTelcom.com.
                </p>
            </div>



            <div className="heading-below-line">
                <h4 >Service Distinctions And Restrictions </h4>
            </div>
            <div className="services-aggrement-4" >
                <p>Customer acknowledges and understands that the Services covered under this agreement are not traditional telephone services. Important distinctions (some, but not necessarily all of which are described in this Agreement) exist between said Services and the traditional telephone service, including but not limited to feature functionality, service limitations and different regulatory treatment. This different regulatory treatment may limit or otherwise affect Customer rights of redress before Federal, State or Provincial telecommunications regulatory agencies.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Emergency Services – 911 / E911 Dialing Emergency - 911 </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>In accordance with FCC regulations for Interconnected Voice over IP service providers, SCS Telcom, LLC provides access to emergency services via 911/E911 dialing to all its customers based in the United States of America. Customer understands and acknowledges that SCS Telcom, LLC does not provide access to emergency services outside of the USA. There are important differences to the 911/E911 service provided by SCS Telcom, LLC as compared to that provided by traditional wire-line service providers. Description and acknowledgement of this service is provided in Exhibit C. As part of setting up Customer account, the Customer is responsible for providing physical address information for each emergency location. THIS INFORMATION MUST BE PROVIDED IN ORDER FOR 911 EMERGENCY CALLS TO BE DELIVERED TO A LOCAL PUBLIC SERVICE OFFICE. If the Customer fails to configure the 911 information, 911 emergency calls will be routed to a national center. 911 emergency calls that are routed to the national center because the Customer failed to provide address information will be subject to a per call surcharge of $100. </p>
            </div>


            <div className="heading-below-line">
                <h4 >Electrical Power </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer acknowledges and understands that SCS Telcom, LLC Services will not function in the absence of electrical power</p>
            </div>


            <div className="heading-below-line">
                <h4 > Internet Access </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer acknowledges and understands that the Services provided will not function if there is an interruption of Customer’s broadband or high-speed internet access service. Interruptions include service outages and/or service degradations of the Customer’s Internet Service Provider or other third party that either intentionally or inadvertently restricts access, blocks ports, or similarly interferes and impedes SCS Telcom, LLC’s ability to deliver Services including Emergency 911.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Supported Customer Devices </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>In order to use SCS Telcom, LLC SIP Trunking Services, a compatible device is required on the Customer’s premises. An example of such a device is a PBX that supports SIP Trunking. SCS Telcom, LLC does not provide these devices as part of its SIP Trunking Service offering. It is the Customer’s responsibility to provide the appropriate equipment in order for the SIP Trunking Service to function properly. A list of Service compatible equipment manufacturers can be found on the SCS Telcom, LLC web site at www.SCS Telcom, LLC.com. The list of compatible equipment manufacturers may be changed from time to time at the sole discretion of SCS Telcom, LLC. Manufacturers of compatible equipment are not tied to SCS Telcom, LLC in any way, financial or otherwise, and frequently make changes to the devices they offer. These changes could make the manufacturer’s device inoperable with SCS Telcom, LLC’s Service. SCS Telcom, LLC will work with manufacturers to ensure compatibility but SCS Telcom, LLC cannot guarantee compatibility with any manufacturer’s device.
                SCS Telcom, LLC does not offer technical assistance for third party devices. This includes PBXs, Gateways, Switches, Firewalls, IP Phones, and Adapters. Customer understands it is their responsibility to properly configure their devices, PBXs, Gateways, Switches, Firewalls, IP Phones, adapters, and devices for use with SCS Telcom, LLC services. If Customer uses a manufacturer’s device that has not been verified as compatible with SCS Telcom, LLC Services, Customer will indemnify and hold harmless SCS Telcom, LLC, its officers, directors, employees, and affiliates against any liability arising out of such use.
                </p>
            </div>


            <div className="heading-below-line">
                <h4 > Fax & Analog Devices </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>SCS Telcom, LLC facilities support both the T.38 and G.711 passthrough protocols for faxing; however, fax service and delivery using SIP Trunking and Hosted Voice over IP services are not guaranteed. SCS Telcom, LLC recommends nVFAX which is an electronic based fax service.  More information on this can be requested by emailing Info@SCSTelcom.com .
                Customer acknowledges Alarm Lines, Credit Card Machines, Elevator Lines, and other analog device applications are not to be used with SCS Telcom, LLC service. SCS Telcom, LLC Session Initiated Protocol (IP Voice) may not function with these devices or by nature of delivery may not meet local, state, or federal specifications or requirements for these line applications.
                </p>
            </div>


            <div className="heading-below-line">
                <h4 > Number & Porting </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer is responsible for understanding the number porting process before orders are submitted. SCS Telcom, LLC serves most markets in the continental US 48 states for local number access; however, not all markets are serviced. Some markets have restrictions that prevent SCS Telcom, LLC from servicing these areas. Customer is to verify with SCS Telcom, LLC that their numbers are portable by emailing Info@SCSTelcom.com. Although it is common for the porting process to take 10 to 14 days from the time port orders are submitted, existing carriers can release numbers much sooner.  Customer understands port orders shall not be submitted until customer’s onsite equipment and PBX have been configured and tested.  Following submission of a port order, the customer will receive a Firm Order Commit (FOC) date.  A FOC date is the date the port will occur.  Rescheduling FOC date is difficult and, in some cases, “cannot” be accommodated especially for a date change request that is within 48 hours of the FOC date.  Additional charges up to $100 per number can be charged for expedited change requests, FOC date changes or snap-back port requests.  SCS Telcom, LLC does not provide guarantees on snap-back port requests. Porting information, timelines, and preparation details are provided in the SCS Telcom, LLC customer portal.
                The number provisioning process begins once an account has been ordered and activated. For small business SCS Telcom VoIP SIP Trunking accounts this begins once a payment is submitted to activate service. SCS Telcom, LLC does not inventory new DID numbers in all markets and provisioning times can vary. Although SCS Telcom, LLC provides an option to buy Vanity Toll Free numbers, local DID vanity number selection is not offered.
                If you want, you may “port” your SCS Telcom, LLC number(s) to another carrier. To do so, you must work with the receiving carrier.  Once your port has been completed to your new service provider you must email Support@SCSTelcom.com to cancel your SCS Telcom, LLC service.  Until you cancel service you will remain a SCS Telcom, LLC customer and continue to be responsible for all charges and taxes associated with your monthly service.   Porting numbers to another provider does not release term obligations as noted under section 5.0 of this agreement.
                </p>
            </div>


            <div className="heading-below-line">
                <h4 > Toll Free Number </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>SCS Telcom, LLC is a Toll Free RESPORG that provides both the ability to order new toll-free numbers and/or port over existing toll-free numbers. Toll free porting information, timelines, and preparation details are provided in the customer portal. Toll free service can be added to any account. Toll Free Vanity number selection is available as an optional feature. Toll Free RESPORG services for time-of-day routing (TOD), day of week routing (DOW), geographic routing, and percent allocation routing are separate features that are not included in SCS Telcom, LLC standard service plans. These features may be purchased separately by contacting Info@SCSTelcom.com.</p>
            </div>



            <div className="heading-below-line">
                <h4 > Internation Did Origination</h4>
            </div>
            <div className="services-aggrement-5" >
                <p>SCS Telcom, LLC offers local DID numbers in many international markets. A list of countries where International DID numbers are available can be found in the customer portal. SCS Telcom, LLC provides no warranty nor guarantees on international DID number porting. International DID numbers are provisioned with two (2) channels. Depending on the country additional channels can be ordered. A rate of 4 cents per minute is charged for inbound calls received on International DID numbers.  Outbound rates made from International DIDs can be found in customers’ web portal.  Outbound rates will depend on the country, region, and city that you are calling.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Rental Hardware </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>In order to use SCS Telcom, LLC’s Services, it may be necessary to install and operate SCS Telcom, LLC equipment on the Customer’s site. For example, a gateway device may be required in order for a traditional PBX to use SCS Telcom, LLC SIP Trunking Service. In these cases, at the discretion of SCS Telcom, LLC, SCS Telcom, LLC may offer the Customer the opportunity to purchase or rent the equipment from SCS Telcom, LLC. If the Customer elects to rent the equipment from SCS Telcom, LLC, Customer understands and agrees that rented equipment remains the property of SCS Telcom, LLC and will be returned to SCS Telcom, LLC at the end of the Agreement term. Customer understands that failure to return the rented equipment to SCS Telcom, LLC within thirty (30) calendar days of termination of Services will result in the Customer being charged the full cost of new equipment.
                Customer can order rental hardware from SCS Telcom, LLC with an approved credit application.   This can include mSIP rental SBCs, Routers, EasyPath SIP to PRI Gateways, SIP to Analog Gateways, and Rental Phones for Hosted Voice Service.   Damage caused to equipment outside of normal wear and tear is the responsibility of Customer and may result in the Customer being charged the current purchase price of new equipment. This includes power surges, theft, water damage or damage due to improperly securing hardware.
                If the Customer elects to purchase the equipment instead of renting, warranty of the equipment will be provided by the equipment manufacturer.
                Rental routers and rental gateways from SCS Telcom, LLC are considered Managed.  Customer understands and accepts these devices are locked and not accessible to them to make their own changes.   All changes to rental routers and rental gateways are to be made by SCS Telcom, LLC.  Customers who attempt to access, override, or tamper with SCS Telcom, LLC managed routers and gateways will be considered in violation of this agreement and can be charged for a minimum of 2 Hours of Professional Service at a rate of $195 per hour for each rental device to be reconfigured.
                </p>
            </div>


            <div className="heading-below-line">
                <h4 > Other Services Restrictions </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Certain usage restrictions may be placed on the Services due to a high risk of fraud, regulatory restrictions and/or difficulties in settlement. Free conference calling or similar services that participate in traffic simulation practices or schemes that result in excessive charges may be blocked. These restrictions include but are not limited to calls terminating to or originating from a specific country or geographic area being blocked.  Restrictions for said Services are described in the SCS Telcom, LLC web portal, and may change without notice from time to time at SCS Telcom, LLC’s sole discretion.  Customer may also obtain a current list of blocked destinations by submitting a request through electronic mail to SCS Telcom, LLC at Support@SCSTelcom.com.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Acceptable Use Of Services </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer agrees to use the Services only for lawful purposes. Customer agrees not to use SCS Telcom, LLC Services for transmitting or receiving any communication or material of any kind if in SCS Telcom, LLC’s sole judgment the transmission, receipt or possession of such communication or material (i) would constitute criminal or illegal activities; give rise to a civil liability, or otherwise violate any applicable local, state, national or international law or (ii) encourages conduct that would constitute a criminal offense, give rise to a civil liability, or otherwise violate any applicable local, state, national or international law.
                Customer agrees not to attempt to hack, misuse, disrupt, disable, or damage the Service in any way and that Customer will not interfere with or disrupt other users of Services and/or any other service provider who furnishes services to Customer in connection with this Agreement. Customer agrees to configure, operate, and maintain any and all devices using SCS Telcom, LLC’s Services in accordance with industry standards and best practices, including the limitation of excessive keep-alive, short registration timers, message flooding, and other abusive activities. Customer agrees not to engage in the delivery of unwanted or unsolicited communications or SPAM to third parties using SCS Telcom, LLC Services or resources. SCS Telcom, LLC reserves the right to suspend customers service immediately and without advance notice if SCS Telcom, LLC, in its sole discretion, believes that Customer has violated the above restrictions.  If customer abandons service Customer is responsible for the full month’s charges to the end of the current term, including without limitation unbilled charges and taxes all of which immediately become due and payable. If SCS Telcom, LLC, in its sole discretion, believes that Customer has violated any of the above restrictions, SCS Telcom, LLC may forward the objectionable material, as well as Customer’s communications with SCS Telcom, LLC and Customer’s personally identifiable information to the appropriate authorities for investigation and prosecution and Customer hereby consents to such forwarding.
                </p>
            </div>


            <div className="heading-below-line">
                <h4 > Fraudulent Activity  </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer is responsible for maintaining the security of their SCS Telcom, LLC web account, SCS Telcom, LLC Service credentials, and Customer equipment connected to the SCS Telcom, LLC network. Should the Customer’s account and/or equipment become compromised (hacked), resulting in SCS Telcom, LLC Services being used on behalf of the Customer whether authorized or unauthorized, the Customer is responsible for any charges or fees associated with such usage. If the Customer believes that their account or equipment has been compromised, the Customer must immediately report the suspicious activity to SCS Telcom, LLC. SCS Telcom, LLC reserves the right to immediately Disable any accounts that appear to have fraudulent activity. At any time, the Customer may request that new credentials be issued to the Customer. Should the Customer’s account be Disabled because of fraudulent activity, prior to SCS Telcom, LLC restoring service, the Customer must provide SCS Telcom, LLC with information documenting the steps taken to prevent fraudulent activity from occurring and, if applicable, arrange for settlement of any charges incurred as a result of fraudulent activity.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Abuse Of Services  </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>SCS Telcom, LLC service plans are designed for traditional business use. Auto Dialer and Broadcast applications are not to be used with SCS Telcom, LLC service plans without prior written permission from SCS Telcom, LLC. Service parameters for Auto Dialer and Broadcast applications approved by SCS Telcom, LLC will be documented in Customer Service Order Agreement. This will often include concurrent call capacity and calls per second restrictions for auto dialer and broadcast applications. Unauthorized use of an Auto Dialer or Broadcast application with SCS Telcom, LLC Service can result in immediate suspension of service to the customer. Customer agrees to limit the percentage of calls which are less than 6 seconds in length to less than 7% of the total number of calls handled during any given period. Should the percentage of short calls exceed 7%, SCS Telcom, LLC may assess a per minute surcharge of $0.015 per minute on all calls of less than 6 seconds of duration.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Fair Usage Policy On Unlimited Minute Services </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customers who use SCS Telcom, LLC Unlimited Plans agree to the SCS Telcom, LLC Fair Usage Policy.    Unlimited Minute service plans are not to be used for high volume calling applications or call centers.  On average, SCS Telcom, LLC customers use less than 1,000 minutes of usage per line or per hosted voice seat per month. Customer will be considered over the SCS Telcom, LLC fair usage policy when, in aggregate, outbound and inbound DID usage exceeds this average in a given month. For example, a customer with 10 lines or hosted voice seats would not typically exceed 10,000 minutes of usage (10 lines x 1,000 minutes) in a month. Customers doubling or tripling this amount can be considered as violating the SCS Telcom, LLC Fair Usage Policy. SCS Telcom, LLC reserves the right to review Customer’s account at any time for potential abuses of this Fair Usage Policy. SCS Telcom, LLC may determine abnormal or abusive usage based on comparisons to the usage patterns of other customers. If SCS Telcom, LLC identifies excessive or abusive traffic patterns, commercially reasonable efforts to inform Customer and to provide the opportunity to correct the unusual usage behavior. If customer is unable to correct or resolve the abusive traffic patterns within thirty (30) days, SCS Telcom, LLC reserves the right to change Customer’s applicable rate plan to meet fair usage policy guidelines. This can include increasing the number of lines or seats purchased each month or charge an overage rate of 1.5 cents for usage above Fair Usage policy guidelines with or without notice.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Term </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Most services are offered on a term agreement of 3, 4 and 5 years.  This is outlined in the Customer Service Order Agreement. Customer is responsible for Termination fees up to the full value of the remaining agreement which may be assessed if the account is closed prior to the term obligation as set forth in the Customer Service Order Agreement.
                At the end of a term agreement, services will automatically renew for another year (12 months) unless notice of non-renewal is sent by electronic mail from customer of record to Support@SCSTelcom.com no later than thirty (30) days before service agreement ends.  Customers can contact the SCS Telcom, LLC sales team by emailing Info@SCSTelcom.com for other options on extending their services thirty (30) days before their service agreement ends and agreement is renewed for another year (12 months).
                Customers can choose to purchase SCS Telcom VoIP small business SIP Trunking service plans on a 3,4-, or 5-year term agreement or on a month-to-month term basis.  This must be identified at the time of purchase to attain initial incentives.  Month to Month service plans end on the last day of a calendar month.  SCS Telcom, LLC requires thirty (30) days’ notice for Service cancellation of SCS Telcom VoIP service plans on month-to-month service terms.   SCS Telcom VoIP service plans on 3,4, or 5 year term agreements are responsible for the full value of the term agreement which may be assessed if the account is closed prior to the term obligation as set forth in the Customer Service Order Agreement .Cancellation notice for month to month service plans must be sent by electronic mail from customer of record to Support@SCSTelcom.com no later than thirty (30) days before service is to be cancelled of which SCS Telcom, LLC will bill for services and taxes.
                </p>
            </div>



            <div className="heading-below-line">
                <h4 > Start Of Services </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Billing for SCS Telcom, LLC services start as soon as they have been made available for use with or without the completion of existing numbers ported to the service.  Customers are encouraged to submit their number port orders when they open their account.  However, some customers may delay this process or not respond to requests to submit documentation for number porting, thus the number porting completion date is “not” considering a start of service billing date.   Start of service billing begins when the service is available for use with or without existing numbers ported to the service.
                mSIP and EasyPath:  Start of service billing for mSIP or EasyPath will begin the sooner of SCS Telcom, LLC making customer aware service is available for use or fifteen (15) days from the time SCS Telcom, LLC rental equipment is shipped.  SIP Trunking service is provisioned the same day account is opened with billing beginning immediately thereafter.  SIP Trunking Accounts using Ramping Plans shall have billing commence as listed in their Ramping Plan Addendum.  If billing is started during the middle of a billing period (Calendar Month), the service billing will be pro-rated.
                Hosted Voice and cloudQ: Start of service billing will begin the sooner of SCS Telcom, LLC set up for service availability or fifteen (15) days from the time SCS Telcom, LLC rental equipment is shipped. The number porting completion date is not considered start of service billing.
                Small Business SIP Trunking Plans:   Start of service billing starts on the day SCS Telcom VoIP service plans are ordered and activated with their initial payment. These service plans are set up and provisioned the same day they are ordered.  SCS Telcom VoIP service plans that elect a term agreement have the option to use a Ramping Plan.  Rates and start of service billing is outlined in the Ramping Plan Addendum in the Customer Service Order Agreement.
                Circuits & Internet Access:  Lead time for a circuit installation varies based on the type of circuit ordered and physical location.   Start of service billing for SCS Telcom, LLC MPLS and Internet Access circuits begin no later than 7 days from the time the customer is notified circuit is available for use.
                </p>
            </div>


            <div className="heading-below-line">
                <h4 > Charges & Fess </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>The charges and fees for SCS Telcom, LLC’s Services are categorized into five types: (1) Usage, (2) Recurring, (3) Surcharges, (4) One Time and (5) Taxes and Regulatory Fees.
                Usage charges are assessed to Customer’s account as Services are used and are based on usage Rates in effect at the time the Service was delivered. Usage rates may vary depending on a number of factors including but not limited to: (i) the type of Services provided, (ii) the originating and terminating locations where the Services are offered and provided, (iii) the time that the Service is utilized, including but not limited to time of day, day of week, day of month, and (iv) quality of service. Domestic usage is billed in six second increments and may be subject to a minimum connect charge. International usage rates vary by country and may be billed in different duration increments and are subject to a minimum connect charge.
                Recurring Charges are billed to the Customer periodically. Typically, Recurring Charges are assessed monthly. Customer is purchasing the Service for full monthly terms, meaning that if Customer terminates Services prior to the end of Customer’s specified term outlined in Customer’s “Customer Service Order Agreement”, Customer will be responsible for the full charges to the end of the then current term, including without limitation unbilled Recurring Charges, all of which become immediately due and payable.
                Surcharges may be assessed on a per call basis when the call is placed.
                One Time Charges shall be billed as they are incurred.
                Taxes and other Regulatory Fees will vary by tax jurisdiction and be assessed at the end of the billing cycle. During a normal billing cycle, estimated taxes will be applied as charges are incurred on the account. At the end of each billing cycle, the estimated taxes will be replaced with the accurate and final taxes.
                Expiration of the term or termination of Service does not excuse the Customer from paying all unpaid, accrued charges due in relation to the Agreement.
                All funds, charges, fees, and credits will be settled in United States dollars (USD).
                </p>
            </div>


            <div className="heading-below-line">
                <h4 > Online Balance </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Account balance information is provided on the SCS Telcom, LLC website. Customer may view their online account balance by logging into the SCS Telcom, LLC website. The online balance is updated in near real-time as billable events occur. The online balance is provided for the Customer’s convenience and should ONLY be considered as an estimate of the Customer’s account balance during the current billing cycle. The account balance may be adjusted at the end of the billing cycle when the final statement is prepared for the billing cycle.</p>
            </div>



            <div className="heading-below-line">
                <h4 > Minimum Allowable Balance </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>The minimum allowable balance for prepaid services is $0.01. To avoid service disruption, prepaid Customers must maintain a balance greater than $0.00 at all times.
                Customers who have a service plan with a monthly recurring cost greater than $500 per month can apply for a Post-Paid billing with a completed credit application. Approved Post Paid customers will have an established credit line provided by SCS Telcom, LLC. Post Paid account balances are to be paid in full on payment due date outlined in the Customer’s postpaid terms and conditions which is provided after a credit line is approved by SCS Telcom, LLC.  To avoid service disruption customers must meet monthly payment obligations outlined in aforementioned postpaid terms and conditions documentation which is provided to those approved for postpaid billing.
            </p>
            </div>



            <div className="heading-below-line">
                <h4 > Services And Credits </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Service Credit allowances are listed in the SCS Telcom, LLC SLA. SCS Telcom, LLC provides an SLA for service plans on term agreements of 3,4, or 5 years.  Customer requesting SLA credits are to provide written request submitted by electronic mail to Support@SCSTelcom.com. If it is determined by SCS Telcom, LLC that a credit is warranted, the amount will be credited directly to the Customer’s SCS Telcom, LLC online account balance to be used for future Services. Cash credits will not be issued. Credits will not be refunded in the event that this agreement is terminated.</p>
            </div>



            <div className="heading-below-line">
                <h4 > Taxes </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer is responsible for, and shall pay, any and all applicable federal, state, provincial, municipal, local or other governmental sales, use, excise, value-added, personal property, public utility or other taxes, fees or charges now in force or enacted in the future, that arise from or as a result of Customer’s subscription or use or payment for Services. Such amounts are in addition to payment for the Services and will be assessed to Customer’s account as set forth in this Agreement. If the Customer is a recognized charitable or non-profit organization and is exempt from payment of such taxes, the Customer must provide SCS Telcom, LLC with a copy of the original certificates that satisfy applicable legal requirement for attesting to tax-exempt status. Tax exemption will only apply from and after the date SCS Telcom, LLC receives such certificate.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Payment </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Payments may be made by submitting a valid credit card number (American Express, Visa or MasterCard, or any other issuer then-accepted by SCS Telcom, LLC) through Customer’s portal. Customer may also mail a check or submit an ACH bank transfer. SCS Telcom, LLC reserves the right to stop accepting credit cards from one or more issuers.
                To use SCS Telcom, LLC prepaid services, customer must establish a prepaid account balance by depositing funds into the account prior to or at the time the Services are activated as noted under Section 7.1. At all times, prepaid customers must maintain an account balance that is greater than the minimum allowable balance for the account as set forth in Section 7.1. To replenish a prepaid account balance, Customer must deposit additional funds using one of acceptable forms of payments. Should the account balance fall below the minimal allowable balance of $0, the account may, at the sole discretion of SCS Telcom, LLC be disabled. No suspension or termination of the Services or of this Agreement shall relieve Customer from paying any amounts due hereunder.
                </p>
            </div>


            <div className="heading-below-line">
                <h4 >Refund of Unused Repaid Balance At Termination </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Following termination of this agreement, SCS Telcom, LLC will refund any monies remaining in the Customer’s account within sixty (60) days of effective termination date. Refunds will be issued to the customer, using check or other acceptable means, to Customer’s billing address on record at that time. The amount refunded will be equal to the ending account balance less any termination fees, disconnect fees, and any unbilled charges and applicable taxes for Services delivered prior to termination.</p>
            </div>


            <div className="heading-below-line">
                <h4 > Delinquent Payment </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>If any charges or fees for Services are due but unpaid for any reason including, but not limited to, non-payment, declined or reversed Credit Card charges or Checks returned by the bank, SCS Telcom, LLC may suspend or terminate Services and all accrued charges shall be immediately due, plus a late fee of the lesser of 1.5% per month or the maximum allowed by law accrued from the date of invoice until payment in full is received by SCS Telcom, LLC. Customer will be fully liable to SCS Telcom, LLC for all costs incurred by SCS Telcom, LLC in collecting accrued charges, including but not limited to collecting costs and attorney’s fees and any charges it receives from the credit card issuer. In addition, for payments made by Check, if the Check is returned unpaid for any reason an additional fee of $25 will be assessed to the Customer’s account.</p>
            </div>



            <div className="heading-below-line">
                <h4 >Billing Disputes </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>All billing disputes or requests for adjustments must be made in good faith and received by SCS Telcom, LLC in writing within 45 days of the disputed event or Customer’s right to raise such billing disputes will be deemed waived. All billing disputes must be submitted to the following email address: Support@SCSTelcom.com. Each billing dispute should contain sufficient information for SCS Telcom, LLC to investigate the disputed event, including but not limited to, the time and date of the event, type of service used, origination and termination details, and the nature of the dispute. SCS Telcom, LLC will respond to each dispute within 21 days of receipt of properly completed dispute information. If SCS Telcom, LLC determines that a disputed event was billed in error, SCS Telcom, LLC will issue a credit to reverse the amount that was incorrectly billed. SCS Telcom, LLC shall solely determine disputed events, and SCS Telcom, LLC’s decision on the disputed event, absent arithmetic errors, shall be final.</p>
            </div>



            <div className="heading-below-line">
                <h4 >Suspension And Termination </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>If Customer’s Service is terminated for any stated reason, including without limitation violation of this Agreement, or because of any improper or unacceptable use of Services as set forth in Section 3.0 and 4.1, Customer will be responsible for charges to the end of their current term, including without limitation unbilled charges plus termination or disconnect fee, all of which immediately become due and payable. Accounts that are inactive for a period of sixty (60) days or more will be automatically suspended. Accounts are considered inactive if no Service usage occurs during the last sixty (60) days. Inactive accounts that are suspended will, at the sole discretion of SCS Telcom, LLC, be terminated if the Customer fails to request that the account be reactivated and resumes using service within thirty (30) days of the account being suspended. Accounts terminated for inactivity will be assessed a disconnect fee up to the full remaining term outlined on Customer’s Service Order agreement. Accounts that are reactivated by the Customer after being suspended due to inactivity may be subject to a reactivation fee.
                SCS Telcom, LLC reserves the right to suspend or discontinue providing Services in its sole discretion. If SCS Telcom, LLC discontinues providing the Service generally, or terminates Customer’s Service in its discretion without a stated reason, Customer will only be responsible for charges accrued through the date of termination. SCS Telcom, LLC will provide customer a minimum of ninety (90) days’ notice if a service is to be discontinued.
                </p>
            </div>



            <div className="heading-below-line">
                <h4 > Prohibation On Resale </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Services are being provided to Customer as an end user unless specifically noted under a wholesale services agreement. Customer may not resell or transfer the service to any other person or party for any purpose, without prior written permission from SCS Telcom, LLC. Resale of the service without permission including sharing a service plan with one or more separate third-party users will result in the immediate suspension of service to the customer. If interested in reselling SCS Telcom, LLC’s Service, the Customer should contact our sales team at Sales@SCSTelcom.com</p>
            </div>


            <div className="heading-below-line">
                <h4 >Copyright, Trademarks, Unauthorized </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>The Services and any software used to provide the Services or provided to Customer in conjunction with providing the Services, and all Services, information, documents, and materials on SCS Telcom, LLC’s website(s) are protected by trademark, copyright or other intellectual property laws and international treaty provisions. All websites, corporate names, service marks, trademarks, trade names, logos, and domain names (collectively Marks) of SCS Telcom, LLC are and shall remain the exclusive property of SCS Telcom, LLC and nothing in this Agreement shall grant Customer the right or license to use any of such Marks. Customer acknowledges that Customer has not been granted any license to use the firmware or software used to provide the Services or provided to Customer in along with providing the Services.</p>
            </div>



            <div className="heading-below-line">
                <h4 >Privacy  </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer acknowledges and understands that SCS Telcom, LLC Services utilize, in whole or in part, the public Internet and third-party networks to transmit voice and other communications. Information transmitted over the public Internet or third-party networks may not be encrypted and should be considered insecure. SCS Telcom, LLC shall not be liable for any lack of privacy which may be experienced with regard to using the Service.
                Customer acknowledges and understands that from time-to-time SCS Telcom, LLC employees, subcontractors, and agents may view Company’s data and call information in order to resolve specific errors discovered with SCS Telcom, LLC’s Services or third-party service providers.
                </p>
            </div>



            <div className="heading-below-line">
                <h4 >Services Outage  </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer acknowledges and understands that SCS Telcom, LLC Services rely on existing Customer infrastructure (not provided by SCS Telcom, LLC) and are dependent on high-speed, quality broadband access to each Customer’s location. Service outages may occur that are outside of the control of SCS Telcom, LLC. Outages may occur for a variety of reasons including but not limited to power failures, service interruptions by Customer’s broadband provider and/or Internet Service Provider, service interruptions by third party service providers, and failings of the public internet, SCS Telcom, LLC’s components or PSTN (Public Switch Telephone Network). Should such an outage occur, SCS Telcom, LLC Services may be unavailable, in part or entirely, until such time that the outage is resolved, and access connectivity is restored. Non-Usage charges will continue to be accrued during outage periods until Customer or SCS Telcom, LLC terminate the Service in accordance with this Agreement. Service credits for outage can be applied if there is a violation of SCS Telcom, LLC SLA as noted under Section 8.0 of this agreement.
                SCS Telcom, LLC may perform scheduled or emergency maintenance (including temporary suspension of Services as necessary) to maintain or modify its Services. SCS Telcom, LLC will make best effort to provide the Customer with notice of when this scheduled or emergency maintenance will occur.
                </p>
            </div>



            <div className="heading-below-line">
                <h4 >General Conditions  </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>SCS Telcom, LLC’s primary methods of communication with Customer are via electronic mail (email) and the SCS Telcom, LLC web site at www.SCS Telcom, LLC.com. Notices to Customer shall be sent to the email address specified by Customer at the time of registration for the Services or as subsequently updated by Customer. Customer is responsible for notifying SCS Telcom, LLC of any Email Address changes. Customer agrees that sending a message to the Email Address is the agreed upon means of providing notification. Email is used to communicate important information, including time sensitive information, about the Services, billing, changes to the Services and other information. It is required that Customer read any email sent to the Email Address in a timely manner in order to avoid any potential interruption in the Services provided hereunder. SCS Telcom, LLC may also post notices as set forth previously and such posting on the SCS Telcom, LLC website or in customer portal will also constitute notice to the Customer.</p>
                <p>This Agreement and the rates and services outlined on Customer Service Order Agreement will constitute the entire agreement between Customer and SCS Telcom, LLC and govern Customer’s use of the Services, superseding any prior agreements between Customer and SCS Telcom, LLC and any and all prior or contemporaneous statements, understandings, writings, commitments or representations concerning its subject matter.</p>
                <p>SCS Telcom, LLC will honor the prices listed in Customer Service Order Agreement for the duration of the term agreement.  International Rates can change without notice due to conditions of upstream providers or specific conditions that can arise in a given region or country. SCS Telcom, LLC may change the rates on month-to-month service plans including the SCS Telcom VoIP from time to time. Notices will be considered given and effective on the earliest date of when the Customer is notified by email or the notice is posted in the SCS Telcom, LLC web portal. Such changes will become binding on Customer, on the date mailed and/or posted to SCS Telcom, LLC website and no further notice by SCS Telcom, LLC is required.</p>
                <p>All Recitals, Exhibits, and Appendices to this Agreement are fully incorporated in this Agreement.</p>
                <p>The failure of either party to enforce at any time any provision of this Agreement, or to exercise any option which is herein provided, or to require or fail to require at any time performance by the other party of any provision herein, shall in no way affect the validity of, or act as a waiver of this Agreement, or any part hereof, or any right of such party thereafter to enforce it.</p>
                <p>If any provision of this Agreement is found by a proper authority to be unenforceable or invalid such unenforceability or invalidity shall not render this Agreement unenforceable or invalid as a whole and in such event, such provision shall be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions.</p>
                <p>All Section headings and captions used in this Agreement are for convenience or reference only and are not intended to define or limit the scope of any provisions in this Agreement.</p>
                <p>This Agreement shall be governed by and construed in accordance with the laws of the State of Texas.</p>
                <p>Customer acknowledges that a breach of this Agreement will cause irreparable damage and hereby agrees that SCS Telcom, LLC shall be entitled to seek injunctive relief.  In the event a dispute arises between the parties to this Agreement, it is hereby agreed that the dispute and the parties shall submit to binding arbitration.</p>
                <p>Each Party represents and warrants that the execution of this Agreement and performance of Party’s obligations hereunder will not conflict with, result in the breach of any provision of, or the termination of, or constitute a default under, any agreement with any other person or entity of which either Party is a party or by which they are bound.
                18.11    No provision of this Agreement provides any person or entity not a party to this Agreement with any remedy, claim, liability, reimbursement, or cause of action or creates any other third-party beneficiary rights.
                </p>
            </div>



            <div className="heading-below-line">
                <h4 > Limitation Of Liabilities And Indemnification </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Customer acknowledges and understands that SCS Telcom, LLC’s liability is limited for any Service outage, reduction in service level or inability to dial 911/emergency services or to access emergency service personnel, as set forth in this document. Customer agrees to defend, indemnify, and hold harmless SCS Telcom, LLC, its officers, directors, employees, affiliates, and agents and any other service provider who furnishes services to Customer in connection with this Agreement or the Services, from any and all claims, losses, damages, fines, penalties, costs and expenses (including, without limitation, attorney’s fees and costs incurred or suffered by SCS Telcom, LLC) by, or on behalf of, Customer, an Agent of the Customer or any third party or user of Customer’s Service, relating to the absence, failure or outage of the Services, including 911 dialing/emergency services and/or inability of Customer or any third person or party or user of Customer’s Service to dial 911 or to access emergency service personnel, the loss of data, loss of revenue or profits, or damages arising out of or in connection with the use or inability to use the Services, breach of a representation or warranty of agent, acts, omissions or default of the Agent or Agent Parties in the performance of any of the covenants, obligations, services or agreements of Agent subject to any limitations under the laws of the Commonwealth of Virginia. This paragraph shall survive termination of this Agreement.</p>
                <p>SCS Telcom, LLC shall not be liable for any delay or failure to initiate and provide Services, including the inability to access 911 dialing, at any time or from time to time, or any interruption or degradation of voice quality that is caused by any of the following: act or omission of SCS Telcom, LLC or an underlying carrier, service provider, vendor or other third party; equipment, network or facility failure; power outage, equipment, network or facility upgrade or modification; force majeure events such as (but not limited to) acts of god; strikes; fire; war; riot; government actions; equipment, network or facility shortage; equipment or facility relocation; service, equipment, network or facility failure of SCS Telcom, LLC’s Services or caused by the loss of power to Customer; outage of Customer’s Internet Service Provider or broadband service provider; act or omission of Customer or any person using the Service provided to Customer; or any other cause that is beyond SCS Telcom, LLC’s control.</p>
                <p>SCS Telcom, LLC’s aggregate liability for (i) any Service outage or degradation in Services; (ii) any claim with respect to SCS Telcom, LLC’s performance or nonperformance hereunder or (iii) any failure or mistake, or (iv) any SCS Telcom, LLC Party’s act or omission in connection with the subject matter hereof shall in no event exceed Service charges with respect to the affected time period.</p>
                <p>In no event shall SCS Telcom, LLC, its officers, directors, employees, affiliates or agents or any other service provider who furnishes Services to customer in connection with this agreement or the Service be liable for any direct, incidental, indirect, special, punitive, exemplary or consequential damages, or for any other damages, including but not limited to loss of data, loss of revenue or profits, or damages arising out of or in connection with the use or inability to use the service, including inability to be able to dial 911 or to access emergency service personnel through the service. The limitations set forth herein apply to claims founded in breach of contract, breach of warranty, product liability, tort and any and all other theories of liability and apply whether or not SCS Telcom, LLC was informed of the likelihood of any particular type of damages.</p>
                <p>Customer is liable for any and all liability that may arise out of the content transmitted between Users of Services and between Users of Services and third parties. Customer shall assure that Customer’s or User’s use of the Services and content will at all times comply with all applicable laws, regulations, and written and electronic instructions for use. SCS Telcom, LLC reserves the right to terminate or suspend affected Services, and/or remove Customer or Users’ content from the Services, if SCS Telcom, LLC determines that such use or content does not conform with the requirements set forth in this Agreement or interferes with SCS Telcom, LLC’s ability to provide Services to Customer or others or receives notice from anyone that Customer’s or Users’ use or Content may violate any laws or regulations. SCS Telcom, LLC’s actions or inaction under this Section shall not constitute review or approval of Customer’s or Users’ use or Content. Customer will indemnify and hold harmless SCS Telcom, LLC against any and all liability arising from the content transmitted by or to Customer or to Users using the Services. A User means any person, whether authorized or unauthorized, using the Service provided to Customer.</p>
            </div>



            <div className="heading-below-line">
                <h4 >Disputes  </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>In the event a dispute arises between the parties to this Agreement, it is hereby agreed that the dispute and the parties shall submit to binding arbitration in accordance with the rules of the American Arbitration Association, or the applicable United States Arbitration and Mediation Rules of Arbitration. The arbitrator’s decision shall be final and legally binding, and judgment may be entered thereon. Each party shall be responsible for its share of the mediation and arbitration fees in accordance with the applicable Rules of Arbitration. In the event a party fails to proceed with arbitration, unsuccessfully challenges the arbitrator’s award, or fails to comply with the arbitrator’s award, the other party is entitled to costs of suit, including a reasonable attorney’s fee for having to compel arbitration or defend or enforce the award. Any arbitration will take place in the State of Texas. Without limiting the foregoing, the parties agree that no arbitrator has the authority to: (i) award relief in excess of what this Agreement provides; or (ii) award punitive or exemplary damages. Judgment on the award rendered by the arbitrators may be entered in any court having jurisdiction thereof. All claims shall be arbitrated individually, and Customer will not bring, or join any class action of any kind in court or in arbitration or seek to consolidate or bring previously consolidated claims in arbitration. CUSTOMER ACKNOWLEDGES THAT THIS ARBITRATION PROVISION CONSTITUTES A WAIVER OF ANY RIGHT TO A JURY TRIAL. Customer agrees that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service, or the Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>
            </div>




            <div className="heading-below-line">
                <h4 >Products & Services </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>The following services are made available for use pursuant to the terms covered under this agreement Voice over IP (VoIP) SIP Trunking Services – Origination</p>
            </div>


            <div className="heading-below-line">
                <h4 >No Warranties On Servcies </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>SCS Telcom, LLC makes no warranty or guarantee, express or implied, including but not limited to any implied warranties of merchantability, satisfactory quality, fitness of the service for a particular purpose, title or non-infringement or any warranty arising by usage of trade, course of dealing or course of performance or any warranty that the service will meet Customer’s requirements. Without limiting the foregoing, SCS Telcom, LLC does not warrant that the service will be without failure, delay, interruption, and error, degradation of voice quality or loss of content, data, or information. In no event shall SCS Telcom, LLC, its officers, directors, employees, affiliates or agents or any other service provider or vendor who furnishes services or products to customer in connection with this agreement or the Services be liable for any unauthorized access to SCS Telcom, LLC or Customer’s transmission facilities or premises equipment or for unauthorized access to, or alteration, theft or destruction of, Customer’s data files, programs, procedures or information through accident, fraudulent means or devices or any other method, regardless of whether such damage occurs as a result of SCS Telcom, LLC’s or its service provider’s or vendors’ negligence. Statements and descriptions concerning the service, if any, by SCS Telcom, LLC or SCS Telcom, LLC’s agents or resellers are informational and are not given as a warranty of any kind.</p>

                <div className="cabling__list">

                    <ul >
                        <li>Voice over IP (VoIP) SIP Trunking Services – Termination</li>
                        <li>Voice over IP (VoIP) SIP Trunking Services – Toll Free Origination</li>
                        <li>Managed SIP Trunking Services (mSIP)</li>
                        <li>Audio Conferencing Services</li>
                        <li>Hosted Voice over IP (Hosted Voice)</li>
                        <li>Hosted Contact Center (cloudQ)</li>
                        <li>Performance Monitoring Services</li>
                        <li>SCS Telcom, LLC Branded IP Circuits and MPLS Direct Connect</li>
                        <li>EasyPath SIP to PRI</li>
                        <li>nVFAX</li>
                    </ul>
                </div>
            </div>




            <div className="heading-below-line">
                <h4 > Services Limitation And Special consideration </h4>
            </div>
            <div className="services-aggrement-5" >
                <p>Due to fraud and other factors, some international or premium Public Switch Telephone Network (PSTN) destinations may be blocked. The list of blocked PSTN terminations may be updated from time to time by SCS Telcom, LLC at its sole discretion and can change without notice. Customer may obtain a current list of blocked destinations by submitting a request through electronic mail to SCS Telcom, LLC at Support@SCSTelcom.com.</p>
            </div>






        </div>
    )
}

export default ServicesAgreement
