import React, { useState } from 'react';
import './Quote.css';
import Zoom from 'react-reveal/Zoom';
import Swal from 'sweetalert2';
import queryServices from '../../../Services/TransactionService';
import Validate from '../../validation/Validate';

const options = [
  {
    label: 'Select Option',
    value: '',
  },
  {
    label: 'Desk Phone',
    value: 'Desk Phone',
  },
  {
    label: 'Room Phone',
    value: 'Room Phone',
  },
  {
    label: 'Cordless Phone',
    value: 'Cordless Phone',
  },
  {
    label: 'Basic Phone',
    value: 'Basic Phone',
  },
  {
    label: 'Phone Number',
    value: 'Phone Number',
  },
  {
    label: 'Ext/DID',
    value: 'Ext/DID',
  },
  {
    label: 'Fax',
    value: 'Fax',
  },
];

function Quote() {
  const queryService = new queryServices();
  const [quote, setQuote] = useState({
    firstName: '',
    LastName: '',
    email: '',
    phone: '',
    company_name: '',
    no_employee: '',
  });
  const [quote_Regarding, setQuote_Regarding] = useState('');
  const [errorMessage, seterrorMess] = useState({});
  const quoteInputhandler = (e) => {
    setQuote({
      ...quote,
      [e.target.name]: e.target.value,
    });
  };
  const successfulAlert = () => {
    Swal.fire({
      icon: 'success',
      title: 'Your request have been submitted',
      showConfirmButton: true,
    });
  };

  const unSuccefullAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong! Network Connection',
    });
  };
  const quoteSubmitHandler = (e) => {
    e.preventDefault();
    const quoteData = { ...quote, quote_Regarding: quote_Regarding };
    seterrorMess(Validate(quoteData));

    if (
      quote_Regarding !== '' &&
      quote.email !== '' &&
      quote.company_name !== '' &&
      quote.firstName !== ''
    ) {
      seterrorMess({});
      queryService
        .getaQuote(quoteData)
        .then((res) => {
          if (res.data.message === 'Ok') {
            successfulAlert();
            setQuote({
              firstName: '',
              LastName: '',
              email: '',
              phone: '',
              company_name: '',
              no_employee: '',
            });
            setQuote_Regarding('');
          }
        })
        .catch((err) => {
          unSuccefullAlert();
          seterrorMess({});
        });
    }
  };

  return (
    <div className='main-hero-slider-quote'>
      <Zoom left>
        <div className='slider-quote-inner'>
          <div className='quote-inner-heading'>
            <h3>Get a Quote</h3>
          </div>
          <div className='quote-inner-form'>
            <div className='quote-inner-form-input-group'>
              <input
                name='firstName'
                value={quote.firstName}
                onChange={quoteInputhandler}
                className='quote-inner-form-input'
                type='text'
                placeholder='First Name'
              />
              <p className='quote-error-small'>{errorMessage.firstName}</p>
            </div>
            <div className='quote-inner-form-input-group'>
              <input
                name='LastName'
                value={quote.LastName}
                onChange={quoteInputhandler}
                className='quote-inner-form-input'
                type='text'
                placeholder='Last Name'
              />
              <p className='quote-error-small'>{errorMessage.lastName}</p>
            </div>
            <div className='quote-inner-form-input-group'>
              <input
                name='email'
                value={quote.email}
                onChange={quoteInputhandler}
                className='quote-inner-form-input'
                type='text'
                placeholder='E-mail'
              />
              <p className='quote-error-small'>{errorMessage.email}</p>
            </div>
            <div className='quote-inner-form-input-group'>
              <input
                name='phone'
                value={quote.phone}
                onChange={quoteInputhandler}
                className='quote-inner-form-input'
                type='text'
                placeholder='Phone Number'
              />
              <p className='quote-error-small'>{errorMessage.phone}</p>
            </div>
            <div className='quote-inner-form-input-group'>
              <input
                name='company_name'
                value={quote.company_name}
                onChange={quoteInputhandler}
                className='quote-inner-form-input'
                type='text'
                placeholder='Company Name'
              />
              <p className='quote-error-small'>{errorMessage.companyName}</p>
            </div>

            <div className='quote-inner-form-input-group'>
              <input
                name='no_employee'
                value={quote.no_employee}
                onChange={quoteInputhandler}
                className='quote-inner-form-input'
                type='text'
                placeholder='Number of Employee'
              />
              <p className='quote-error-small'>{errorMessage.no_employee}</p>
            </div>
            <div className='quote-inner-form-input-group'>
              <select
                onChange={(e) => setQuote_Regarding(e.target.value)}
                className='form-select form-select-sm dropdown-quote-small'
                aria-label='.form-select-sm example'
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className='qoute-inner-button'>
              <button
                onClick={quoteSubmitHandler}
                className='quote-inner-form-button'
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Zoom>
    </div>
  );
}

export default Quote;
