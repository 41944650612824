import React from 'react';
import './prepackage.css';
import { Link } from 'react-router-dom';


const PrePackage = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2>Pre-Package</h2>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Solutions</li>
                                    <li>Pre-Package</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            <div className="pre-package-main"  >
                <h1>Coming Soon</h1>
            </div>
        </div>
    )
}

export default PrePackage
