import React, { useEffect, useState } from 'react';
import './SuccessAlert.css';
import AuthService from '../../../Services/AuthService'
import queryString from 'query-string'
import service2 from '../../../images/service-icon2.png';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
const tokenURL = 'lcfmosfisodfnhivschdfmsjfjscndfimclsfjfnsiodhfvsldhfblsdviflsdfncsjmldnfvchsifutiowuyerurtwerqwexz'
const authService = new AuthService();

const SuccessAlert = () => {
    const [isVerified, setIsVerified] = useState(false)
    let { token } = queryString.parse(window.location.search)
    useEffect(() => {
        jwt.verify(token, tokenURL, function (err, decoded) {
            if (decoded && decoded._id) {
                let data = { id: decoded._id }
                authService.signupRequestToMasterAdmin(data)
                    .then(response => {
                        console.log(response.data)
                        setIsVerified(true)
                    })
                    .catch(err => {
                        console.log(err)
                    })

            }
        });
    }, [])

    return (
        <>
            {  isVerified ?
                <div className="success_alert_mainDiv">
                    < div className="success-alert" >
                        <div className="success-alert-inner" >
                            <div className="success-alert-icon">
                                {" "}
                                <img src={service2} alt="" />
                            </div>
                            <div className="success-alert-content" >
                                <h3>Verification Success</h3>
                                <p>Thank you for your support, We have successfully verified your data</p>
                                <p>You can now proceed to you home page</p>
                            </div>
                            <Link to="/authui" className="success-alert-button"   >Log in</Link>

                        </div>
                    </div>
                </div>
                : ""
            }


            { !isVerified ? <div className="loading_div" >
                <div class="spinner-border " style={{ color: "#7ebc12" }} role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div> : ""}

        </>
    )
}

export default SuccessAlert;