import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Cleave from 'cleave.js/react';
import TransactionService from '../../../../Services/TransactionService';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const billingTransaction = new TransactionService()

const ModalExample = (props) => {
  const {
    buttonLabel,
    className,
    userDetails,
  } = props;

  let history = useHistory()
  const getUserInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [isLoading, setisLoading] = useState(false)
  const [errorMessage, seterrorMessage] = useState('')
  const [isSuccessful, setisSuccessful] = useState(false)
  const [modal, setModal] = useState(false);
  const [authorize, setauthorize] = useState({
    cardNo: '',
    expiry: '',
    cvc: ''

  })

  const toggle = () => setModal(!modal);

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;
  const authorizedInputHandler = (e) => {
    setauthorize({
      ...authorize,
      [e.target.name]: e.target.value
    })
  }

  const successfulAlert = () => {
    Swal.fire({

      icon: 'success',
      text: 'Your Transaction had been successful',
      confirmButtonColor: '#7ebc12',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.value) {
        history.push("/profile");
      }
    })
  }

  const unSuccefullAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong! Error found',
    })
  }
  const authorizationSubmitHandler = (e) => {
    e.preventDefault()
    setisLoading(true)
    let userInformation = {
      firstName: getUserInfo.first_Name,
      lastName: getUserInfo.last_Name,
      address: getUserInfo.address,
      city: getUserInfo.city,
      state: getUserInfo.state,
      zip: getUserInfo.zip_Code,
      country: getUserInfo.country,
      email: getUserInfo.email,
      phone: getUserInfo.phone,
      fax: getUserInfo.fax,
      cardNo: authorize.cardNo,
      expiry: authorize.expiry,
      cvc: authorize.cvc,
      billDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      totalAmount: props.totalAmount,
      // taxes: billingDetails.cartDetail.tax,

    }

    billingTransaction.chargePayment(userInformation)
      .then((res) => {

        let checkMSG = res.data.errorMessage
        if (checkMSG == "A duplicate transaction has been submitted.") {
          seterrorMessage('A duplicate subscription will not be created.')
          setisSuccessful(true)
          setisLoading(false)

        }
        else if (checkMSG == "The credit card number is invalid.") {
          setisSuccessful(true)
          setisLoading(false)

          seterrorMessage("The credit card number is invalid.")
        }
        else if (checkMSG == "The credit card has expired.") {
          setisSuccessful(true)
          setisLoading(false)

          seterrorMessage("The credit card has expired.")
        }
        else if (checkMSG) {
          setisSuccessful(true)
          setisLoading(false)

          seterrorMessage("Your provided information is not complete")
        }
        else if (res.data.messages.resultCode == 'Ok') {
          billingTransaction.updateUnpaidBill(userInformation.email, props.invoicenumber)
            .then((res) => {
              console.log(res, 'res of update bill', res)
              successfulAlert()
              setisSuccessful(false)
              setisLoading(false)
            })
            .catch((err) => {
              console.log(res, 'res of update bill', err)
              unSuccefullAlert()
              setisLoading(false)

            })
        }
        else {
          setisLoading(false)
          console.log(res, 'res of update bill')
          seterrorMessage(res.data.errorMessage)
        }
        // if (res.data.message == "Bill status has been successfully updated") {
        //   billingTransaction.updateUnpaidBill(userInformation.email, props.invoicenumber)
        //     .then((res) => {
        //       successfulAlert()
        //     })
        //     .catch((err) => {
        //       unSuccefullAlert()
        //     })
        // }
        // else {
        //   console.log('error')
        // }
      })
      .catch((err) => {
        unSuccefullAlert()
        console.log('err', err)
      })
  }

  const authorizationSubmitHandlerUR = (e) => {
  e.preventDefault();
  setisLoading(true);
  let userInformation = {
       customerId: userDetails.customerId,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      address: userDetails.address,
      city: userDetails.city,
      state: userDetails.state,
      zip: 'Not Entered',
      country: 'Not Entered',
      email: userDetails.email,
      phone: userDetails.phone,
      fax: 'Not Entered',
       cardNo: authorize.cardNo,
      expiry: authorize.expiry,
      cvc: authorize.cvc,
      billDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      totalAmount: props.totalAmount,
     }
     billingTransaction.chargePayment(userInformation)
      .then((res) => {

        let checkMSG = res.data.errorMessage
        if (checkMSG == "A duplicate transaction has been submitted.") {
          seterrorMessage('A duplicate subscription will not be created.')
          setisSuccessful(true)
          setisLoading(false)

        }
        else if (checkMSG == "The credit card number is invalid.") {
          setisSuccessful(true)
          setisLoading(false)

          seterrorMessage("The credit card number is invalid.")
        }
        else if (checkMSG == "The credit card has expired.") {
          setisSuccessful(true)
          setisLoading(false)

          seterrorMessage("The credit card has expired.")
        }
        else if (checkMSG) {
          setisSuccessful(true)
          setisLoading(false)

          seterrorMessage("Your provided information is not complete")
        }
        else if (res.data.messages.resultCode == 'Ok') {
          billingTransaction.updateUnpaidBill(userInformation.email, props.invoicenumber)
            .then((res) => {
              console.log(res, 'res of update bill', res)
              successfulAlert()
              setisSuccessful(false)
              setisLoading(false)
            })
            .catch((err) => {
              console.log(res, 'res of update bill', err)
              unSuccefullAlert()
              setisLoading(false)

            })
        }
        else {
          setisLoading(false)
          console.log(res, 'res of update bill')
          seterrorMessage(res.data.errorMessage)
        }
        // if (res.data.message == "Bill status has been successfully updated") {
        //   billingTransaction.updateUnpaidBill(userInformation.email, props.invoicenumber)
        //     .then((res) => {
        //       successfulAlert()
        //     })
        //     .catch((err) => {
        //       unSuccefullAlert()
        //     })
        // }
        // else {
        //   console.log('error')
        // }
      })
      .catch((err) => {
        unSuccefullAlert()
        console.log('err', err)
      })
}

  return (
    <div>
      <Button color="danger" onClick={toggle}>Pay</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} close={closeBtn}>Pay your unpaid orders</ModalHeader>
        <ModalBody>
          <p>Total Amount : {props.totalAmount}</p>
          {
            isSuccessful ? <div class="alert alert-danger" role="alert">
              <p style={{fontSize : '1rem' , margin : '0px'}} >{errorMessage}</p>
            </div> : ''
          }
          <div className="authorized-inputs" >
            <Cleave
              placeholder="Card No"
              options={{
                blocks: [16],
                numericOnly: true
              }}
              onChange={authorizedInputHandler}
              value={authorize.cardNo}
              name="cardNo"
            />
            <Cleave placeholder="MM-YY"
              options={{ date: true, datePattern: ['m', 'y'] }}
              onChange={authorizedInputHandler}
              value={authorize.expiry}
              name="expiry"

            />
            <Cleave
              placeholder="CVC"
              options={{
                blocks: [4],
                numericOnly: true
              }}
              onChange={authorizedInputHandler}
              value={authorize.cvc}
              name="cvc"
            />
            <button type="submit" className="transaction-popup-btn" onClick={userDetails?.firstName?authorizationSubmitHandlerUR:authorizationSubmitHandler}  > {isLoading ? <div class="spinner-border text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div> : 'Pay with card'} </button>{' '}

          </div>
        </ModalBody>
        {/* <ModalFooter>

        </ModalFooter> */}
      </Modal>
    </div>
  );
}

export default ModalExample;