import React, { useEffect, useState } from 'react'
import { Launcher } from 'react-chat-window'
import io from 'socket.io-client'
import ChatService from '../../../Services/ChatService'
import { withRouter } from 'react-router-dom'
import Auth from '../../../authentication'
import { API_BASE } from '../../../Config'
import { setChatToInitial } from '../../../store/actions/ChatAction'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import './style.css'
const chatService = new ChatService()
const auth = new Auth()
let socket

function ChatComponent(props) {
    const dispatch = useDispatch();
    const isloggedIn = useSelector(({ chat }) => chat.isGuestLogin)
    const guest = useSelector(({ chat }) => chat.user)
    const registerUser = JSON.parse(sessionStorage.getItem('register_user'))
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    const ENDPOINT = API_BASE
    const token = auth.token()
    if (registerUser !== null && registerUser.userChatId) { }
    var loginUser = token ? JSON.parse(localStorage.getItem('userInfo')) : ''

    //JOIN A USER
    useEffect(() => {
        if (token !== null) {
            socket = io.connect(ENDPOINT, { transport: ['websocket'], upgrade: false })
            registerUser && socket.emit('join', { name: registerUser.chatContact.name, room: registerUser.chatContact.room }, ({ error }) => {
                if (error) alert(error);

            })
            socket.on('ping', function (data) {
                socket.emit('pong', { beat: 1 });
            });
        }


        if (registerUser !== null) {
            let dataToSend = {
                "params": {
                    "contactEmail": loginUser?.email
                }
            }
            chatService.getChat(dataToSend)
                .then(res => {
                    let chatArray = res.data.dialog
                    let arr = []
                    for (let i = 0; i < chatArray.length; i++) {
                        let obj = {
                            author: res.data.dialog[i].senderType == 'user' ? 'me' : 'them',
                            type: 'text',
                            data: { text: chatArray[i].message },
                        }
                        arr.push(obj)
                    }
                    setMessages(arr)
                })
                .catch(err => {
                    console.log(err.message, 'Error')
                })
        }


    }, [])



    //SETTING A NEW MESSAGE IN CONVERSATION
    useEffect(() => {
        if (isloggedIn === true || token !== null) {
            socket.on('message', (message) => {
                // console.log(messages, 'messages reacieve---')
                if (message) {
                    setMessages([...messages, {
                        author: message.user === 'SCStelcom' ? 'them' : 'me',
                        type: 'text',
                        data: { text: message.text },
                    }])
                }

            })
        }

    }, [messages, isloggedIn])




    const _onMessageWasSent = async (messageObject) => {

        if (token !== null) {
            if (messageObject) {
                socket.emit('sendMessage', { message: messageObject.data.text, contactId: registerUser.chatContact.room }, () => setMessage(''))

            }
            let newMsg = {
                senderType: 'user',
                message: messageObject.data.text,
                time: new Date()
            }

            let dataToSend = {
                newMessage: newMsg,
                contactEmail: loginUser?.email ? loginUser?.email : '',
                from: 'website',
            }

            await chatService.createChatAndChatList(dataToSend)
                .then(res => {
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else {
            Swal.fire({
                title: 'Oops !',
                icon: 'error',
                text: 'Please sign in first!',
                confirmButtonColor: '#7ebc12',
                confirmButtonText: 'Sign In',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    props.history.push("/authui");
                }
            })
        }
    }


    return (
        <div>
            <Launcher
                style={{ zIndex: 1000 }}
                agentProfile={{
                    teamName: 'SCStelcom',
                    imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
                }}
                onMessageWasSent={_onMessageWasSent}
                messageList={messages}
                showEmoji

            />
        </div>
    )
}

export default withRouter(ChatComponent)