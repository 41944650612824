import { useState } from 'react'
import company from '../../../scs-img/company.jpeg';
import icon4 from '../../../images/hosting-box-icon3.png';
import testmonial from '../../../images/testimonials1.png'
import phoneicon from '../../../images/cta-phone-icon.png';
import './whyscs.css';
import number1 from '../../../images/number-icon1.png'
import number2 from '../../../images/number-icon2.png'
import number3 from '../../../images/number-icon3.png'
import number4 from '../../../images/number-icon4.png';
import { Link } from 'react-router-dom';
import Counter from "../../counter/counter";
import userImg from '../../../scs-img/testimonials3.png'


const slidesGo = [
    {
        id: 1,
        img: userImg,
        heading: 'voip',
        name: 'Chris B',
        message: 'In skilled care, the biggest issue is that nobody is sitting in the same office on any given day. We need to be able to be reached, we need to be able to reach our staff, we need to keep track of our staff.'
    },
    {
        id: 3,
        img: userImg,
        heading: 'team1',
        name: 'Vanessa A',
        message: 'We were thrilled to learn we could get more for less with SCS Telcom. The journey to a stable, high quality, lower cost communications system was possible.'
    },
    {
        id: 3,
        img: userImg,
        heading: 'company',
        name: 'Henry S',
        message: 'Very knowledgeable and willing to work within our budget.'
    },
    {
        id: 4,
        img: userImg,
        heading: 'company',
        name: 'Stephen L ',
        message: 'SCSTelcom is a great and decent company who knows the business and wants to get the job done for their customers.'
    },
    {
        id: 5,
        img: userImg,
        heading: 'company',
        name: ' Pam M ',
        message: ' Great communication, great service...fixed my phone & Fiber outage issues and now i am back in business with more features for less. Thanks SCSTelcom for a job well done!.'
    },
]
const WhySCS = (props) => {
    const [current, setCurrent] = useState(0)

    const nextSlide = () => {
        setCurrent(current === slidesGo.length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? slidesGo.length - 1 : current - 1)
    }
    if (!Array.isArray(slidesGo) || slidesGo.length <= 0) {
        return null
    }

    return (
        <div style={{ textAlign: 'justify' }}  >
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h2>Why SCS Telcom</h2>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Why SCS Telcom</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            <section className="our-newsletter-wrapper">
                <div className="container">
                    <div className="title">
                        <h2>About Us</h2>
                        <span className="heading-line"></span> </div>
                </div>
                <div className="container" >
                    <div className="contact-us-para" >
                        <p>SCS Telcom's team has been fulfilling the telephone systems needs of businesses in Houston and surrounding area with reliable and feature-rich systems. We believe that a telephone system should complement your workflow, rather than force you to conform to its capabilities. With clientele represented across nearly every industry, you can be confident that together, we can design and install the right telecom system for your company. Our experience and know-how make Steadfast the go-to company for your VoIP Telephone system.</p>
                    </div>
                </div>
            </section>
            <section className="about-content">
                <div className="container" >
                    <div className="row" >
                        <div className="col-lg-6" >
                            <div data-aos="fade-up" data-aos-offset="400"
                                data-aos-duration="400" className="comopnay-img" >
                                <img src={company} alt="company image" />

                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div data-aos="fade-down" data-aos-offset="400" data-aos-duration="400" >
                                <div class="heading-below-line">
                                    <h3>What we provide</h3>
                                </div>
                                <div className="about-detail" >
                                    <h2>We Execute Our Ideas an for Start to Finish </h2>
                                    <p>SCS Telcom provides Voice Over IP Phone Solutions with voicemail, auto-attendants, multi-line capabilities, and more for you and your business.</p>
                                </div>
                                <div className="row about-detail-icon" >
                                    <div className="col-lg-6" >
                                        <div className="provided-faclty" >
                                            <div className="provided-faclty-1-img" >
                                                <img src={icon4} alt="icon4" />

                                            </div>
                                            <div className="provided-faclty-1-heading" >
                                                <h5>EXPERTS AROUND THE WORLD </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6" >
                                        <div className="provided-faclty" >
                                            <div className="provided-faclty-1-img" >
                                                <img src={icon4} alt="icon4" />

                                            </div>
                                            <div className="provided-faclty-1-heading" >
                                                <h5>BEST PRACTICES FOR INDUSTRY  </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row about-detail-2" >
                                    <div className="col-lg-2 about-detail-2-testi-img"  >
                                        <img src={testmonial} alt="detailimg" />
                                    </div>
                                    <div className="col-lg-10" >
                                        <p  >Making the switch to a VoIP phone system will cut costs, increase functionality, increase flexibility, enhance your team’s mobility, and more. Let SCS Telcom help your business with our variety of service plans and phone hardware options.</p>
                                    </div>
                                </div>
                                <div className="row about-detail-3" >
                                    <div className="col-lg-6" >
                                        <Link to="/terms&uses" className="scs-button">Read More</Link>
                                    </div>
                                    <div className="col-lg-6 about-detail-3-1" >
                                        <span><img src={phoneicon} alt="phone-icon" /></span>
                                        <span><p>844-727-8353</p></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Satisfied Wrapper start --> */}
            <section className="satisfied-wrapper">
                <div className="container">
                    <div className="title">
                        <h2 className="font__weight">Why Choose Us</h2>
                        <span className="heading-line"></span>{" "}
                    </div>
                </div>
                <div className="container">
                    <div className="statistics">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <div className="counter counter-icon-secondary">
                                    <div className="number ">
                                        {" "}
                                        <span>
                                            <Counter target={150} duration={2} />
                                        </span>
                                    </div>
                                    <p>Reliable Services</p>
                                    <div className="shape">
                                        <img src={number1} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <div className="counter counter-icon-secondary">
                                    <div className="number">
                                        {" "}
                                        <span>
                                            {" "}
                                            <Counter target={400} duration={2} />{" "}
                                        </span>
                                    </div>
                                    <p>Up Time</p>
                                    <div className="shape">
                                        <img src={number2} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <div className="counter counter-icon-secondary">
                                    <div className="number">
                                        {" "}
                                        <span>
                                            {" "}
                                            <Counter target={1220} duration={2} />{" "}
                                        </span>
                                    </div>
                                    <p>24/7 Support</p>
                                    <div className="shape">
                                        <img src={number3} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- satisfied Wrapper End -->  */}
            {/* experience Wrapper start */}
            <section className="experience-section" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-lg-6" >

                            <div data-aos="fade-left" data-aos-offset="400" data-aos-duration="400">
                                <div class="heading-below-line">
                                    <h3>Our Experience</h3>
                                </div>
                                <div className="about-detail" >
                                    <h2>Experts with Experience</h2>
                                    <p>Customer acknowledges and understands that SCS Telcom, LLC Services utilize, in whole or in part, the public Internet and third-party networks to transmit voice and other communications. Information transmitted over the public Internet or third-party networks may not be encrypted and should be considered insecure. SCS Telcom, LLC shall not be liable for any lack of privacy which may be experienced with regard to using the Service.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                            <div data-aos="fade-right" data-aos-offset="400" data-aos-duration="400" className="row experience-check_list" >
                                <div className="col-lg-6" >
                                    <ul >
                                        <li><i class="fa fa-check-circle" aria-hidden="true"></i> <span className="check-option">High-functioning systems</span> </li>
                                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span className="check-option"> Forward calls to cell phones </span></li>
                                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span className="check-option"> Integrate your fax system</span> </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6" >
                                    <ul>
                                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span className="check-option-2">Customize your settings</span></li>
                                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span className="check-option-2">Increase performance</span></li>
                                        <li><i class="fa fa-check-circle" aria-hidden="true"></i><span className="check-option-2">All at an affordable price </span></li>
                                    </ul>
                                </div>
                                <div className="container-fluid" >
                                <div className="row serch-aria" >
                                    <div className="col-lg-2 search-icon" >
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </div>

                                    <div className="col-lg-10 search-icon-detail" >

                                            <h4> Best Practices from Industry Experts  </h4>
                                            <p>All installations performed by SCSTelcom ensure expert cabling and hardware Installation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* experience wrapper end */}
            {/* testemonial slider */}

            <section className="first-slider" >
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 icon-side" >
                            <div class="heading-below-line">
                                <h3>Our Experience</h3>
                            </div>
                            <p className="slider-para" >
                                <span> What do people praise </span>
                                <span> about Itinch? Over </span>
                                <span> The Glow </span>
                            </p>
                            <div className="arrow" >
                                <span><i className="fa fa-arrow-left left-arrow" onClick={prevSlide}  ></i></span>
                                <span><i className="fa fa-arrow-right right-arrow" onClick={nextSlide}  ></i></span>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8 col-sm-12 content-side" >
                            {slidesGo.map((slide, index) => {
                                return (
                                    <div className={index === current ? 'slide active' : 'slide'} key={index}  >
                                        {index === current && (
                                            <div className="row " >

                                                <div className="col-lg-3 img-area" >
                                                    <div className="avatar" >
                                                        <img className="testemonial-img" src={slide.img} alt={slide.heading} />
                                                    </div>
                                                    <div className="avatar-name" >
                                                        <h6>{slide.name}</h6>
                                                        <p style={{ opacity: '0' }} >Designation</p>
                                                    </div>
                                                </div>


                                                <div className="col-lg-9 " >
                                                    <div className="testimonial_msg" >
                                                        <p className="testimonial_msg_para" > {slide.message} </p>
                                                        <div className="starts" >
                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            {/* testimonial slider end */}
        </div>
    )
}
export default WhySCS;
