import React, { Component } from 'react'


class authentication extends Component {
    token = () => {
        return sessionStorage.getItem('access_Token')
    }
    getRegisterUserData(){
        return sessionStorage.getItem('register_user')
    }
}

export default authentication
