import { Component } from 'react'
import axios from 'axios';
import { API_BASE } from '../Config'


let instance = axios.create({
    baseURL: API_BASE + 'api/user',
})
let instanceForEmail = axios.create({
    baseURL: API_BASE + 'api/emails'
})

class AuthService extends Component {

    changePasword = (changePass , userDetail) => {
        return instance.patch(`/change-password?email=${userDetail}`, changePass)
    }
    setResetPassword = (userDetail) => {
        return instance.post(`/reset-password`,userDetail);
    }

    resetPassword = (email) => {
        return instance.patch('/reset-password', email)
    }
    updateProfile = (data) => {
        return instance.post('/update-profile', data)
    }
    signupRequest(data) {
        return instance.post('/signupRequest', data)
    }
    signup(data) {
        return instance.post('/signup', data)
    }
    signupRequestToMasterAdmin(data) {
        return instance.post('/signupRequestVerifiedByUser', data)
    }
    signInData(data) {
        return instance.post('/masterAdmin-login', data)
    }
}
export default AuthService
