
import { toast } from 'react-toastify';
import * as ActionTypes from '../actions/actiionTypes';
import { API_BASE } from '../../Config'
import ProductService from '../../Services/ProductServices'
const productService = new ProductService();




toast.configure()
const HardwareReducer = (state = {
    products: [],
    HardwareCart: [],
}, action) => {

    switch (action.type) {
        case ActionTypes.ADD_HARDWARE:
            return { ...state, products: action.payload }

        case ActionTypes.ADD_TO_CART_HARDWARE:
            toast.warning('Your Product is added to cart', { autoClose: 3000 })

            if (state.HardwareCart.length === 0) {

                state.HardwareCart['price_value'] = 0
                return {

                    ...state,
                    HardwareCart: [...state.HardwareCart, action.payload]
                }
            } else {

                let exist = state.HardwareCart.find(res => res._id == action.payload._id)
                if (exist) {
                    state.HardwareCart['price_value'] = 0
                    let existingObject = JSON.parse(JSON.stringify(exist))
                    if (!existingObject) {
                        return {
                            ...state,
                            hardware: [...state.hardware, action.payload]
                        }
                    } else {
                        return state
                    }
                } else {
                    return {
                        ...state,
                        HardwareCart: [...state.HardwareCart, action.payload]
                    }
                }
            }


        case ActionTypes.REMOVE_FROM_CART_HARDWARE:

            return {
                ...state,
                HardwareCart: state.HardwareCart.filter((rem) => rem._id !== action.payload._id)
            }
        // let i = action.payload
        // let arr = state.HardwareCart
        // arr.splice(i, 1)
        // return {
        //     ...state,
        //     HardwareCart: arr
        // }


        case ActionTypes.INC_HARDWARE:

            const cartcopy = [...state.HardwareCart]
            const incItem = cartcopy.filter((inc) => inc._id === action.payload._id)
            const findInd = cartcopy.indexOf(incItem[0])
            if (incItem) {
                const slec = cartcopy.map((hard) => hard._id === action.payload._id ? {
                    ...hard,
                    quantity: hard.quantity + 1,
                    prcie_value: hard.pricing.tax_excluded,
                    tax: hard.pricing.tax_rate
                } : hard)
                return { ...state, HardwareCart: slec }
            }


        case ActionTypes.DEC_HARDWARE:
            const cartcopys = [...state.HardwareCart]
            const incItems = cartcopys.filter((inc) => inc._id === action.payload.pro._id)
            if (incItems) {
                const slecs = cartcopys.map((hard) => hard._id === action.payload.pro._id ? {
                    ...hard,
                    quantity: hard.quantity > 1 ? hard.quantity - 1 : hard.quantity,
                    price: hard.pricing.tax_excluded - hard.pricing.tax_excluded,
                    prcie_value: hard.pricing.tax_excluded,
                    tax: hard.pricing.tax_rate
                } : hard)
                return { ...state, HardwareCart: slecs }
            }

        case ActionTypes.RESET_HARDWARE_CART:
            return { ...state, HardwareCart: [] }



    }
    return state
}


export default HardwareReducer
