
export default function Validate(value) {
    let errorMessage = {};

    if(!value.firstName){
        errorMessage.firstName = "First name required"
    }
    if(!value.refFirstName){
        errorMessage.refFirstName = "First name required"
    }
    // else if (value.firstName.length < 5) {
    //     errorMessage.firstName = 'First name should be more then 5 charactars';
    // }


    if(!value.company_name){
        errorMessage.company_name = "Company name required"
    }
    if(!value.LastName){
        errorMessage.LastName = "Last name required"
    }
    if(!value.lastName){
        errorMessage.lastName = "Last name required"
    }
    if(!value.refLastName){
        errorMessage.refLastName = "Last name required"
    }
    if(!value.companyName){
        errorMessage.companyName = "Company name required"
    }
    if(!value.refCompanyName){
        errorMessage.refCompanyName = "Company name required"
    }
    // else if (value.lastName.length < 5) {
    //     errorMessage.lastName = 'Last name should be more then 5 charactars';
    // }

    if(!value.email){
        errorMessage.email = ('Email address required')
    }
    if(!value.refEmail){
        errorMessage.refEmail = ('Email address required')
    }
    // else if (!/\S+@\S+\.\S+/.test(value.email)) {
    //     errorMessage.email = 'Required valid email adress'
    // }

    if(!value.quote){
        errorMessage.quote = ('Email address required')
    }
    else if (!/\S+@\S+\.\S+/.test(value.quote)) {
        errorMessage.quote = 'Required valid email adress'
    }
    

    if(!value.userPassword) {
        errorMessage.userPassword = 'Password required'
    }else if (value.userPassword.length < 8) {
        errorMessage.userPassword = 'Password need to be more then 8 characters'
    }

    if(!value.phone){
        errorMessage.phone = "Phone number required"
    }
    if(!value.refPhoneNo){
        errorMessage.refPhoneNo = "Phone number required"
    }
    // else if (value.lastName.length < 7) {
    //     errorMessage.phone = 'Phone number should be more then 7 charactars';
    // }


    if(!value.mobile){
        errorMessage.mobile = "Mobile number required"
    }
    // else if (value.mobile.length < 7) {
    //     errorMessage.mobile = 'Phone number should be more then 7 charactars';
    // }

    if(!value.country){
        errorMessage.country = "Country name required"
    }
    if(!value.city){
        errorMessage.city = "City name required"
    }
    if(!value.address  ){
        errorMessage.address = "Address required"
    }
    if(!value.state){
        errorMessage.state = "State name required"
    }
    if(!value.fax){
        errorMessage.fax = "Fax number required"
    }
    else if (!/^(0|[1-9][0-9]*)$/.test(value.fax)) {
        errorMessage.fax = 'Fax is must be number'
    }


    if(!value.zip){
        errorMessage.zip = "Zip code number required"
    }
    else if (!/^(0|[1-9][0-9]*)$/.test(value.zip)) {
        errorMessage.zip = 'Zip code is must be number'
    }

    

    return errorMessage;
}
