import React from 'react'

const DomainPrice = () => {
    return (
        <div>
            <div class="breadcrumb-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-7">
        <h2>Domain Price</h2>
      </div>
      <div class="col-sm-5">
        <div class="inner-breadcrumb">
          <ul>
            <li><a href="index.html">Home</a></li>
            <li>Domain</li>
            <li>Domain Price</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Breadcrumb Wrapper End -->  */}
{/* <!-- Inner Wrapper Start --> */}
<div class="inner-page-wrapper domain-price-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table pricing_table_domain">
            <thead>
              <tr>
                <th>domain</th>
                <th>1 year</th>
                <th>2 year</th>
                <th>renew</th>
                <th>transfer</th>
                <th>whois privacy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>.com</th>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
              </tr>
              <tr>
                <th>.today</th>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
              </tr>
              <tr>
                <th>.guru</th>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
              </tr>
              <tr>
                <th>.org</th>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <th>.net</th>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <th>.co.uk</th>
                <td>$8.98</td>
                <td>$8.98</td>
                <td>$8.98</td>
                <td>$8.98</td>
                <td>$8.98</td>
              </tr>
              <tr>
                <th>.email</th>
                <td>$11.25</td>
                <td>$11.25</td>
                <td>$11.25</td>
                <td>$11.25</td>
                <td>$11.25</td>
              </tr>
              <tr>
                <th>.today</th>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
              </tr>
              <tr>
                <th>.guru</th>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
              </tr>
              <tr>
                <th>.org</th>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <th>.today</th>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
                <td>$9.50</td>
              </tr>
              <tr>
                <th>.guru</th>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
                <td>$9.95</td>
              </tr>
              <tr>
                <th>.org</th>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
                <td>$10.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
{/* <!-- Inner Wrapper End -->  */}
        </div>
    )
}

export default DomainPrice
