import { Component } from 'react'
import axios from 'axios';
import { API_BASE } from '../Config'


let instance = axios.create({
    baseURL: API_BASE + 'api/payment-Transaction',
})



let queryInstance = axios.create({
    baseURL : API_BASE + 'api/query'
})

class TransactionService extends Component {

    unpaidBills = (email) => {
        return instance.get(`/get-unpaid-bill-byEmail?email=${email}`)
    }

    updateUnpaidBill = (email , invoiceNo) => {
        console.log(email , invoiceNo)
        return instance.patch(`/update-billStatus?email=${email}&invoice_number=${invoiceNo}`)
    }
    getCashBillByInvoive = (invoiceNo) => {
        return instance.get(`get-cash-bill-By-invoice?invoiceSearch=${invoiceNo}`)
    }
    getCashBillByEmail = (userEmail) =>{

       
        return instance.get('/get-cash-bill-By-Email?email='+userEmail)
    }

    createCashedBill(data) {
        return instance.post('/create-cash-bill', data)
    }

    getOrderBill = (id) => {
        console.log(id , 'id of user')
        return instance.get('/get-cash-bill' , id)
    }

    sendQuery = (data) => {
        return queryInstance.post('/create' , data)
    }
    getaQuote =(data) => {
        return  queryInstance.post('/create-a-new-quote' , data)
    }

    subcription = (data) => {
        return  instance.post('/create-recurring' , data)
    }


    chargePayment =(data) => {
        return  instance.post('/create-transaction' , data)
    }

  


}
export default TransactionService;
