export const manageTrunking = {
  // headingList: ['Features', 'Reseller Hosting', 'Dedicated Hosting'],
  headingList: [  
    { heading: 'Features', price: '' },
    { heading: 'Reseller Hosting', price: '389' },
    
    { heading: 'Dedicated Hosting', price: '589' },
    { heading: 'Dedicated Hosting', price: '589' },
    { heading: 'Dedicated Hosting', price: '589' }
  ],
  allList: [
    {
      id: 1,
      allData: ['Concurrent calls', 'Unlimited', 'Unlimited', 'Unlimited', 'Unlimited']
    },
    {
      id: 2,
      allData: [' In/outbound DID in US 48 & canada', '5,000 Minutes', '5,000 Minutes', '5,000 Minutes', '5,000 Minutes']
    },
    {
      id: 3,
      allData: ['New or ported DID\'s', '10 DIDs', '10 DIDs', '10 DIDs', '10 DIDs']
    },
    {
      id: 3,
      allData: ['Calling Overage Rate', '3.5 Cent', '3.5 Cent', '3.5 Cent', '3.5 Cent']
    },
    {
      id: 4,
      allData: ['Toll-Free Usage', '4.5 Cent', '4.5 Cent', '4.5 Cent', '4.5 Cent']
    },
    {
      id: 5,
      allData: ['Toll-Free Overage Rate', '-', '-', '-', '-']
    },
    {
      id: 6,
      allData: ['Caller ID Name', 'Included', 'Included', 'Included', 'Included']
    },
    {
      id: 7,
      allData: ['Direct Peering', 'Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 8,
      allData: ['Autodetecting Disaster Recovery', 'Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 9,
      allData: ['Local Phone Number', 'Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 10,
      allData: ['Local E911', 'Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 11,
      allData: ['HIPPA Verified', 'Yes', 'Yes', 'Yes', 'Yes']
    }

  ]

  //          {
  //     heading: 'Features',
  //         headingList: [
  //             'Concurrent calls', ' In/outbound DID in US 48 & canada', 'New or ported DID\'s', 'Calling Overage Rate', 'Toll-Free Usage', 'Toll-Free Overage Rate', 'Caller ID Name', 'Direct Peering', 'Autodetecting Disaster Recovery', 'Local Phone Number', 'Local E911', 'HIPPA Verified'
  //         ]
  // },
  // {
  //     heading: 'Reseller Hosting',
  //         list: ['Unlimited', '5,000 Minutes', '10 DIDs', '3.5 Cent', '4.5 Cent', '-', 'Included', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
  //             },
  // {
  //     heading: 'Dedicated Hosting',
  //         list: ['Unlimited', '5,000 Minutes', '10 DIDs', '3.5 Cent', '4.5 Cent', '-', 'Included', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes']
  // }

}

export const sampleValue = {
  allList: [
    {
      id: 'Concurrent calls',
      allData: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited']
    },
    {
      id: ' In/outbound DID in US 48 & canada',
      allData: [, '5,000 Minutes', '5,000 Minutes', '5,000 Minutes', '5,000 Minutes']
    },
    {
      id: 'New or ported DID\'s',
      allData: [, '10 DIDs', '10 DIDs', '10 DIDs', '10 DIDs']
    },
    {
      id: 'Calling Overage Rate',
      allData: ['3.5 Cent', '3.5 Cent', '3.5 Cent', '3.5 Cent']
    },
    {
      id: 'Toll-Free Usage',
      allData: ['4.5 Cent', '4.5 Cent', '4.5 Cent', '4.5 Cent']
    },
    {
      id: 'Toll-Free Overage Rate',
      allData: [, '-', '-', '-', '-']
    },
    {
      id: 'Caller ID Name',
      allData: [ 'Included','Included', 'Included', 'Included']
    },
    {
      id: 'Direct Peering',
      allData: ['Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 'Autodetecting Disaster Recovery',
      allData: ['Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 'Local Phone Number',
      allData: [, 'Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 'Local E911',
      allData: [, 'Yes', 'Yes', 'Yes', 'Yes']
    },
    {
      id: 'HIPPA Verified',
      allData: [, 'Yes', 'Yes', 'Yes', 'Yes']
    }

  ]
}


