import React from 'react';
import './Generate.css';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const Generate = ({ data }) => {
  const getTableDataNonRecurring = (data) => {
    const allData = data.cartDetail?.cart?.nonRecurring?.map((res, i) => {
      return [
        res.product_name
          ? res.product_name
          : res.packageName
          ? res.packageName
          : '',
        `${res.pricing.tax_excluded}`,
        res.pricing.sales_tax ? `${res.pricing.sales_tax}` : '$0.00',
        res.pricing.city_tax ? `${res.pricing.city_tax}` : '$0.00',
        res.pricing.compared_price ? `${res.pricing.compared_price}` : '$0.00',
        res.pricing.county_tax ? `${res.pricing.county_tax}` : '$0.00',
        res.pricing.puc_surcharge ? `${res.pricing.puc_surcharge}` : '$0.00',
        res.pricing.state_tax ? `${res.pricing.state_tax}` : '$0.00',
        res.pricing.tax_included,
      ];
    });
    return allData;
  };
  const getTableDataRecurring = (data) => {
    const allData = data.cartDetail?.cart?.recurring?.map((res, i) => {
      return [
        res.product_name
          ? res.product_name
          : res.packageName
          ? res.packageName
          : '',
        `${res.pricing.tax_excluded}`,
        res.pricing.sales_tax ? `${res.pricing.sales_tax}` : '$0.00',
        res.pricing.city_tax ? `${res.pricing.city_tax}` : '$0.00',
        res.pricing.compared_price ? `${res.pricing.compared_price}` : '$0.00',
        res.pricing.county_tax ? `${res.pricing.county_tax}` : '$0.00',
        res.pricing.puc_surcharge ? `${res.pricing.puc_surcharge}` : '$0.00',
        res.pricing.state_tax ? `${res.pricing.state_tax}` : '$0.00',
        res.pricing.tax_included,
      ];
    });
    return allData;
  };

  const gen_pdf = () => {
    const doc = new jsPDF();
    doc.text('INVOICE', 80, 10);
    // doc.text('Name:', 10, 20);
    // doc.text(
    //   data.userDetails.firstName + ' ' + data.userDetails.lastName,
    //   30,
    //   20
    // );
    // doc.text('Email', 10, 30);
    // doc.text(data.userDetails.email, 30, 30);
    // doc.text('Status:', 10, 40);
    // doc.text(data.billStatus, 30, 40);
    let col = 60;
    const name = data.userDetails.firstName + ' ' + data.userDetails.lastName;
    // autoTable({
    //   head: [['Invoice No.', data.billingDetails.invoice_no]],
    // });
    doc.autoTable({
      head: [
        ['Invoice No.', data.billingDetails.invoice_no? data.billingDetails.invoice_no: data.billingDetails.invoice_number],
        ['Name:', name],
        ['Email:', data.userDetails.email],
        ['Contact', data.userDetails.phone],
        ['Company', data.userDetails?.company ? data.userDetails.company : ''],
      ],
      styles: { cellPadding: 0.5, fontSize: 12 },
    });

    let finalY = doc.lastAutoTable.finalY || 10;
    if (data.cartDetail?.cart?.nonRecurring?.length > 0) {
      doc.text('Non-Recurring', 20, finalY + 10);
      doc.autoTable({
        startY: finalY + 15,
        head: [
          [
            'Product',
            'Price',
            'Sales Tax',
            'State Sales and Telephone Tax',
            'County Sales Tax',
            'City Sales Tax',
            'State Cost Recovery Charge',
            'State P.U.C. Surcharge',
            'Total',
          ],
        ],
        body: getTableDataNonRecurring(data),
      });
    }
    //  data.cartDetail?.cart?.nonRecurring?.map((res, i) => {
    //    const c = i * 10;
    //    col = col + c;
    //    doc.text('Product', 10, col);
    //    doc.text(res.product_name, 60, col);
    //    col = col + 10;
    //    doc.text('Quantity:', 10, col);
    //    doc.text(`${res.quantity}`, 40, col);
    //    col = col + 10;
    //    doc.text('Price', 10, col);
    //    doc.text(`${res.pricing.tax_excluded}`, 30, col);
    //    doc.text('Tax:', 50, col);
    //    doc.text(`${res.pricing.tax_rate}`, 70, col);
    //    doc.text('Total:', 90, col);
    //    doc.text(`${res.pricing.tax_included}`, 110, col);
    //    col = col + 10;
    //  });
    finalY = doc.lastAutoTable.finalY || 10;

    if (data.cartDetail?.cart?.recurring?.length > 0) {
      doc.text('Recurring', 20, finalY + 10);

      doc.autoTable({
        startY: finalY + 15,
        head: [
          [
            'Product',
            'Price',
            'Sales Tax',
            'State Sales and Telephone Tax',
            'County Sales Tax',
            'City Sales Tax',
            'State Cost Recovery Charge',
            'State P.U.C. Surcharge',
            'Total',
          ],
        ],
        body: getTableDataRecurring(data),
      });
    }
    // if (data.cartDetail?.cart?.recurring?.length > 0) {
    //   doc.text('Recurring', 10, col);
    //   data.cartDetail?.cart?.recurring?.map((res, i) => {
    //     const c = i * 10;
    //     col = col + c;
    //     doc.text('Product', 10, col);
    //     doc.text(res.packageName, 60, col);
    //     col = col + 10;
    //     doc.text('Month:', 10, col);
    //     doc.text(`${res.month}`, 40, col);
    //     col = col + 10;
    //     doc.text('Price', 10, col);
    //     doc.text(`${res.pricing.tax_excluded}`, 30, col);
    //     doc.text('Tax:', 50, col);
    //     doc.text(`${res.pricing.tax_rate}`, 70, col);
    //     doc.text('Total:', 90, col);
    //     doc.text(`${res.pricing.tax_included}`, 110, col);
    //     col = col + 10;
    //   });
    // }
    // doc.text('Total Tax:', 10, col);
    // doc.text(`${data.cartDetail.tax}`, 40, col);
    // col = col + 10;
    // doc.text('Total:', 10, col);
    // doc.text(`${data.cartDetail.totalAmount}`, 30, col);

    doc.autoTable({
      body: [
        ['Total Tax:', `$${data.cartDetail.tax?data.cartDetail.tax.toFixed(2):data.cartDetail.taxes.toFixed(2)}`],
        ['Total Amount:', `$${data.cartDetail?.totalAmount.toFixed(2)}`],
      ],
      styles: {
        cellPadding: 0.5,
        fontSize: 12,
      },
    });

    doc.save('invoice.pdf');
  };
  return (
    <button className='btn btn-primary' onClick={gen_pdf}>
      Generate
    </button>
  );
};

export default Generate;
