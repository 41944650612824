import React, { useState } from 'react'
import { Navbar, Nav, NavDropdown, DropdownButton, Dropdown, NavbarBrand } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
// import Logo from '../../scs-img/nav-_logo-removebg-preview.png';
import Logo from '../../scs-img/telcom-logo.c828d489.png';
import './NavBar.css';
import { Fade, Zoom } from 'react-reveal';
import { SidebarData } from '../../DummyData/SideBarData';
import Swal from 'sweetalert2';



function NavBarNew(props) {
    const history = useHistory()
    let tok = sessionStorage.getItem('access_Token')
    const [token, setToken] = useState(sessionStorage.getItem('access_Token'))
    function logoutHandler() {
        sessionStorage.removeItem('access_Token')
        sessionStorage.removeItem('register_user')
        localStorage.removeItem('userInfo')
        setToken(null)
        window.location.replace('/')
    }
    function checkQuickPay() {
        history.push({
                pathname: "/quickpay",
            });
    }
    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark" id="main__Navbar">
                <NavbarBrand>
                    <Link to="/">
                        <div className="scs-logo"  >
                            <img width="80%" src={Logo} alt="logo" />
                        </div>
                    </Link>
                </NavbarBrand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    </Nav>
                    <Nav>
                        <Nav.Link style={{ padding: "13px 20px 6px 20px", fontSize: '1rem' }} ><Link to="/" className="nav-link" >Home</Link></Nav.Link>
                        <NavDropdown title="Products" id="collasible-nav-dropdown" style={{ padding: "13px 20px 6px 20px", fontSize: '1rem' }} >
                            <NavDropdown.Item as={Link} to="/siptrunking"  >SIP Trunking</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/managesiptrunking"  >Managed SIP Trunking</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/pricing"  >Pricing</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/promotion"  >Promotion</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/hardware"  >Hardware</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Solutions" id="collasible-nav-dropdown" style={{ padding: "13px 20px 6px 20px", fontSize: '1rem' }}>
                            <NavDropdown.Item as={Link} to="/voip"   >VOIP Phone System</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/bussinesssolution"  >Business Solution</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/solutioninstallation"  >Installation</NavDropdown.Item>
                            {/* <NavDropdown.Item as={Link} to="/prepackage"  >Pre-Package</NavDropdown.Item> */}
                        </NavDropdown>
                        <NavDropdown title="Support" id="collasible-nav-dropdown" style={{ padding: "13px 20px 6px 20px", fontSize: '1rem' }}>
                            <NavDropdown.Item as={Link} to="/support-installation"  >Installation</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/maintanence"  >Maintenance</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/repair-replacement" >Repair/Replacement</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/onsitesupport"  >On-Site Support</NavDropdown.Item>
                        </NavDropdown>
                        {/* <NavDropdown title="About Us" href="/aboutus" to=" /aboutus" id="collasible-nav-dropdown" NavDropdown={true} navItem={true} style={{ padding: "13px 20px 6px 20px", fontSize: '1rem' }} >
                            <NavDropdown.Item as={Link} to="/whyscstelcom"   >Why SCS Telcom</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/contactus"  >Contact Us</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link style={{ padding: "13px 20px 6px 20px", fontSize: '1rem' }} ><Link to="/whyscstelcom" className="nav-link" >About Us</Link></Nav.Link>
                        <Nav.Link style={{ padding: "13px 20px 6px 20px", fontSize: '1rem' }} ><Link to="/contactus" className="nav-link" >Contact Us</Link></Nav.Link>

                        <Fade left>
                            <NavDropdown.Divider />
                        </Fade>

                        <li className=" auth-Link nav-item">
                            <Link onClick={checkQuickPay} className="scs-top-bar-login-link" ><i class="fa fa-credit-card" aria-hidden="true"></i> Quick Pay</Link>
                            {/* <Link to="/contactus" className="scs-top-bar-charitem" ><i class="fa fa-address-card-o" aria-hidden="true"></i> Get A Quote</Link> */}
                            <Link to="/cart" className="scs-top-bar-charitem" style={{ marginRight: "0" }} ><i aria-hidden="true" className="fa fa-shopping-basket"></i> View Cart</Link>
                        </li>
                        <li className=" auth-Link nav-item">
                            <div  className="scs-top-bar-login-link" ></div>
                            <Link to="/contactus" className="scs-top-bar-charitem" ><i class="fa fa-address-card-o" aria-hidden="true"></i> Get A Quote</Link>
                            <div  className="scs-top-bar-charitem" style={{ marginRight: "0" }} ></div>
                        </li>

                        <li className=" auth-Link nav-item" >
                            {token ? <Link to="/" onClick={logoutHandler} className="nav-link" style={{  fontSize: "14px" }}>Sign out</Link> : <Link to="/signup" className="nav-link" style={{ fontSize: "14px" }}>Sign Up</Link>}
                            {token ? <Link to="/profile" className="scs-top-bar-login-link"  ><i className="fa fa-user"></i> Profile</Link> : <Link to="/authui" className="scs-top-bar-charitem" style={{ marginRight: "0" ,fontSize: "14px" }} >Sign in</Link>}
                        </li>

                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default NavBarNew
