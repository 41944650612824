import { React, useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import AuthService from '../../../../Services/AuthService';
import Swal from 'sweetalert2';



const authService = new AuthService()
const AuthUI = (props) => {
    const getUserInfo = JSON.parse(localStorage.getItem('userInfo'))
    const [userDetail, setuserDetail] = useState(getUserInfo.email)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirnPassword, setConfirmPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false)
    const [isloading, setisLoading] = useState(false)


    const unSuccefullAlert = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! Error Warning',

        })
    }
    const successfulAlert = () => {

        Swal.fire({
            icon: 'success',
            title: 'Your password has been changed',
            confirmButtonColor: '#7ebc12',
            confirmButtonText: 'Ok',
        }).then((result) => {
            if (result.value) {
                props.history.push("/profile");
            }
        })
    }

    const signInFormSubmitHandler = (e) => {
        e.preventDefault()
        setIsSubmited(true)
        setisLoading(true)

        if (oldPassword == '') {
            setErrorMessage('Old password is required')
            setisLoading(false)

        }
        else if (newPassword.length < 6) {
            setErrorMessage('Password minimum should be 6 character')
            setisLoading(false)


        }
        else if (newPassword == '') {
            setErrorMessage('New password is required')
            setisLoading(false)


        }
        else if (confirnPassword == '') {
            setErrorMessage('Confirm password is required')
            setisLoading(false)


        }
        else if (confirnPassword.length < 6) {
            setErrorMessage('Password minimum should be 6 character')
            setisLoading(false)


        }
        else {

            let data = {
                "oldPassword": oldPassword,
                "newPassword": newPassword,
                "confirmPassword": confirnPassword
            }

            authService.changePasword(data, userDetail)
                .then((res) => {


                    let checkMsg = res.data.ErrorMessage

                    if (checkMsg == "Old password does not match") {
                        setErrorMessage('Old password does not match')
                        setisLoading(false)
                    }
                    else if (checkMsg ==  "New Password and confirm Password do not match") {
                        setErrorMessage( "New Password and confirm Password do not match")
                        setisLoading(false)
                    }
                    else{
                        setisLoading(false)
                        successfulAlert()
                        setErrorMessage( '')
                    }
                })
                .catch((error) => {
                    setisLoading(false)
                    setIsSubmited(false)
                    unSuccefullAlert()
                })
        }


    }

    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2>Change Password</h2>
                        </div>
                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Change Password</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            {/* <!-- Inner Wrapper Start --> */}

            {
                isSuccess ?
                    window.location.replace('/') : ""
            }
            <div className="inner-page-wrapper login-wrapper">

                <div className="container">
                    {errorMessage ? <div class="alert alert-danger" role="alert" style={{ marginTop: "40px", margin: "auto", width: "80%" }}>
                        <p className="alert-errorMessage" >{errorMessage}</p>
                    </div> : ""}
                    <div className="row" style={{ marginTop: '30px' }}  >
                        <div className="col-sm-5">
                            <h3>Change Password</h3>
                            <form action="#" onSubmit={signInFormSubmitHandler} >
                                <input required placeholder="Old Password" value={oldPassword} name="oldPassword" type="password" onChange={(e) => {
                                    if (errorMessage) {
                                        setOldPassword('')
                                    }
                                    setOldPassword(e.target.value)
                                }} />
                                <input required placeholder="New Password" value={newPassword} name="newPassword" type="password" onChange={(e) => {
                                    if (errorMessage) {
                                        setNewPassword('')
                                    }
                                    setNewPassword(e.target.value)
                                }} />
                                <input required placeholder="Confirm  Password" value={confirnPassword} name="confirnPassword" type="password" onChange={(e) => {
                                    if (errorMessage) {
                                        setConfirmPassword('')
                                    }
                                    setConfirmPassword(e.target.value)
                                }} />

                                <div className="forget-password">
                                    <div className="pull-left">
                                        <input id="test1" type="checkbox" />
                                        {/* <label htmlFor="test1">Remember Me</label> */}
                                    </div>
                                </div>
                                <div className="auth-ui-btn" >
                                    <button type="submit" className="btn" > {isloading ? <div class="spinner-border text-dark" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div> : 'Submit'} </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Inner Wrapper End -->  */}
        </div>
    )
}

export default withRouter(AuthUI)
