import React from 'react';
import './Maintance.css'
import { Link } from 'react-router-dom';
import phoneSys from '../../../../scs-img/5.png'
import perSys from '../../../../scs-img/img_03.jpg'
import net from '../../../../scs-img/networ.jpg'

const Maintance = () => {
    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div class="breadcrumb-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-7">
                            <h2>Maintenance</h2>
                        </div>
                        <div class="col-sm-5">
                            <div class="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Support</li>
                                    <li>Maintenance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            <div className="Maintenance-main-div" >
                <div className="Maintenance-content-section" >
                    <div className="container" >
                        <div className="row" >
                            <div className="col-lg-6" >
                                <div data-aos="fade-left" className="mainta-cntn" >
                                    <div className="maintenance-content-1" >
                                        <div className="heading-below-line">
                                            <h3>Phone System  </h3>
                                        </div>
                                        <p>SCS Telcom and our services team provide leading Voice Over IP phone system assistance and data equipment maintenance for small-to-medium businesses.</p>
                                    </div>
                                    <div className="maintenance-content-2" >
                                        <div className="heading-below-line">
                                            <h3>Offering</h3>
                                        </div>
                                        <p>For those that are current customers, SCS Telcom can assist you in the management of your systems to ensure that you have all necessary equipment covered and that you have a plan that is most beneficial to your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" >
                                <div data-aos="fade-right"  className="maintenance-content-img" >
                                    <img className="img-responsive" src={phoneSys} alt="phonesys" />
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    <div data-aos="zoom-in" className="maintenance-second-content-2" >
                            <div className="business_solution_heading">
                                <h2>VoIP phone system support and maintenance</h2>
                                <span className="heading-line"></span>
                            </div>
                            <div className="maintenace-content-2-2" >
                                <p>SCS Telcom provides cohesive telecommunication plans that address all of your business needs. From dial-tone to internet services, expert service and installation to post-installation maintenance and support, and industry leading telephone solutions, we will create a plan that is sure to increase your business productivity. Run your business,SCS Telcom will take care of your VoIP phone system support and maintenance.</p>
                            </div>
                        </div>





                        <div className="container maintance-second-section" >
                        <div className="row maintance-second-section-innner" >
                            <div className="col-lg-6" >
                                <div data-aos="fade-left" className="mainta-cntn-img" >
                                <div className="maintenance-content-img-1" >
                                    <img className="img-responsive img-1-1"  src={perSys} alt="phonesys" />
                                    <img className="img-responsive img-1-2"  src={net} alt="phonesys" />
                                </div>
                                   
                                   
                                </div>
                            </div>
                            <div className="col-lg-6" >
                            <div  data-aos="fade-right" className="maintenance-content-2" >
                                        <div className="heading-below-line">
                                            <h3>Technicians Support</h3>
                                        </div>
                                        <p>Our team of experienced and certified support technicians can also support and troubleshoot network issues and will help you successfully migrate to a newer platform when your business is ready - let us help you!</p>
                                    </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Maintance
