import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../css/chatStyle.css'
import ChatService from '../../Services/ChatService';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { guestLoginTrue, guestLoginFalse } from '../../store/actions/ChatAction'
const chatService = new ChatService()



function Join() {
    const [name, setName] = useState('')
    const [room, setRoom] = useState('')
    let history = useHistory();
    const dispatch = useDispatch();
    var isLoggedIn = useSelector(({ chat }) => chat.isGuestLogin)

    React.useEffect(() => {
        dispatch(guestLoginFalse())
    }, [])


    const handleClick = (e) => {

        e.preventDefault()
        console.log('action called')

        let data = {
            name: name,
            room: room,
            avatar: "",
            status: "online",
            mood: "",
            unread: "",
            user: "guest"
        }
        console.log(data, 'data to send')
        chatService.createChatContact(data)
            .then(res => {
                let responseJson = res.data
                dispatch(guestLoginTrue({ name: responseJson.saved.name, email: responseJson.saved.room, userId: responseJson.masterAdmin_chat_id, contactId: responseJson.saved._id }))
                sessionStorage.setItem('user', JSON.stringify({ name: responseJson.saved.name, email: responseJson.saved.room, userId: responseJson.masterAdmin_chat_id, contactId: responseJson.saved._id }))
                localStorage.setItem('contactId', res.data._id)
                history.push(`/`)
            })
            .catch(err => {
                console.log(err)
            })


    }

    return (
        <div className="container-fluid" >
            <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                    <div className="joinOuterContainer">
                        <div className="joinInnerContainer">
                            <div className="chat_join_registration">
                                <p style={{ fontSize: 'medium' }}>If you are register user then you can <Link to='/authui' style={{ color: '#7ebc12' }}>Login</Link> for send message. Otherwise you can sign up with user name and email as a guest. </p>
                            </div>
                            <h1 className="heading">Guest</h1>
                            <form onSubmit={(e) => handleClick(e)}>
                                <div>
                                    <input placeholder="username" className="joinInput" type="text" onChange={(event) => setName(event.target.value)} required />
                                </div>
                                <div>
                                    <input placeholder="email" className="joinInput mt-20" type="email" onChange={(event) => setRoom(event.target.value)} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                                </div>
                                {/* <Link onClick={e => (!name || !room) ? e.preventDefault() : null} to={`/chat?name=${name}&room=${room}`}> */}
                                {/* <Link onClick={e => (!name || !room) ? e.preventDefault() : handleClick()}> */}

                                {/* <button onClick={handleClick} className={'button mt-20'} type="submit">Sign In</button> */}
                                <button className={'button mt-20'} type="submit">Sign In</button>

                                {/* </Link> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Join
