import ProductServices from '../../Services/ProductServices';
import * as ActionTypes from './actiionTypes';

const productService = new ProductServices()

export const FetchHardware = () => (dispatch) => {

    productService.getProducts()
    .then((res) => {
        const { data } = res
        dispatch(addHardware(data))
    })
    .catch((Error) => {
        alert('Network Error' , Error)
     })
}


export const addToCart = (item) => {

    return {
        type : ActionTypes.ADD_TO_CART_HARDWARE,
        payload : item
    }
}

export const HardwareremoveFromCart = (item) => {
    return {
        type : ActionTypes.REMOVE_FROM_CART_HARDWARE,
        payload : item
    }
}

export const HardwareIncrementAction = (item) => {

    return{
        type : ActionTypes.INC_HARDWARE,
        payload : item
    }
}
export const HardwareDecrementAction = (item) => {

    return{
        type : ActionTypes.DEC_HARDWARE,
        payload : {
            pro : item
        }
    }
}

export const addHardware = (hardware) => {
    return {
        type : ActionTypes.ADD_HARDWARE,
        payload : hardware
    }
}
export const resetHardwareCart = () => {
    console.log('reset hardware cart')
    return {
        type : ActionTypes.RESET_HARDWARE_CART,
        payload : []
    }
}