import { React, useState, useEffect } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import AuthService from '../../Services/AuthService';
import Swal from 'sweetalert2';
import logo from '../../scs-img/ezgif.com-gif-maker.gif'
import './authUI.css';


const authService = new AuthService()
const AuthUI = (props) => {
    const history = useHistory()
        let tok = sessionStorage.getItem('access_Token')
    const [token, setToken] = useState(sessionStorage.getItem('access_Token'));

    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false)
    const [isloading, setisLoading] = useState(false)
    const checkQuickPay = () => {
        if (token) {
            history.push({
                pathname: "/quickpay",
            });
        }
        else {
            Swal.fire({
                title: 'Oops !',
                icon: 'error',
                text: 'Please sign in first!',
                confirmButtonColor: '#7ebc12',
                confirmButtonText: 'Sign In',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    history.push("/authui");
                }
            })
        }
    }

    const unSuccefullAlert = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong! Please Check Your connection',
        })
    }

    const signInFormSubmitHandler = (e) => {
        e.preventDefault()
        setIsSubmited(true)
        setisLoading(true)

        const formData = {
            email, password
        }
        console.log(formData);

        authService.signInData(formData)
            .then((res) => {
            
                if (res.data.access_Token) {
                    setIsSubmited(true)
                    let responseJson = res.data
                    let userInfo = res.data.user.userInfo
                    let user = res.data
                    localStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('userInfo', JSON.stringify(userInfo))
                    sessionStorage.setItem('access_Token', responseJson.access_Token)
                    sessionStorage.setItem('register_user', JSON.stringify({ name: user.first_Name + " " + user.last_Name, email: user.email, chatContact: { ...res.data.chat_contact, masterAdmin_chat_id: res.data.masterAdmin_chat_id }, userChatId: res.data.userChatId }))
                    setIsSuccess(true)
                } else {
                    window.scrollTo({
                        top: 100,
                        left: 100,
                        behavior: 'smooth'
                    });
                    if (res.data == "\"email\" is not allowed to be empty") {
                        setErrorMessage('Email is not allowed to empty')
                        setisLoading(false)
                    }
                    else if (res.data == "\"email\" must be a valid email") {
                        setErrorMessage('Valid email is required')
                        setisLoading(false)
                    }
                    else if (res.data == "\"password\" is not allowed to be empty") {
                        setErrorMessage('Password is not allowed to empty')
                        setisLoading(false)
                    }
                    else if (res.data == "Email or password is incorrect") {
                        setErrorMessage("Email or password is incorrect")
                        setisLoading(false)
                    }
                    else {
                        setErrorMessage(res.data)
                        setisLoading(false)
                    }


                }
            })
            .catch((error) => {
                unSuccefullAlert()
                setisLoading(false)

            })
    }

    return (
        <div>
            {/* <!-- Breadcrumb Wrapper Start --> */}
            <div className="breadcrumb-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <h2 className='aqua'>Sign in</h2>
                        </div>
                        <div className="col-sm-5">
                            <div className="inner-breadcrumb">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Sign in</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Breadcrumb Wrapper End -->  */}
            {/* <!-- Inner Wrapper Start --> */}

            {
                isSuccess ?
                    window.location.replace('/') : ""
            }
            <div className="login-wrapper back">

                <div className="container">
                    {errorMessage ? <div class="alert alert-danger" role="alert" style={{ marginTop: "40px", margin: "auto", width: "80%" }}>
                        <p className="alert-errorMessage" > {errorMessage}</p>
                    </div> : ""}
                    <div className="row flex-column-reverse flex-md-row" style={{ marginTop: '30px' }}  >
                        <div className="col-lg-5 col-md-5 white">
                            <div className='contain'>
                            <h1 className='scs__header'><strong>SCS</strong> <br></br> &nbsp;Telecom</h1>
                                <h3>Sign in</h3>
                            <form action="#" onSubmit={signInFormSubmitHandler} >
                                <input required="" placeholder="E-mail" className='scs__input' value={email} name="name" type="email" onChange={(e) => {
                                    if (errorMessage) {
                                        setErrorMessage('')
                                    }
                                    setemail(e.target.value)
                                }} />
                                <input required="" placeholder="password" className='scs__input' value={password} name="password" type="password" onChange={(e) => {
                                    if (errorMessage) {
                                        setErrorMessage('')
                                    }
                                    setpassword(e.target.value)
                                }} />
                                <div className="forget-password">
                                    <div className="pull-left">
                                        <input id="test1" type="checkbox" />
                                        {/* <label htmlFor="test1">Remember Me</label> */}
                                    </div>
                                    <Link to="/forgotpassword" className="pull-right">Forgot Password ?</Link> </div>
                                <div className="auth-ui-btn" >
                                    <button type="submit" className="btn" > {isloading ? <div class="spinner-border text-dark" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div> : 'Sign In'} </button>
                                </div>
                                
                            </form>
                            </div>
                            
                        </div>
                        <div className="col-lg-1 col-md-1"></div>
                        <div className="col-lg-5 col-md-5 scs__right" >
                            <div >
                                <div >
                                    <h3 className='scs__logo'>Register for My Account</h3>
                                    <p className='scs__logo'>
                                    View and pay your bill, customize your account preferences, manage your services, and more.
                                    </p>
                                    <div className=' center'>
                                    <Link to="/signup" className="btn btn-success btn-lg custom" > Register </Link>
                                </div>
                                </div>
                                {/* <div className="authui-logo" >
                                    <img src={logo} alt="logo scs" />
                                </div> */}
                            </div>
                            <div >
                                <div >
                                    <h3 className='scs__logo'>Short on time?</h3>
                                    <p className='scs__logo'>
                                    Make an easy and secure bill payment for a single account without signing in.
                                    </p>
                                    <div className=' center'>
                                    <Link onClick={checkQuickPay} className="btn btn-success btn-lg custom" >
                                        Quick Pay
                                    </Link>
                                </div>
                                </div>
                                {/* <div className="authui-logo" >
                                    <img src={logo} alt="logo scs" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Inner Wrapper End -->  */}
        </div>
    )
}

export default withRouter(AuthUI)
